import React, { useState, useEffect } from "react";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import InputAdornment from "@material-ui/core/InputAdornment";
//import Checkbox from "@material-ui/core/Checkbox";
//import FormControlLabel from "@material-ui/core/FormControlLabel";
import Icon from "@material-ui/core/Icon";
import Swal from 'sweetalert2'
import swal from '@sweetalert/with-react'
import InputLabel from "@material-ui/core/InputLabel";
import moment from 'moment';
import { useHistory } from 'react-router-dom';

// @material-ui/icons
//import Timeline from "@material-ui/icons/Timeline";
//import Code from "@material-ui/icons/Code";
//import Group from "@material-ui/icons/Group";
import Face from "@material-ui/icons/Face";
import Email from "@material-ui/icons/Email";
// import LockOutline from "@material-ui/icons/LockOutline";
//import Check from "@material-ui/icons/Check";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import CustomInput from "components/CustomInput/CustomInput.js";
//import InfoArea from "components/InfoArea/InfoArea.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";

import styles from "assets/jss/material-dashboard-pro-react/views/registerPageStyle";
const Inscription = [
  {
    nomUtilisateur: '',
    nom: '',
    prenom: '',
    email: '',
    password: ''

  }
];
const apiUrl = "https://api.lba-investissements.com"

const UserMeta = [
  {
    user_id: '',
  }
];

const Information = [
  {
    id_utilisateur: '',
    nom_utilisateur: '',
    nom: '',
    premon: '',
    email: '',
    date_inscription: '',
    reference_client: ''


  }
];
const useStyles = makeStyles(styles);

export default function RegisterPage() {
  const classes = useStyles();
  const [message, setMessage] = useState('');
  const [nomUtilisateur, setNomUtilisateur] = useState();
  const [nom, setNom] = useState();
  const [prenom, setPrenom] = useState();
  const [email, setEmail] = useState();
  const [password, setPassword] = useState();
  const [cardAnimaton, setCardAnimation] = React.useState("cardHidden");
  const [lastId, setLastId] = useState('');
  const history = useHistory();


  React.useEffect(() => {
    fetch(`${apiUrl}/connexion/getLastId`)
      .then(response => response.json())
      .then((data) => {
        setLastId(data[0].LastID);
      });
    let id = setTimeout(function () {
      setCardAnimation("");
    }, 700);
    // Specify how to clean up after this effect:
    return function cleanup() {
      window.clearTimeout(id);
    };
  });
  const handleFacebook = () => {
    //window.location.replace("https://lba-investissements.com/");
    window.open("https://lba-investissements.com/", "_blank");

  };
  const handleInstagram = () => {
    //window.location.replace("https://instagram.com/lba.investissements");
    window.open("https://instagram.com/lba.investissements", "_blank");


  };
  const handleClick = async () => {
    if ((nomUtilisateur == null) || (nom == null) || (prenom == null) || (email == null) || (password == null) || (nomUtilisateur === "") || (nom === "") || (prenom === "") ||
      (email === "") || (password === "")) {
      setMessage("Information manquante");
    } else {

      setMessage("");
      Inscription[0].nomUtilisateur = nomUtilisateur;
      Inscription[0].nom = nom;
      Inscription[0].prenom = prenom;
      Inscription[0].email = email;
      Inscription[0].password = password;
      Information[0].date_inscription = moment(Date.now()).format("YYYY-MM-DD hh:mm:ss");
      Information[0].email = email;
      Information[0].nom = nom;
      Information[0].nom_utilisateur = nomUtilisateur;
      Information[0].prenom = prenom;

      //setLastId(data[0].LastID);




      await fetch(`${apiUrl}/connexion/Inscription`,
        {
          method: "POST",
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify(Inscription[0])
        })
        .then((result) => {
          if (result.status === 200) {
            Swal.fire({
              position: 'top-end',
              icon: 'success',
              title: 'Inscription avec succès',
              showConfirmButton: false,
              timer: 1500
            })

          }

        }).then(async () => {
          await fetch(`${apiUrl}/connexion/getLastId`)
            .then(response => response.json())
            .then((data) => {
              UserMeta[0].user_id = data[0].LastID;
              Information[0].id_utilisateur = data[0].LastID;
              Information[0].reference_client = 'LBA0' + (100 + (data[0].LastID))
            });
          await fetch(`${apiUrl}/connexion/saveUsermeta`,
            {
              method: "POST",
              headers: { 'Content-Type': 'application/json' },
              body: JSON.stringify(UserMeta[0])
            })
        }).then(async () => {
          await fetch(`${apiUrl}/information/InscritUser`,
            {
              method: "POST",
              headers: { 'Content-Type': 'application/json' },
              body: JSON.stringify(Information[0])
            })
        }).then(() => {
          history.push("/auth/connexion");

        });
    }
  }
  return (
    <div className={classes.container}>
      <GridContainer justify="center">
        <GridItem xs={12} sm={12} md={5}>
          <Card className={classes.cardSignup}>
            <h2 className={classes.cardTitle}>Formulaire d'inscription</h2>
            <CardBody>
              <GridContainer justify="center">
                <GridItem xs={12} sm={8} md={8}>
                  <div className={classes.center}>
                    <Button justIcon round color="facebook" onClick={handleFacebook}>
                      <i className="fab fa-facebook-f" />
                    </Button>
                    {` `}
                    <Button justIcon round color="instagram" onClick={handleInstagram}>
                      <i className="fab fa-instagram" />
                    </Button>
                    {` `}
                    <h4 className={classes.socialTitle}>Suivez-nous sur les réseaux</h4>
                  </div>
                  <form className={classes.form}>
                    <CustomInput
                      formControlProps={{
                        fullWidth: true,
                        className: classes.customFormControlClasses
                      }}
                      inputProps={{
                        onChange: e => {
                          setNomUtilisateur(e.target.value);
                        },
                        startAdornment: (
                          <InputAdornment
                            position="start"
                            className={classes.inputAdornment}
                          >
                            <Face className={classes.inputAdornmentIcon} />
                          </InputAdornment>
                        ),
                        placeholder: "Nom d'utilisateur"
                      }}
                    />
                    <CustomInput
                      formControlProps={{
                        fullWidth: true,
                        className: classes.customFormControlClasses
                      }}
                      inputProps={{
                        onChange: e => {
                          setNom(e.target.value);
                        },
                        startAdornment: (
                          <InputAdornment
                            position="start"
                            className={classes.inputAdornment}
                          >
                            <Face className={classes.inputAdornmentIcon} />
                          </InputAdornment>
                        ),
                        placeholder: "Votre Nom"
                      }}
                    />
                    <CustomInput
                      formControlProps={{
                        fullWidth: true,
                        className: classes.customFormControlClasses
                      }}
                      inputProps={{
                        onChange: e => {
                          setPrenom(e.target.value);
                        },
                        startAdornment: (
                          <InputAdornment
                            position="start"
                            className={classes.inputAdornment}
                          >
                            <Face className={classes.inputAdornmentIcon} />
                          </InputAdornment>
                        ),
                        placeholder: "Votre Prénom"
                      }}
                    />
                    <CustomInput
                      formControlProps={{
                        fullWidth: true,
                        className: classes.customFormControlClasses
                      }}
                      inputProps={{
                        onChange: e => {
                          setEmail(e.target.value);
                        },
                        startAdornment: (
                          <InputAdornment
                            position="start"
                            className={classes.inputAdornment}
                          >
                            <Email className={classes.inputAdornmentIcon} />
                          </InputAdornment>
                        ),
                        placeholder: "E-mail"
                      }}
                    />
                    <CustomInput
                      formControlProps={{
                        fullWidth: true,
                        className: classes.customFormControlClasses
                      }}
                      inputProps={{
                        onChange: e => {
                          setPassword(e.target.value);
                        },
                        startAdornment: (
                          <InputAdornment
                            position="start"
                            className={classes.inputAdornment}
                          >
                            <Icon className={classes.inputAdornmentIcon}>
                              lock_outline
                            </Icon>
                          </InputAdornment>
                        ),
                        type: "password",
                        placeholder: "Mot de passe"
                      }}
                    />

                    <br></br>

                    <br></br>
                    <InputLabel
                      className={classes.labelRoot + " "}
                      error={true}
                    >
                      {message}
                    </InputLabel>
                    <br></br>

                    <div className={classes.center}>
                      <Button round color="info" onClick={() => handleClick()}>
                        Inscription
                      </Button>
                    </div>
                  </form>
                </GridItem>
              </GridContainer>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  );
}
