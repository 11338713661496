import React, { useState, useEffect } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { useHistory } from 'react-router-dom';

// material-ui icons
//import Assignment from "@material-ui/icons/Assignment";
import CreditCardIcon from '@material-ui/icons/CreditCard';
//import Person from "@material-ui/icons/Person";
import Edit from "@material-ui/icons/Edit";
import Close from "@material-ui/icons/Close";
import CheckCircleRoundedIcon from '@material-ui/icons/CheckCircleRounded';
import moment from 'moment';
import 'sweetalert2/src/sweetalert2.scss';
import Swal from 'sweetalert2';
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Table from "components/Table/Table.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardIcon from "components/Card/CardIcon.js";
import CardHeader from "components/Card/CardHeader.js";
import AuthService from '../Services/Auth.js'

import styles from "assets/jss/material-dashboard-pro-react/views/extendedTablesStyle.js";

const apiUrl = "https://api.lba-investissements.com"
const Auth = new AuthService();

const useStyles = makeStyles(styles);

export default function ExtendedTables() {
  function numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }
  const classes = useStyles();
  const [rowsCommissions, setRowsCommissions] = useState();
  const history = useHistory();

  useEffect(() => {
    fetch(`${apiUrl}/commissions/getRetraits`)
      .then(response => response.json())
      .then((data) => {
        setRowsCommissions(Array.from(data["data"]));
      });

  }, []);

  const simpleButtons = [
    { color: "success", icon: CheckCircleRoundedIcon, data: rowsCommissions },
    { color: "warning", icon: Edit, data: rowsCommissions },
    { color: "danger", icon: Close, data: rowsCommissions }
  ].map((prop, key) => {
    return (
      <Button
        color={prop.color}
        simple
        className={classes.actionButton}
        key={key}
      >
        <prop.icon className={classes.icon} />
      </Button>
    );
  });
  return (
    <GridContainer>
      <GridItem xs={12}>
        <Card>
          <CardHeader color="warning" icon>
            <CardIcon color="warning">
              <CreditCardIcon />
            </CardIcon>
            <h4 className={classes.cardIconTitle}>Demandes retraits clients</h4>
            <font color="black"><small>Liste de toutes les demandes de retraits clients</small></font>
          </CardHeader>
          <CardBody>
            {rowsCommissions !== undefined ?
              <Table
                tableHead={["Nom Prénom", "Référence client", "Date de demande", "KYC", "Montant demandé"]}
                // tableData={[

                //   ["Dupont Jean","LBA01006", "05/12/2020", "✔", "350€", simpleButtons],
                //   ["Hirtz Alex","LBA01005", "05/12/2020", "✔", "1000€", simpleButtons],
                //   ["Meyer Damien","LBA01004", "05/12/2020", "-", "50€", simpleButtons],
                //   ["Dubois Jean","LBA01003", "05/12/2020", "-", "90€", simpleButtons],
                //   ["Muller Paul","LBA01002", "05/12/2020", "-", "800€", simpleButtons],
                //   ["Berra Luc","LBA01001", "05/12/2020", "✔", "1500€", simpleButtons]
                // ]}
                tableData={rowsCommissions.map((row) => ([

                  // [row.nom_prenom,row.nom_service, row.date, row.montant]
                  //row.nom_prenom, row.reference_client, moment(row.date).format("DD/MM/YYYY"), row.kyc === 0 ? "-" : "✔" ,numberWithCommas(Math.round(parseFloat(row.montant)).toFixed(2)) + " €",simpleButtons])
                  row.nom_prenom, row.reference_client, moment(row.date).format("DD/MM/YYYY"), row.kyc === 0 ? "-" : "✔",  Auth.toFixed(parseFloat(row.montant),2) + " €",
                  [
                    { color: "success", icon: CheckCircleRoundedIcon, data: row },
                    { color: "warning", icon: Edit, data: row },
                    { color: "danger", icon: Close, data: row }
                  ].map((prop, key) => {
                    return (
                      <Button
                        color={prop.color}
                        simple
                        className={classes.actionButton}
                        key={key}
                        //onClick={(e)=>clickButton(e)}
                        onClick={() => {
                          if (prop.color === "success") {
                            fetch(`${apiUrl}/commissions/updateRetrait/` + prop["data"].id_commission + '/Validé',
                              {
                                method: "PUT"
                              }).then((result) => {
                                if (result.status === 200) {
                                  Swal.fire({
                                    position: 'top-end',
                                    icon: 'success',
                                    title: 'Mise à jour avec succès',
                                    showConfirmButton: false,
                                    timer: 1500
                                  }).then(() => {
                                    window.location.reload();

                                  })
                                }
                                else {
                                  Swal.fire({
                                    position: 'top-end',
                                    icon: 'warning',
                                    title: 'Erreur Mise à jour',
                                    showConfirmButton: false,
                                    timer: 1500
                                  })
                                }
                              }).catch((error) => {
                                Swal.fire({
                                  position: 'top-end',
                                  icon: 'warning',
                                  title: 'Error',
                                  showConfirmButton: false,
                                  timer: 1500
                                })
                              })
                          }
                          if (prop.color === "danger") {
                            Swal.fire({
                              title: 'Voulez-vous refusé la demande de retrait client?',
                              showDenyButton: true,
                              confirmButtonText: `Anuller`,
                              denyButtonText: `Accepter`,
                            }).then((result) => {
                              /* Read more about isConfirmed, isDenied below */
                              if (result.isConfirmed) {
                                //Swal.fire('Saved!', '', 'success')
                              } else if (result.isDenied) {
                                fetch(`${apiUrl}/commissions/updateRetrait/` + prop["data"].id_commission + '/Refusé',
                                  {
                                    method: "PUT"
                                  }).then((result) => {
                                    if (result.status === 200) {
                                      Swal.fire({
                                        position: 'top-end',
                                        icon: 'success',
                                        title: 'Mise à jour avec succès',
                                        showConfirmButton: false,
                                        timer: 1500
                                      }).then(() => {
                                        window.location.reload();

                                      })
                                    }
                                    else {
                                      Swal.fire({
                                        position: 'top-end',
                                        icon: 'warning',
                                        title: 'Erreur Mise à jour',
                                        showConfirmButton: false,
                                        timer: 1500
                                      })
                                    }
                                  })
                              }
                            })
                          } else if (prop.color === "warning") {
                            history.push(`edite-profil`, row);
                          }
                        }}
                      >
                        <prop.icon className={classes.icon} />
                      </Button>
                    );
                  })
                ])

                )}

                coloredColls={[5]}
                colorsColls={["success"]}

                customCellClasses={[classes.center, classes.right, classes.right]}
                customClassesForCells={[0, 4, 5]}
                customHeadCellClasses={[
                  classes.center,
                  classes.right,
                  classes.right
                ]}
                customHeadClassesForCells={[0, 4, 5]}
              /> :
              null}
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
}
