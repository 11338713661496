// import client
import ButtonsClient from "views/Client/Components/ButtonsClient.js";
import AchatCredit from "views/Client/Components/AchatCredit.js";

import PricingPageClient from "views/Client/Pages/PricingPageClient.js";
import DashboardClient from "views/Client/Dashboard/DashboardClient.js";
/*import CalendarClient from "views/Client/Calendar/CalendarClient.js";
import ChartsClient from "views/Client/Charts/ChartsClient.js";
import ErrorPageClient from "views/Client/Pages/ErrorPageClient.js";
import ExtendedFormsClient from "views/Client/Forms/ExtendedFormsClient.js";
import ExtendedTablesClient from "views/Client/Tables/ExtendedTablesClient.js";
import FullScreenMapClient from "views/Client/Maps/FullScreenMapClient.js";
import GoogleMapsClient from "views/Client/Maps/GoogleMapsClient.js";
import GridSystemClient from "views/Client/Components/GridSystemClient.js";
import IconsClient from "views/Client/Components/IconsClient.js";
import LockScreenPageClient from "views/Client/Pages/LockScreenPageClient.js";
import LoginPageClient from "views/Client/Pages/LoginPageClient.js";
import NotificationsClient from "views/Client/Components/NotificationsClient.js";
import PanelsClient from "views/Client/Components/PanelsClient.js";
import ReactTablesClient from "views/Client/Tables/ReactTablesClient.js";
import RegisterPageClient from "views/Client/Pages/RegisterPageClient.js";
import RegularFormsClient from "views/Client/Forms/RegularFormsClient.js";
import RegularTablesClient from "views/Client/Tables/RegularTablesClient.js";
import SweetAlertClient from "views/Client/Components/SweetAlertClient.js";
import TimelinePageClient from "views/Client/Pages/TimelineClient.js";
import TypographyClient from "views/Client/Components/TypographyClient.js";
import UserProfileClient from "views/Client/Pages/UserProfileClient.js";
import ValidationFormsClient from "views/Client/Forms/ValidationFormsClient.js";
import VectorMapClient from "views/Client/Maps/VectorMapClient.js";
import WidgetsClient from "views/Client/Widgets/WidgetsClient.js";
import WizardClient from "views/Client/Forms/WizardClient.js";*/

// @material-ui/icons
//import AppsClient from "@material-ui/icons/AppsClient";
// import DashboardIconClient from "@material-ui/icons/DashboardClient";
import DashboardIconClient from "@material-ui/icons/Dashboard";

// import TimelineClient from "@material-ui/icons/TimelineClient";
import TimelineClient from "@material-ui/icons/Timeline";

/*import DateRangeClient from "@material-ui/icons/DateRangeClient";
import GridOnClient from "@material-ui/icons/GridOnClient";
import ImageClient from "@material-ui/icons/ImageClient";

import PlaceClient from "@material-ui/icons/PlaceClient";
import WidgetsIconClient from "@material-ui/icons/WidgetsClient";*/

// import UnarchiveClient from "@material-ui/icons/UnarchiveClient";
import UnarchiveClient from "@material-ui/icons/Unarchive";
// import PersonClient from "@material-ui/icons/PersonClient";
import PersonClient from "@material-ui/icons/Person";

import ProfilUtilisateurClient from "views/Client/ProfilUtilisateur/ProfilUtilisateurClient.js";
import TradingClient from "views/Client/Trading/TradingClient.js";
import ImmoClient from "views/Client/Immo/ImmoClient.js";
import RTL from "layouts/RTL.js"
import CommissionsClient from "views/Client/Commissions/CommissionsClient.js";
import RedirectionClient from "views/Client/Redirection/RedirectionClient.js";
import HomeWorkRoundedIcon from '@material-ui/icons/HomeWorkRounded';
import EuroSymbolRoundedIcon from '@material-ui/icons/EuroSymbolRounded';
import PublishRoundedIcon from '@material-ui/icons/PublishRounded';
import AssignmentReturnedRoundedIcon from '@material-ui/icons/AssignmentReturnedRounded';

/////////////////////////////////

var dashRoutes = [
    {
    path: "/dashboard",
    name: "Vue d'ensemble",
    rtlName: "لوحة القيادة",
    icon: DashboardIconClient,
    component: DashboardClient,
    layout: "/client"
  },
  {
    path: "/profil-utilisateur",
    name: "Profil d'utilisateur",
    //rtlName: "لوحة القيادة",
    icon: PersonClient,
    component: ProfilUtilisateurClient,
    layout: "/client"
  },
  {
    path: "/trading",
    name: "Trading",
    //rtlName: "لوحة القيادة",
    icon: TimelineClient,
    component: TradingClient,
    layout: "/client"
  },
  {
    path: "/immobilier",
    name: "Immobilier",
    //rtlName: "لوحة القيادة",
    icon: HomeWorkRoundedIcon,
    component: ImmoClient,
    layout: "/client"
  },
  {
    path: "/mes-commissions",
    name: "Mes commissions",
    //rtlName: "لوحة القيادة",
    icon: EuroSymbolRoundedIcon,
    component: CommissionsClient,
    layout: "/client"
  },
  {
    collapse: true,
    name: "Dépôt",
    rtlName: "صفحات",
    icon: PublishRoundedIcon,
    state: "depotCollapse",
    views: [
      // {
      //   path: "/pricing-page",
      //   name: "Services ATS Trading",
      //   rtlName: "عالتسعير",
      //   mini: "AT",
      //   rtlMini: "ع",
      //   component: PricingPageClient,
      //   layout: "/auth"
      // },
      {
        path: "/pricing-page",
        name: "Services ATS Trading",
        rtlName: "عالتسعير",
        mini: "AT",
        rtlMini: "ع",
        component: PricingPageClient,
        layout: "/client"
      },
      // {
      //   path: "/rtl-support-page",
      //   name: "Services LBA IMMO",
      //   rtlName: "صودعم رتل",
      //   mini: "LI",
      //   rtlMini: "صو",
      //   component: RTLSupportClient,
      //   layout: "/rtl"
      // },
      {
        path: "/RTL",
        name: "Services LBA IMMO",
        rtlName: "صودعم رتل",
        mini: "LI",
        rtlMini: "صو",
        component: RTL,
        layout: "/client"
      },
    ]
  },
  {
    collapse: true,
    name: "Retrait",
    rtlName: "المكونات",
    icon: AssignmentReturnedRoundedIcon,
    state: "retraitCollapse",
    views: [
      {
        path: "/buttons",
        name: "Commissions",
        rtlName: "وصفت",
        icon: EuroSymbolRoundedIcon,
        rtlMini: "ب",
        component: ButtonsClient,
        layout: "/client"
      },
    ]
  },
  {
    path: "/Achat-credit-LBA",
    name: "Achat crédit LBA",
    rtlName: "التطور للاحترافية",
    icon: EuroSymbolRoundedIcon,
    component: AchatCredit,
    layout: "/client"
  },
  {
    path: "/redirection",
    name: "Retourné sur le site",
    rtlName: "التطور للاحترافية",
    icon: UnarchiveClient,
    component: RedirectionClient,
    layout: "/client"
  },
];
export default dashRoutes;
