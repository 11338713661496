import React, { useState, useEffect } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// material-ui icons
//import Assignment from "@material-ui/icons/Assignment";
import HomeWorkRoundedIcon from '@material-ui/icons/HomeWorkRounded';
import swal from '@sweetalert/with-react'
import Button from "components/CustomButtons/Button.js";
import Swal from 'sweetalert2'
import moment from 'moment';

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Table from "components/Table/Table.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";
import $ from 'jquery';
import AuthService from '../../Services/Auth.js'

import { cardTitle } from "assets/jss/material-dashboard-pro-react.js";
const apiUrl = "https://api.lba-investissements.com"
const Auth = new AuthService();

const styles = {
  customCardContentClass: {
    paddingLeft: "0",
    paddingRight: "0"
  },
  cardIconTitle: {
    ...cardTitle,
    marginTop: "15px",
    marginBottom: "0px"
  }
};
const demandePlacements = [
  {
    portefeuille: '',
    id_utilisateur: '',
    nom_service: 'LBA IMMO',
    date: moment(Date.now()).format("YYYY-MM-DD hh:mm:ss"),
    montant: '',
    nom_prenom: '',
    reference_client: ''
  }
];
function numberWithCommas(x) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}
const useStyles = makeStyles(styles);

export default function RegularTables() {
  const idUser = localStorage.getItem('IdUser');
  const [rowsPlacements, setRowsPlacements] = useState();
  const [userInformation, setUserInformation] = useState();
  const [creditUser, setCredit] = useState('');

  useEffect(() => {
    fetch(`${apiUrl}/placements/getImmobilier/` + idUser)
      .then(response => response.json())
      .then((data) => {
        setRowsPlacements(Array.from(data["data"]));
      });
    fetch(`${apiUrl}/information/getInformationByIdUser/` + idUser)
      .then(response => response.json())
      .then(async (data) => {
        await setUserInformation(data[0]);
      });
    fetch(`${apiUrl}/information/creditUser/` + idUser)
      .then(response => response.json())
      .then((data) => {
        setCredit(data[0].credit);
      });

  }, []);
  const classes = useStyles();
  return (
    <>

      <Button color="success" className={classes.updateProfileButton} style={{ float: 'right' }}
        onClick={() => {

          swal({
            title: 'Données Utilisateurs',
            text: 'Redirecting...',
            icon: 'success',
            timer: 1000,
            buttons: false,
          }).then(() => {
            Swal.fire({
              title: "Nouvelle demande de placement \n" +
                "Service : <b>LBA IMMO</b>",
              type: 'info',
              input: 'select',
              inputOptions: {
                creditUser: 'Crédit - ( ' + Auth.toFixed(parseFloat(creditUser,2)) + ' )',
                commissions: 'Commissions - ( ' + (isNaN(Math.round(parseFloat(userInformation.commissions))) ? 0 : Auth.toFixed(parseFloat(userInformation.commissions),2)) + ' )'


              },
              inputPlaceholder: 'Choisir Portefeuille',
              //showCancelButton: true,

              html:
                '<p id="label">Montant désiré : <p>\n' +
                '<input id="swal-input1" class="swal2-input">',

              //input: 'text',
              showDenyButton: true,
              //showCancelButton: true,
              confirmButtonText: 'Validé',
              denyButtonText: 'Annulé',
              //showCloseButton: true,
              inputValidator: async () => {
                var select = $(".swal2-select").val();

                return new Promise((resolve) => {
                  if (select !== null) {
                    resolve()
                  } else {
                    resolve('Veuillez renseigner tous les champs')
                  }
                })
              },
              preConfirm: () => {
                var input = $("#swal-input1").val();
                if (input === "") {
                  Swal.showValidationMessage(
                    '<i class="fa fa-info-circle"></i>Veuillez renseigner tous les champs'
                  )
                }
                if (parseFloat($("#swal-input1").val()) > parseFloat(creditUser) && ($(".swal2-select").val() === "creditUser") || ($("#swal-input1").val() < 50)) {
                  Swal.showValidationMessage(
                    '<i class="fa fa-info-circle"></i>Montant invalid'
                  )
                }
                if (parseFloat($("#swal-input1").val()) > parseFloat(userInformation.commissions) && ($(".swal2-select").val() === "commissions") || ($("#swal-input1").val() < 50)) {
                  Swal.showValidationMessage(
                    '<i class="fa fa-info-circle"></i>Montant invalid'
                  )
                }
              },
              onOpen: function (ele) {
                $(ele).find('.swal2-select').insertBefore($(ele).find('#label'));
              }

            }).then((result) => {
              demandePlacements[0].id_utilisateur = userInformation.id_utilisateur;
              demandePlacements[0].nom_prenom = userInformation.nom + " " + userInformation.prenom;
              demandePlacements[0].reference_client = userInformation.reference_client;
              demandePlacements[0].montant = $("#swal-input1").val();

              if (result.isDenied) {
              }
              else if (result.isConfirmed) {
                if ($(".swal2-select").val() === "creditUser") {
                  Swal.fire({
                    title: "Vous êtes sur le point de placé " + $("#swal-input1").val() + "€ sur le service \n" +
                      "LBA IMMO \n" +
                      "voulez-vous continuer ?</b>",
                    type: 'info',
                    showDenyButton: true,
                    confirmButtonText: 'Validé',
                    denyButtonText: 'Annulé',

                  }).then((result) => {
                    if (result.isConfirmed) {

                      demandePlacements[0].portefeuille = "Crédit";

                      swal({
                        title: 'Votre placement à bien été enregistrer \n',
                        text: 'votre placement sera prochainement actif',
                        icon: 'success',
                        timer: 3000,
                        buttons: false,
                      }).then(async () => {
                        await fetch(`${apiUrl}/placements/InsertDemande`,
                          {
                            method: "POST",
                            headers: { 'Content-Type': 'application/json' },
                            body: JSON.stringify(demandePlacements[0])
                          })
                      })
                    } else {

                    }
                  })

                } else {
                  Swal.fire({
                    title: "Vous êtes sur le point de placé " + $("#swal-input1").val() + "€ sur le service \n" +
                      "ATS Trading Services \n" +
                      "voulez-vous continuer ?</b>",
                    type: 'info',
                    showDenyButton: true,
                    confirmButtonText: 'Validé',
                    denyButtonText: 'Annulé',

                  }).then((result) => {
                    if (result.isConfirmed) {
                      demandePlacements[0].portefeuille = "Commissions";

                      swal({
                        title: 'Votre placement à bien été enregistrer \n',
                        text: 'votre placement sera prochainement actif',
                        icon: 'success',
                        timer: 3000,
                        buttons: false,
                      }).then(async () => {
                        await fetch(`${apiUrl}/placements/InsertDemande`,
                          {
                            method: "POST",
                            headers: { 'Content-Type': 'application/json' },
                            body: JSON.stringify(demandePlacements[0])
                          })
                      })
                    } else {

                    }
                  })
                }
              }
            })


          });
        }} >
        Nouveau placement
              </Button>
      <GridContainer>
        <GridItem xs={12}>
          <Card>
            <CardHeader color="info" icon>
              <CardIcon color="info">
                <HomeWorkRoundedIcon />
              </CardIcon>
              <h4 className={classes.cardIconTitle}>Placement LBA Immobilier</h4>
              <font color="black"><small>Retrouver la liste de vos placements sur ce service</small></font>
            </CardHeader>
            <CardBody>
              {rowsPlacements !== undefined ?

                <Table
                  tableHeaderColor="primary"
                  tableHead={["ID", "Services", "Date", "ROI", "Montant"]}
                  // tableData={[
                  //   ["ATS TRADING SERVICES", "15/01/2021", "500€"],
                  //   ["LBA IMMO", "13/01/2021", "1000€"],
                  //   ["ATS TRADING SERVICES", "08/01/2021", "350€"],
                  //   ["LBA IMMO", "22/12/2020", "50€"],
                  //   ["LBA IMMO", "13/01/2021", "720€"],
                  //   ["LBA IMMO", "18/11/2020", "1430€"],
                  //   ["ATS TRADING SERVICES", "28/10/2020", "500€"],
                  //   ["LBA IMMO", "25/10/2020", "1000€"],
                  //   ["ATS TRADING SERVICES", "23/09/2020", "350€"],
                  //   ["ATS TRADING SERVICES", "19/09/2020", "490€"],
                  // ]}
                  tableData={rowsPlacements.map(row => ([

                    // [row.nom_prenom,row.nom_service, row.date, row.montant]
                    row.id_placement, row.nom_service, moment(row.date).format("DD/MM/YYYY"), (row.roi != null ? Auth.toFixed(row.roi, 2) : 0), Auth.toFixed(parseFloat(row.montant), 2) + "€"]))}

                  coloredColls={[4]}
                  colorsColls={["success"]}
                /> : null}
              {/* <Table
              tableHeaderColor="primary"
              tableHead={["ID", "Services", "Date", "ROI", "Montant"]}
              tableData={[
                ["6", "LBA IMMO", "05/12/2020", "10%", "350€"],
                ["5", "LBA IMMO", "15/11/2020", "15%", "1000€"],
                ["4", "LBA IMMO", "13/09/2020", "23%", "200€"],
                ["3", "LBA IMMO", "18/07/2020", "29%", "700€"],
                ["2", "LBA IMMO", "13/05/2020", "33%", "800€"],
                ["1", "LBA IMMO", "09/03/2020", "65%", "1500€"]
              ]}
              coloredColls={[4]}
              colorsColls={["success"]}
            /> */}
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </>
  );
}
