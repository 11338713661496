import React, { useState, useEffect } from "react";
import InputLabel from "@material-ui/core/InputLabel";
import { useHistory } from 'react-router-dom';

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import InputAdornment from "@material-ui/core/InputAdornment";
import Icon from "@material-ui/core/Icon";
import Authorities from "../Services/Auth";

// @material-ui/icons
//import Face from "@material-ui/icons/Face";
import Email from "@material-ui/icons/Email";
// import LockOutline from "@material-ui/icons/LockOutline";
import Cookies from 'universal-cookie';

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import CardFooter from "components/Card/CardFooter.js";

import styles from "assets/jss/material-dashboard-pro-react/views/loginPageStyle.js";
const cookies = new Cookies();
const apiUrl = "https://api.lba-investissements.com" 

const useStyles = makeStyles(styles);
const Login = [
  {
    identifiant: '',
    motDePasse: ''
  }
];
export default function LoginPage() {
  const [cardAnimaton, setCardAnimation] = React.useState("cardHidden");
  const [identifiant, setIdentifiant] = useState();
  const [motDePasse, setMotDePasse] = useState();
  const [message, setMessage] = useState('');
  const [informationUser, setInformationUsers] = useState();
  const history = useHistory();

  React.useEffect(() => {
    let cookieValue = document.cookie.match('wordpress_logged_in');
    
    let id = setTimeout(function () {
      setCardAnimation("");
    }, 700);
    // Specify how to clean up after this effect:
    return function cleanup() {
      window.clearTimeout(id);
    };
  });
  const classes = useStyles();
  const setToken = (idToken) => {
    // Saves user token to localStorage
    localStorage.setItem('id_token', idToken)
  }
  const handleClickForgetPassword = async () => {
    //window.location.replace("/auth/mot-de-passe-oublie");
    window.open('https://lba-investissements.com/password-reset/','_blank');


  }
  const handleClick = async () => {

    Login[0].identifiant = identifiant;
    Login[0].motDePasse = motDePasse;
    if ((identifiant !== undefined) && (motDePasse !== undefined)) {
      await fetch(`${apiUrl}/connexion/getUser/`,
        {
          method: "POST",
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify(Login[0])
        }).then(response => response.json())
        .then(async (data) => {
          if (data["data"][0].user_login !== "") {
            if (data["valid"] == true) {
              localStorage.setItem('IdUser',data["data"][0].ID);

              await fetch(`${apiUrl}/connexion/getUserById/` + data["data"][0].ID)
                .then(response => response.json())
                .then((data) => {
                  var nSubscriber = data[0]['meta_value'].includes("subscriber");
                  var nAdmin = data[0]['meta_value'].includes("administrator");


                  if (nSubscriber === true) {
                    history.push("/client/dashboard",data[0]);
                    window.location.reload();
                  } else if(nAdmin){
                    history.push("/admin/dashboard");
                    window.location.reload();
                                    }
                  setInformationUsers(data[0]);
                });

            } else {
              setMessage("Mot de passe incorrecte");
            }

          }
        })
        .catch(e => {
          console.log(e)
          setMessage("Identifient ou adresse e-mail incorrecte");
        });
    } else {
      setMessage("Renseigner toutes les informations demandées");

    }

  };
  const handleFacebook = () => {
    //window.location.replace("https://lba-investissements.com/");
    window.open("https://lba-investissements.com/","_blank");


  };
  const handleInstagram = () => {
    //window.location.replace("https://instagram.com/lba.investissements");
    window.open("https://instagram.com/lba.investissements","_blank");


  };

  return (
    <div className={classes.container}>
      <GridContainer justify="center">
        <GridItem xs={12} sm={6} md={4}>
          <form>
            <Card login className={classes[cardAnimaton]}>
              <CardHeader
                className={`${classes.cardHeader} ${classes.textCenter}`}
                color="info"
              >
                <h4 className={classes.cardTitle}>Connexion</h4>
                <div className={classes.socialLine}>
                <Button justIcon color="transparent"  className={classes.customButtonClass} onClick={handleFacebook}>
                      <i className="fab fa-facebook-square" />
                    </Button>
                    <Button justIcon color="transparent" className={classes.customButtonClass} onClick={handleInstagram}>
                      <i className="fab fa-instagram" />
                    </Button>
                  </div>
                {/* <div className={classes.socialLine}>
                  {[
                    "fab fa-facebook-square",
                    "fab fa-instagram",
                  ].map((prop, key) => {
                    return (
                      <Button
                        color="transparent"
                        justIcon
                        key={key}
                        className={classes.customButtonClass}
                      >
                        <i className={prop} />
                      </Button>
                    );
                  })}
                </div> */}
              </CardHeader>
              <CardBody>
                <CustomInput
                  labelText="Nom d'utilisateur ou E-mail"
                  id="email"
                  formControlProps={{
                    fullWidth: true
                  }}
                  inputProps={{
                    onChange: e => {
                      setIdentifiant(e.target.value);
                    },
                    endAdornment: (
                      <InputAdornment position="end">
                        <Email className={classes.inputAdornmentIcon} />
                      </InputAdornment>
                    )
                  }}
                />
                <CustomInput
                  labelText="Mot de passe"
                  id="password"
                  formControlProps={{
                    fullWidth: true
                  }}
                  inputProps={{
                    onChange: e => {
                      setMotDePasse(e.target.value);
                    },
                    endAdornment: (
                      <InputAdornment position="end">
                        <Icon className={classes.inputAdornmentIcon}>
                          lock_outline
                        </Icon>
                      </InputAdornment>
                    ),
                    type: "password",
                    autoComplete: "off"
                  }}
                />
              </CardBody>
              <InputLabel
                className={classes.labelRoot + " "}
                error={true}
              >
                <p style={{ textAlign: "center" }}>{message}</p>
              </InputLabel>
              <CardFooter className={classes.justifyContentCenter}>
                <Button color="info" simple size="lg" block onClick={() => handleClick()}>
                  Se connecter
                </Button>
                
              </CardFooter>
              <Button color="primary" simple size="lg" block onClick={() => handleClickForgetPassword()}>
                  Mot de passe Oublié
                 </Button>
            </Card>
          </form>
        </GridItem>
      </GridContainer>
    </div>
  );
}
