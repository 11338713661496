import React, { useState, useEffect } from "react";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// @material-ui/icons
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import PriorityHigh from "@material-ui/icons/PriorityHigh";
import Check from "@material-ui/icons/Check";
import Warning from "@material-ui/icons/Warning";
import Close from "@material-ui/icons/Close";
import Favorite from "@material-ui/icons/Favorite";
import swal from '@sweetalert/with-react'

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Pagination from "components/Pagination/Pagination.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import 'sweetalert2/src/sweetalert2.scss';
import Swal from 'sweetalert2';
import styles from "assets/jss/material-dashboard-pro-react/views/buttonsStyle.js";
import AuthService from '../../Services/Auth.js'


const Auth = new AuthService();
const useStyles = makeStyles(styles);
const apiUrl = "https://api.lba-investissements.com" 
const commissions = [
  {
    id_utilisateur: '',
    nom_prenom: '',
    status: 'En Attente',
    date: '',
    montant: ''
  }
];
export default function Buttons() {
  const classes = useStyles();
  const [rowsCommissionsRetire, setRowsCommissionsRetire] = useState();
  const [rowsCommissions, setRowsCommissions] = useState();
  const idUser = localStorage.getItem('IdUser');
  function reformatDateString(s) {
    var b = s.split(/\D/);
    return b.reverse().join('-');
  }

  useEffect(() => {
    fetch(`${apiUrl}/information/getInformationByIdUser/` + idUser)
      .then(response => response.json())
      .then(async (data) => {
        if (data[0].kyc === 0) {
        swal({
          title: "Validation KYC requise",
          text: "Suite à la Directive (UE) 2015/849 du Parlement Européen et du Conseil, nous somme dans l'obligation de procéder à la vérification de votre identité \n" +
            "Pour ce faire nous aurions besoins des documents suivants vous concernant afin de validé votre identité : \n" +
            "- Une pièce d'indentité en cours de validité \n" +
            "- Un justificatif de domicile de moins de 3 mois \n" +
            "- Un RIB \n" +
            "Et de nous retourner tous les documents par e-mail en accompagnent dans l'objet la Référence suivante :" + data[0].reference_client + "\n",
          type: "success",
          button: "Envoyer les documents"
        }).then(okay => {
          if (okay) {
            window.location.href = "mailto:contact@lba-investissements.com?subject="+data[0].reference_client;
          }
        });
      }
      if (data[0].kyc === 1) {
        swal({
          title: "Nouvelle demande de retrait",
          text: "Votre solde : " + Auth.toFixed(parseFloat(data[0].commissions),2) + "\n",
          type: "success",
          content: "input",
          // button: "Validé"
          buttons: {
            cancel: "Annulé",
            confirm: {
              text: "Validé",
            }

            //   catch: {
            //   text: "Validé",
            //   value: "valid",
            // }
          }
        }).then((value) => {
          switch (value) {
            case "Annulé":
              //window.location.href = "";
              break;

            default:
              //window.location.href = "";
              if ((value > parseFloat(data[0].commissions) && (value !== null))) {
                swal({
                  title: "Le montant demander dépasse la solde disponible",
                  icon: 'warning'
                })
              }
              else if ((value < 10) && (value !== null)) {
                swal({
                  title: "Le montant minimum est de 10€",
                  icon: 'warning'

                })
              }
              else if (value !== null) {
                swal({
                  title: "Opération réussis ! Votre demande sera traiter dans les plus brève délai",
                  icon: 'success'

                }).then(async () => {
                  console.log("33333"+data[0])
                  commissions[0].id_utilisateur = idUser;
                  commissions[0].montant = value;
                  commissions[0].nom_prenom = data[0].nom + " " + data[0].prenom;
                  var date = new Date().toLocaleDateString();
                  commissions[0].date = reformatDateString(date);

                  await fetch(`${apiUrl}/commissions/InsertCommission`,
                    {
                      method: "POST",
                      headers: { 'Content-Type': 'application/json' },
                      body: JSON.stringify(commissions[0])
                    })
                }).then(() => {
                  window.location.reload();
                })

              }

          }
        });
      }
        // if(data[0].kyc == 0){
        //   Swal.fire({
        //     position: 'top-end',
        //     icon: 'success',
        //     title: 'popup_1',
        //     showConfirmButton: false,
        //     timer: 2000
        //   })
        // }else{
        //   Swal.fire({
        //     position: 'top-end',
        //     icon: 'success',
        //     title: 'popup_2',
        //     showConfirmButton: false,
        //     timer: 2000
        //   })
        // }     
       })
        

  }, []);
  return (null
    // <Card>
    //   <CardBody>
    //     <GridContainer>
    //       <GridItem xs={12} sm={12} md={6}>
    //         <div className={classes.cardHeader}>
    //           <h4 className={classes.cardTitle}>Pick your Color</h4>
    //         </div>
    //         <div className={classes.cardContentLeft}>
    //           <Button className={classes.marginRight}>Default</Button>
    //           <Button color="primary" className={classes.marginRight}>
    //             Primary
    //           </Button>
    //           <Button color="info" className={classes.marginRight}>
    //             Info
    //           </Button>
    //           <Button color="success" className={classes.marginRight}>
    //             Success
    //           </Button>
    //           <Button color="warning" className={classes.marginRight}>
    //             Warning
    //           </Button>
    //           <Button color="danger" className={classes.marginRight}>
    //             Danger
    //           </Button>
    //           <Button color="rose" className={classes.marginRight}>
    //             Rose
    //           </Button>
    //         </div>
    //       </GridItem>
    //       <GridItem xs={12} sm={12} md={6}>
    //         <div className={classes.cardHeader}>
    //           <h4 className={classes.cardTitle}>Buttons with Label</h4>
    //         </div>
    //         <div className={classes.cardContentRight}>
    //           <Button className={classes.marginRight}>
    //             <KeyboardArrowLeft className={classes.icons} /> Left
    //           </Button>
    //           <Button className={classes.marginRight}>
    //             Right <KeyboardArrowRight className={classes.icons} />
    //           </Button>
    //           <Button color="info" className={classes.marginRight}>
    //             <PriorityHigh className={classes.icons} /> Info
    //           </Button>
    //           <Button color="success" className={classes.marginRight}>
    //             <Check className={classes.icons} /> Success
    //           </Button>
    //           <Button color="warning" className={classes.marginRight}>
    //             <Warning className={classes.icons} /> Warning
    //           </Button>
    //           <Button color="danger" className={classes.marginRight}>
    //             <Close className={classes.icons} /> Danger
    //           </Button>
    //         </div>
    //       </GridItem>
    //     </GridContainer>
    //     <GridContainer>
    //       <GridItem xs={12} sm={12} md={6}>
    //         <div className={classes.cardHeader}>
    //           <h4 className={classes.cardTitle}>Pick your Size</h4>
    //         </div>
    //         <div className={classes.cardContentLeft}>
    //           <Button color="primary" size="sm" className={classes.marginRight}>
    //             Small
    //           </Button>
    //           <Button color="primary" className={classes.marginRight}>
    //             Regular
    //           </Button>
    //           <Button color="primary" size="lg" className={classes.marginRight}>
    //             Large
    //           </Button>
    //         </div>
    //       </GridItem>
    //       <GridItem xs={12} sm={12} md={6}>
    //         <div className={classes.cardHeader}>
    //           <h4 className={classes.cardTitle}>Pick your Style</h4>
    //         </div>
    //         <div className={classes.cardContentRight}>
    //           <Button color="primary" className={classes.marginRight}>
    //             Default
    //           </Button>
    //           <Button color="primary" round className={classes.marginRight}>
    //             round
    //           </Button>
    //           <Button color="primary" round className={classes.marginRight}>
    //             <Favorite className={classes.icons} /> with icon
    //           </Button>
    //           <Button
    //             justIcon
    //             round
    //             color="primary"
    //             className={classes.marginRight}
    //           >
    //             <Favorite className={classes.icons} />
    //           </Button>
    //           <Button color="primary" simple className={classes.marginRight}>
    //             simple
    //           </Button>
    //         </div>
    //       </GridItem>
    //     </GridContainer>
    //     <GridContainer>
    //       <GridItem xs={12} sm={12} md={6}>
    //         <div className={classes.cardHeader}>
    //           <h4 className={classes.cardTitle}>Pagination</h4>
    //         </div>
    //         <div className={classes.cardContentLeft}>
    //           <Pagination
    //             pages={[
    //               { text: 1 },
    //               { text: "..." },
    //               { text: 5 },
    //               { text: 6 },
    //               { active: true, text: 7 },
    //               { text: 8 },
    //               { text: 9 },
    //               { text: "..." },
    //               { text: 12 }
    //             ]}
    //           />
    //           <Pagination
    //             pages={[
    //               { text: "PREV" },
    //               { text: 1 },
    //               { text: 2 },
    //               { active: true, text: 3 },
    //               { text: 4 },
    //               { text: 5 },
    //               { text: "NEXT" }
    //             ]}
    //             color="info"
    //           />
    //         </div>
    //       </GridItem>
    //       <GridItem xs={12} sm={12} md={6}>
    //         <div className={classes.cardHeader}>
    //           <h4 className={classes.cardTitle}>Button Group</h4>
    //         </div>
    //         <div className={classes.cardContentRight}>
    //           <div className={classes.buttonGroup}>
    //             <Button color="info" className={classes.firstButton}>
    //               Left
    //             </Button>
    //             <Button color="info" className={classes.middleButton}>
    //               Middle
    //             </Button>
    //             <Button color="info" className={classes.lastButton}>
    //               Right
    //             </Button>
    //           </div>
    //           <br />
    //           <br />
    //           <div className={classes.buttonGroup}>
    //             <Button color="info" round className={classes.firstButton}>
    //               1
    //             </Button>
    //             <Button color="info" round className={classes.middleButton}>
    //               2
    //             </Button>
    //             <Button color="info" round className={classes.middleButton}>
    //               3
    //             </Button>
    //             <Button color="info" round className={classes.lastButton}>
    //               4
    //             </Button>
    //           </div>
    //           {` `}
    //           <div className={classes.buttonGroup}>
    //             <Button color="info" round className={classes.firstButton}>
    //               5
    //             </Button>
    //             <Button color="info" round className={classes.middleButton}>
    //               6
    //             </Button>
    //             <Button color="info" round className={classes.lastButton}>
    //               7
    //             </Button>
    //           </div>
    //         </div>
    //       </GridItem>
    //     </GridContainer>
    //     <GridContainer>
    //       <GridItem xs={12}>
    //         <div className={classes.cardHeader}>
    //           <h4 className={classes.cardTitle}>Social buttons</h4>
    //         </div>
    //         <div className={classes.cardContentBottom}>
    //           <GridContainer>
    //             <GridItem xs={12} sm={4} md={4}>
    //               <Button color="twitter">
    //                 <i
    //                   className={
    //                     classes.socialButtonsIcons +
    //                     " " +
    //                     classes.marginRight +
    //                     " fab fa-twitter"
    //                   }
    //                 />{" "}
    //                 Connect with Twitter
    //               </Button>
    //             </GridItem>
    //             <GridItem xs={12} sm={1} md={1}>
    //               <Button justIcon color="twitter">
    //                 <i className={"fab fa-twitter"} />
    //               </Button>
    //             </GridItem>
    //             <GridItem xs={12} sm={1} md={1}>
    //               <Button justIcon round color="twitter">
    //                 <i
    //                   className={classes.socialButtonsIcons + " fab fa-twitter"}
    //                 />
    //               </Button>
    //             </GridItem>
    //             <GridItem xs={12} sm={1} md={1}>
    //               <Button justIcon color="twitter" simple>
    //                 <i
    //                   className={
    //                     classes.socialButtonsIcons +
    //                     " " +
    //                     classes.marginRight +
    //                     " fab fa-twitter"
    //                   }
    //                 />
    //               </Button>
    //             </GridItem>
    //             <GridItem xs={12} sm={4} md={3}>
    //               <Button color="twitter" simple>
    //                 <i
    //                   className={
    //                     classes.socialButtonsIcons +
    //                     " " +
    //                     classes.marginRight +
    //                     " fab fa-twitter"
    //                   }
    //                 />{" "}
    //                 Connect with Twitter
    //               </Button>
    //             </GridItem>
    //           </GridContainer>
    //           <GridContainer>
    //             <GridItem xs={12} sm={4} md={4}>
    //               <Button color="facebook">
    //                 <i
    //                   className={
    //                     classes.socialButtonsIcons +
    //                     " " +
    //                     classes.marginRight +
    //                     " fab fa-facebook-square"
    //                   }
    //                 />{" "}
    //                 Share · 2.2k
    //               </Button>
    //             </GridItem>
    //             <GridItem xs={12} sm={1} md={1}>
    //               <Button justIcon color="facebook">
    //                 <i className={"fab fa-facebook"} />
    //               </Button>
    //             </GridItem>
    //             <GridItem xs={12} sm={1} md={1}>
    //               <Button justIcon round color="facebook">
    //                 <i
    //                   className={
    //                     classes.socialButtonsIcons + " fab fa-facebook"
    //                   }
    //                 />
    //               </Button>
    //             </GridItem>
    //             <GridItem xs={12} sm={1} md={1}>
    //               <Button justIcon color="facebook" simple>
    //                 <i
    //                   className={
    //                     classes.socialButtonsIcons +
    //                     " " +
    //                     classes.marginRight +
    //                     " fab fa-facebook-square"
    //                   }
    //                 />
    //               </Button>
    //             </GridItem>
    //             <GridItem xs={12} sm={4} md={3}>
    //               <Button color="facebook" simple>
    //                 <i
    //                   className={
    //                     classes.socialButtonsIcons +
    //                     " " +
    //                     classes.marginRight +
    //                     " fab fa-facebook-square"
    //                   }
    //                 />{" "}
    //                 Share · 2.2k
    //               </Button>
    //             </GridItem>
    //           </GridContainer>
    //           <GridContainer>
    //             <GridItem xs={12} sm={4} md={4}>
    //               <Button color="google">
    //                 <i
    //                   className={
    //                     classes.socialButtonsIcons +
    //                     " " +
    //                     classes.marginRight +
    //                     " fab fa-google-plus-g"
    //                   }
    //                 />{" "}
    //                 Share on Google+
    //               </Button>
    //             </GridItem>
    //             <GridItem xs={12} sm={1} md={1}>
    //               <Button justIcon color="google">
    //                 <i className={"fab fa-google"} />
    //               </Button>
    //             </GridItem>
    //             <GridItem xs={12} sm={1} md={1}>
    //               <Button justIcon round color="google">
    //                 <i
    //                   className={classes.socialButtonsIcons + " fab fa-google"}
    //                 />
    //               </Button>
    //             </GridItem>
    //             <GridItem xs={12} sm={1} md={1}>
    //               <Button justIcon color="google" simple>
    //                 <i
    //                   className={
    //                     classes.socialButtonsIcons +
    //                     " " +
    //                     classes.marginRight +
    //                     " fab fa-google"
    //                   }
    //                 />
    //               </Button>
    //             </GridItem>
    //             <GridItem xs={12} sm={4} md={3}>
    //               <Button color="google" simple>
    //                 <i
    //                   className={
    //                     classes.socialButtonsIcons +
    //                     " " +
    //                     classes.marginRight +
    //                     " fab fa-google-plus-g"
    //                   }
    //                 />{" "}
    //                 Share on Google+
    //               </Button>
    //             </GridItem>
    //           </GridContainer>
    //           <GridContainer>
    //             <GridItem xs={12} sm={4} md={4}>
    //               <Button color="linkedin">
    //                 <i
    //                   className={
    //                     classes.socialButtonsIcons +
    //                     " " +
    //                     classes.marginRight +
    //                     " fab fa-linkedin"
    //                   }
    //                 />{" "}
    //                 Connect with Linkedin
    //               </Button>
    //             </GridItem>
    //             <GridItem xs={12} sm={1} md={1}>
    //               <Button justIcon color="linkedin">
    //                 <i className={"fab fa-linkedin"} />
    //               </Button>
    //             </GridItem>
    //             <GridItem xs={12} sm={1} md={1}>
    //               <Button justIcon round color="linkedin">
    //                 <i
    //                   className={
    //                     classes.socialButtonsIcons + " fab fa-linkedin"
    //                   }
    //                 />
    //               </Button>
    //             </GridItem>
    //             <GridItem xs={12} sm={1} md={1}>
    //               <Button justIcon color="linkedin" simple>
    //                 <i
    //                   className={
    //                     classes.socialButtonsIcons +
    //                     " " +
    //                     classes.marginRight +
    //                     " fab fa-linkedin"
    //                   }
    //                 />
    //               </Button>
    //             </GridItem>
    //             <GridItem xs={12} sm={4} md={3}>
    //               <Button color="linkedin" simple>
    //                 <i
    //                   className={
    //                     classes.socialButtonsIcons +
    //                     " " +
    //                     classes.marginRight +
    //                     " fab fa-linkedin"
    //                   }
    //                 />{" "}
    //                 Connect with Linkedin
    //               </Button>
    //             </GridItem>
    //           </GridContainer>
    //           <GridContainer>
    //             <GridItem xs={12} sm={4} md={4}>
    //               <Button color="pinterest">
    //                 <i
    //                   className={
    //                     classes.socialButtonsIcons +
    //                     " " +
    //                     classes.marginRight +
    //                     " fab fa-pinterest"
    //                   }
    //                 />{" "}
    //                 Pint it · 212
    //               </Button>
    //             </GridItem>
    //             <GridItem xs={12} sm={1} md={1}>
    //               <Button justIcon color="pinterest">
    //                 <i className={"fab fa-pinterest"} />
    //               </Button>
    //             </GridItem>
    //             <GridItem xs={12} sm={1} md={1}>
    //               <Button justIcon round color="pinterest">
    //                 <i
    //                   className={
    //                     classes.socialButtonsIcons + " fab fa-pinterest"
    //                   }
    //                 />
    //               </Button>
    //             </GridItem>
    //             <GridItem xs={12} sm={1} md={1}>
    //               <Button justIcon color="pinterest" simple>
    //                 <i
    //                   className={
    //                     classes.socialButtonsIcons +
    //                     " " +
    //                     classes.marginRight +
    //                     " fab fa-pinterest"
    //                   }
    //                 />
    //               </Button>
    //             </GridItem>
    //             <GridItem xs={12} sm={4} md={3}>
    //               <Button color="pinterest" simple>
    //                 <i
    //                   className={
    //                     classes.socialButtonsIcons +
    //                     " " +
    //                     classes.marginRight +
    //                     " fab fa-pinterest"
    //                   }
    //                 />{" "}
    //                 Pint it · 212
    //               </Button>
    //             </GridItem>
    //           </GridContainer>
    //           <GridContainer>
    //             <GridItem xs={12} sm={4} md={4}>
    //               <Button color="youtube">
    //                 <i
    //                   className={
    //                     classes.socialButtonsIcons +
    //                     " " +
    //                     classes.marginRight +
    //                     " fab fa-youtube"
    //                   }
    //                 />{" "}
    //                 View on Youtube
    //               </Button>
    //             </GridItem>
    //             <GridItem xs={12} sm={1} md={1}>
    //               <Button justIcon color="youtube">
    //                 <i className={"fab fa-youtube-square"} />
    //               </Button>
    //             </GridItem>
    //             <GridItem xs={12} sm={1} md={1}>
    //               <Button justIcon round color="youtube">
    //                 <i
    //                   className={
    //                     classes.socialButtonsIcons + " fab fa-youtube-square"
    //                   }
    //                 />
    //               </Button>
    //             </GridItem>
    //             <GridItem xs={12} sm={1} md={1}>
    //               <Button justIcon color="youtube" simple>
    //                 <i
    //                   className={
    //                     classes.socialButtonsIcons +
    //                     " " +
    //                     classes.marginRight +
    //                     " fab fa-youtube-square"
    //                   }
    //                 />
    //               </Button>
    //             </GridItem>
    //             <GridItem xs={12} sm={4} md={3}>
    //               <Button color="youtube" simple>
    //                 <i
    //                   className={
    //                     classes.socialButtonsIcons +
    //                     " " +
    //                     classes.marginRight +
    //                     " fab fa-youtube"
    //                   }
    //                 />{" "}
    //                 View on Youtube
    //               </Button>
    //             </GridItem>
    //           </GridContainer>
    //           <GridContainer>
    //             <GridItem xs={12} sm={4} md={4}>
    //               <Button color="tumblr">
    //                 <i
    //                   className={
    //                     classes.socialButtonsIcons +
    //                     " " +
    //                     classes.marginRight +
    //                     " fab fa-tumblr-square"
    //                   }
    //                 />{" "}
    //                 Repost
    //               </Button>
    //             </GridItem>
    //             <GridItem xs={12} sm={1} md={1}>
    //               <Button justIcon color="tumblr">
    //                 <i className={"fab fa-tumblr-square"} />
    //               </Button>
    //             </GridItem>
    //             <GridItem xs={12} sm={1} md={1}>
    //               <Button justIcon round color="tumblr">
    //                 <i
    //                   className={
    //                     classes.socialButtonsIcons + " fab fa-tumblr-square"
    //                   }
    //                 />
    //               </Button>
    //             </GridItem>
    //             <GridItem xs={12} sm={1} md={1}>
    //               <Button justIcon color="tumblr" simple>
    //                 <i
    //                   className={
    //                     classes.socialButtonsIcons +
    //                     " " +
    //                     classes.marginRight +
    //                     " fab fa-tumblr-square"
    //                   }
    //                 />
    //               </Button>
    //             </GridItem>
    //             <GridItem xs={12} sm={4} md={3}>
    //               <Button color="tumblr" simple>
    //                 <i
    //                   className={
    //                     classes.socialButtonsIcons +
    //                     " " +
    //                     classes.marginRight +
    //                     " fab fa-tumblr-square"
    //                   }
    //                 />{" "}
    //                 Repost
    //               </Button>
    //             </GridItem>
    //           </GridContainer>
    //           <GridContainer>
    //             <GridItem xs={12} sm={4} md={4}>
    //               <Button color="github">
    //                 <i
    //                   className={
    //                     classes.socialButtonsIcons +
    //                     " " +
    //                     classes.marginRight +
    //                     " fab fa-github"
    //                   }
    //                 />{" "}
    //                 Connect with Github
    //               </Button>
    //             </GridItem>
    //             <GridItem xs={12} sm={1} md={1}>
    //               <Button justIcon color="github">
    //                 <i className={"fab fa-github"} />
    //               </Button>
    //             </GridItem>
    //             <GridItem xs={12} sm={1} md={1}>
    //               <Button justIcon round color="github">
    //                 <i
    //                   className={classes.socialButtonsIcons + " fab fa-github"}
    //                 />
    //               </Button>
    //             </GridItem>
    //             <GridItem xs={12} sm={1} md={1}>
    //               <Button justIcon color="github" simple>
    //                 <i
    //                   className={
    //                     classes.socialButtonsIcons +
    //                     " " +
    //                     classes.marginRight +
    //                     " fab fa-github"
    //                   }
    //                 />
    //               </Button>
    //             </GridItem>
    //             <GridItem xs={12} sm={4} md={3}>
    //               <Button color="github" simple>
    //                 <i
    //                   className={
    //                     classes.socialButtonsIcons +
    //                     " " +
    //                     classes.marginRight +
    //                     " fab fa-github"
    //                   }
    //                 />{" "}
    //                 Connect with Github
    //               </Button>
    //             </GridItem>
    //           </GridContainer>
    //           <GridContainer>
    //             <GridItem xs={12} sm={4} md={4}>
    //               <Button color="behance">
    //                 <i
    //                   className={
    //                     classes.socialButtonsIcons +
    //                     " " +
    //                     classes.marginRight +
    //                     " fab fa-behance-square"
    //                   }
    //                 />{" "}
    //                 Follow us
    //               </Button>
    //             </GridItem>
    //             <GridItem xs={12} sm={1} md={1}>
    //               <Button justIcon color="behance">
    //                 <i className={"fab fa-behance"} />
    //               </Button>
    //             </GridItem>
    //             <GridItem xs={12} sm={1} md={1}>
    //               <Button justIcon round color="behance">
    //                 <i
    //                   className={
    //                     classes.socialButtonsIcons + " fab fa-behance-square"
    //                   }
    //                 />
    //               </Button>
    //             </GridItem>
    //             <GridItem xs={12} sm={1} md={1}>
    //               <Button justIcon color="behance" simple>
    //                 <i
    //                   className={
    //                     classes.socialButtonsIcons +
    //                     " " +
    //                     classes.marginRight +
    //                     " fab fa-behance"
    //                   }
    //                 />
    //               </Button>
    //             </GridItem>
    //             <GridItem xs={12} sm={4} md={3}>
    //               <Button color="behance" simple>
    //                 <i
    //                   className={
    //                     classes.socialButtonsIcons +
    //                     " " +
    //                     classes.marginRight +
    //                     " fab fa-behance-square"
    //                   }
    //                 />{" "}
    //                 Follow us
    //               </Button>
    //             </GridItem>
    //           </GridContainer>
    //           <GridContainer>
    //             <GridItem xs={12} sm={4} md={4}>
    //               <Button color="dribbble">
    //                 <i
    //                   className={
    //                     classes.socialButtonsIcons +
    //                     " " +
    //                     classes.marginRight +
    //                     " fab fa-dribbble"
    //                   }
    //                 />{" "}
    //                 Find us on Dribble
    //               </Button>
    //             </GridItem>
    //             <GridItem xs={12} sm={1} md={1}>
    //               <Button justIcon color="dribbble">
    //                 <i className={"fab fa-dribbble"} />
    //               </Button>
    //             </GridItem>
    //             <GridItem xs={12} sm={1} md={1}>
    //               <Button justIcon round color="dribbble">
    //                 <i
    //                   className={
    //                     classes.socialButtonsIcons + " fab fa-dribbble"
    //                   }
    //                 />
    //               </Button>
    //             </GridItem>
    //             <GridItem xs={12} sm={1} md={1}>
    //               <Button justIcon color="dribbble" simple>
    //                 <i
    //                   className={
    //                     classes.socialButtonsIcons +
    //                     " " +
    //                     classes.marginRight +
    //                     " fab fa-dribbble"
    //                   }
    //                 />
    //               </Button>
    //             </GridItem>
    //             <GridItem xs={12} sm={4} md={3}>
    //               <Button color="dribbble" simple>
    //                 <i
    //                   className={
    //                     classes.socialButtonsIcons +
    //                     " " +
    //                     classes.marginRight +
    //                     " fab fa-dribbble"
    //                   }
    //                 />{" "}
    //                 Find us on Dribble
    //               </Button>
    //             </GridItem>
    //           </GridContainer>
    //           <GridContainer>
    //             <GridItem xs={12} sm={4} md={4}>
    //               <Button color="reddit">
    //                 <i
    //                   className={
    //                     classes.socialButtonsIcons +
    //                     " " +
    //                     classes.marginRight +
    //                     " fab fa-reddit"
    //                   }
    //                 />{" "}
    //                 Repost · 232
    //               </Button>
    //             </GridItem>
    //             <GridItem xs={12} sm={1} md={1}>
    //               <Button justIcon color="reddit">
    //                 <i className={"fab fa-reddit"} />
    //               </Button>
    //             </GridItem>
    //             <GridItem xs={12} sm={1} md={1}>
    //               <Button justIcon round color="reddit">
    //                 <i
    //                   className={classes.socialButtonsIcons + " fab fa-reddit"}
    //                 />
    //               </Button>
    //             </GridItem>
    //             <GridItem xs={12} sm={1} md={1}>
    //               <Button justIcon color="reddit" simple>
    //                 <i
    //                   className={
    //                     classes.socialButtonsIcons +
    //                     " " +
    //                     classes.marginRight +
    //                     " fab fa-reddit"
    //                   }
    //                 />
    //               </Button>
    //             </GridItem>
    //             <GridItem xs={12} sm={4} md={3}>
    //               <Button color="reddit" simple>
    //                 <i
    //                   className={
    //                     classes.socialButtonsIcons +
    //                     " " +
    //                     classes.marginRight +
    //                     " fab fa-reddit"
    //                   }
    //                 />{" "}
    //                 Repost · 232
    //               </Button>
    //             </GridItem>
    //           </GridContainer>
    //         </div>
    //       </GridItem>
    //     </GridContainer>
    //   </CardBody>
    // </Card>
  );
}
