// import admin
import ErrorPage from "views/Pages/ErrorPage.js";
import LoginPage from "views/Pages/LoginPage.js";
//import PricingPage from "views/Pages/PricingPage.js";
import RegisterPage from "views/Pages/RegisterPage.js";
import MotDePasseOublie from "views/Pages/MotDePasseOublie.js";
import RenitialiserMotDePasseOublie from "views/Pages/RenitialiserMotDePasseOublie.js";

import RegisterPageATS from "views/Pages/RegisterPageATS.js";

// @material-ui/icons
import Image from "@material-ui/icons/Image";

////////////////////// Admin
var dashRoutes = [
  {
    collapse: true,
    name: "Pages",
    rtlName: "صفحات",
    icon: Image,
    state: "pageCollapse",
    views: [
      {
        path: "/connexion",
        name: "Espace de Connexion",
        rtlName: "هعذاتسجيل الدخول",
        mini: "L",
        rtlMini: "هعذا",
        component: LoginPage,
        layout: "/auth"
      },
      {
        path: "/inscription",
        name: "Inscription",
        rtlName: "تسجيل",
        mini: "R",
        rtlMini: "صع",
        component: RegisterPage,
        layout: "/auth"
      },
      {
        path: "/error-page",
        name: "Error Page",
        rtlName: "صفحة الخطأ",
        mini: "E",
        rtlMini: "البريد",
        component: ErrorPage,
        layout: "/auth"
      },
      {
        path: "/mot-de-passe-oublie",
        name: "Mot de passe oublié",
        rtlName: "صفحة الخطأ",
        mini: "P",
        rtlMini: "البريد",
        component: MotDePasseOublie,
        layout: "/auth"
      },
      {
        path: "/Reinitialiser-mot-de-passe",
        name: "Réinitialiser votre mot de passe",
        rtlName: "صفحة الخطأ",
        mini: "P",
        rtlMini: "البريد",
        component: RenitialiserMotDePasseOublie,
        layout: "/auth"
      },
      {
        path: "/ats-trading-services",
        name: "Inscription ATS TRADING SERVICES",
        rtlName: "صفحة الخطأ",
        mini: "I",
        rtlMini: "البريد",
        component: RegisterPageATS,
        layout: "/auth"
      }
    ]
  },
];
export default dashRoutes;
