import React, { useState, useEffect } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// material-ui icons
//import Assignment from "@material-ui/icons/Assignment";
import SettingsApplicationsRoundedIcon from '@material-ui/icons/SettingsApplicationsRounded';
//import Person from "@material-ui/icons/Person";
import Edit from "@material-ui/icons/Edit";
import Close from "@material-ui/icons/Close";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Table from "components/Table/Table.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardIcon from "components/Card/CardIcon.js";
import CardHeader from "components/Card/CardHeader.js";

import styles from "assets/jss/material-dashboard-pro-react/views/extendedTablesStyle.js";
import AuthService from './Auth.js'
const Auth = new AuthService();

const apiUrl = "https://api.lba-investissements.com"

const useStyles = makeStyles(styles);

export default function ExtendedTables() {
  function numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }
  function Somme(x) {
    var ATS = x.filter(function (pilot) {
      return pilot.nom_service === "ATS TRADING SERVICES";
    });
    var IMMO = x.filter(function (pilot) {
      return pilot.nom_service === "LBA IMMO";
    });
    let sumATS = ATS.reduce(function(prev, current) {

        return prev + +current.montant ;

      
    }, 0);
    let sumIMMO = IMMO.reduce(function(prev, current) {

      return prev + +current.montant ;

    
  }, 0);
  setSommeMonATS(sumATS)
  setSommeMonIMMO(sumIMMO)
  
    // console.log(sumATS)  
    // console.log(sumIMMO)  
    // console.log(ATS.length)  
    // console.log(IMMO.length)  

  }
  const classes = useStyles();
  const [servicesData, setServicesData] = useState();
  const [services, setServices] = useState();
  const [SommeMonATS, setSommeMonATS] = useState(0);
  const [SommeMonIMMO, setSommeMonIMMO] = useState(0);

  const [TotalATS, setTotalATS] = useState(0);
  const [TotalIMMO, setTotalIMMO] = useState(0);
  useEffect(() => {

    fetch(`${apiUrl}/services/getServices`)
      .then(response => response.json())
      .then((data) => {
        var obj = {};
        Somme(data["data"]);
        for (var i = 0, len = data["data"].length; i < len; i++)
          obj[data["data"][i]['id_service']] = data["data"][i];
        data["data"] = new Array();
        for (var key in obj)
          data["data"].push(obj[key]);
        setServicesData(data["data"]);
      });
      fetch(`${apiUrl}/services/listServices`)
      .then(response => response.json())
      .then((data) => {
        var ATS = data["data"].filter(function (pilot) {
          return pilot.nom_service === "ATS TRADING SERVICES";
        });
        var IMMO = data["data"].filter(function (pilot) {
          return pilot.nom_service === "LBA IMMO";
        });
        setTotalATS(ATS.length);
        setTotalIMMO(IMMO.length);

      });
  }, []);
  const simpleButtons = [
    { color: "warning", icon: Edit },
    { color: "danger", icon: Close }
  ].map((prop, key) => {
    return (
      <Button
        color={prop.color}
        simple
        className={classes.actionButton}
        key={key}
      >
        <prop.icon className={classes.icon} />
      </Button>
    );
  });
  return (
    <GridContainer>
      <GridItem xs={12}>
        <Card>
          <CardHeader color="success" icon>
            <CardIcon color="success">
              <SettingsApplicationsRoundedIcon />
            </CardIcon>
            <h4 className={classes.cardIconTitle}>Services disponible</h4>
            <font color="black"><small>Liste de tous les services disponible</small></font>
          </CardHeader>
          <CardBody>
            {servicesData !== undefined ?
              <Table
                tableHead={["ID", "Nom du service", "Perf. Mensuel", "Nombre de personne sur le service", "Montant total investi sur ce service"]}
                // tableData={[
                //   ["2","ATS TRADING SERVICES","5%","15", "350€", simpleButtons],
                //   ["1","LBA IMMO","3%","8", "1000€", simpleButtons],
                // ]}
                tableData={[
                  ["2","ATS TRADING SERVICES",servicesData[0].perf + "%",TotalATS, SommeMonATS + "€", simpleButtons],
                  ["1","LBA IMMO",servicesData[1] !== undefined ? servicesData[1].perf : 0 +"%",TotalIMMO, SommeMonIMMO + "€", simpleButtons],
                ]}
                // tableData={servicesData.map(row => ([

                //   // [row.nom_prenom,row.nom_service, row.date, row.montant] 
                //   row.id_service, row.nom_service, row.perf + " %", row.Total, row.SommeMon !== null ? Auth.toFixed(parseFloat(row.SommeMon), 2) : 0 + " €", simpleButtons]))}

                coloredColls={[4]}
                colorsColls={["success"]}

                customCellClasses={[classes.center, classes.right, classes.right]}
                customClassesForCells={[0, 4, 5]}
                customHeadCellClasses={[
                  classes.center,
                  classes.right,
                  classes.right
                ]}
                customHeadClassesForCells={[0, 4, 5]}
              /> : null}
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
}
