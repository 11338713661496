import React, { useState, useEffect } from "react";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import InputAdornment from "@material-ui/core/InputAdornment";
//import Icon from "@material-ui/core/Icon";

// @material-ui/icons
//import Face from "@material-ui/icons/Face";
import Email from "@material-ui/icons/Email";
// import LockOutline from "@material-ui/icons/LockOutline";
import Swal from 'sweetalert2';

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import CardFooter from "components/Card/CardFooter.js";
import {
  transporter,
  getPasswordResetURL,
  resetPasswordTemplate
} from "../Services/Email.js"
import styles from "assets/jss/material-dashboard-pro-react/views/MotDePasseOubliePageStyle.js";
const apiUrl = "https://api.lba-investissements.com" 

const useStyles = makeStyles(styles);
const User = [
  {
    nom_utilisateur: '',
    email: ''
  }
];
export default function LoginPage() {
  const [cardAnimaton, setCardAnimation] = React.useState("cardHidden");
  const [email, setEmail] = useState();
  const [user, setUser] = useState();

  React.useEffect(() => {
    let id = setTimeout(function() {
      setCardAnimation("");
    }, 700);
    // Specify how to clean up after this effect:
    return function cleanup() {
      window.clearTimeout(id);
    };
  });
  const sendEmail = async () => {
    await fetch(`${apiUrl}/information/userByEmail/`+email)
      .then(response => response.json())
      .then(async (data) => {
        User[0].nom_utilisateur = data[0].nom_utilisateur;
        User[0].email = data[0].email;

      }).then(response => {
        fetch(`${apiUrl}/connexion/forgetPassword`,
                      {
                        method: "POST",
                        headers: { 'Content-Type': 'application/json' },
                        body: JSON.stringify(User[0])
                      }).then((response) =>{
                        if(response.status === 200){
                          Swal.fire({
                            position: 'top-end',
                            icon: 'success',
                            title: 'Email de réinitialisation envoyé avec succès',
                            showConfirmButton: false,
                            timer: 1500
                          })
                        }else{
                          Swal.fire({
                            position: 'top-end',
                            icon: 'warning',
                            title: `Error sending email`,
                            showConfirmButton: false,
                            timer: 1500
                          })
                        }
                    })
                      }
                    )
  }
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <GridContainer justify="center">
        <GridItem xs={12} sm={6} md={4}>
          <form>
            <Card login className={classes[cardAnimaton]}>
              <CardHeader
                className={`${classes.cardHeader} ${classes.textCenter}`}
                color="info"
              >
                <h4 className={classes.cardTitle}>Réinitialiser votre mot de passe</h4>
                <div className={classes.socialLine}>
                  {[
                    "fab fa-facebook-square",
                    "fab fa-instagram",
                  ].map((prop, key) => {
                    return (
                      <Button
                        color="transparent"
                        justIcon
                        key={key}
                        className={classes.customButtonClass}
                      >
                        <i className={prop} />
                      </Button>
                    );
                  })}
                </div>
              </CardHeader>
              <CardBody>
                <CustomInput
                  labelText="E-mail"
                  id="email"
                  formControlProps={{
                    fullWidth: true
                  }}
                  inputProps={{
                    onChange: e => {
                      setEmail(e.target.value);
                    },
                    endAdornment: (
                      <InputAdornment position="end">
                        <Email className={classes.inputAdornmentIcon} />
                      </InputAdornment>
                    )
                  }}
                />
              </CardBody>
              <CardFooter className={classes.justifyContentCenter} onClick={() => sendEmail()}>
                <Button color="info" simple size="lg" block>
                  Réinitialiser
                </Button>
              </CardFooter>
            </Card>
          </form>
        </GridItem>
      </GridContainer>
    </div>
  );
}
