import React from "react";
import ReactDOM from "react-dom";
import { createBrowserHistory } from "history";
import { Router, Route, Switch, Redirect } from "react-router-dom";
import Cookies from 'universal-cookie';


import AuthLayout from "layouts/Auth.js";
import ClientLayout from "layouts/Client.js";
import AdminLayout from "layouts/Admin.js";

import "assets/scss/material-dashboard-pro-react.scss?v=1.9.0";
const hist = createBrowserHistory();
const cookies = new Cookies();
const apiUrl = "https://api.lba-investissements.com"

var IdUser = localStorage.getItem("IdUser");
//cookies.set('wordpress_logged_in', {path: '/'})
//cookies.set('PHPSESSID','b49acf560df832c42dcba38e14c31076',{path: '/'});
if (document.cookie.match('wordpress_logged_in') !== null) {
  if (cookies.get('PHPSESSID') !== undefined) {
    fetch(`${apiUrl}/connexion/getUserBySession/` + cookies.get('PHPSESSID'))
      .then(response => response.json())
      .then((data) => {
        if (data[0] != undefined) {
          var stringEle = data[0].session_value.substr(data[0].session_value.indexOf('id|') + 5, (data[0].session_value.length));
          var element = stringEle.replace(";", "");
          fetch(`${apiUrl}/connexion/getUserById/` + element)
            .then(response => response.json())
            .then((data) => {
              var nSubscriber = data[0]['meta_value'].includes("subscriber");
              var nAdmin = data[0]['meta_value'].includes("administrator");

              localStorage.setItem('IdUser', data[0].user_id);

              if (nSubscriber) {
                ReactDOM.render(
                  <Router history={hist}>
                    <Switch>
                      {/* <Route path="/rtl" component={RtlLayout} /> */}
                      <Route exact path="/auth">
                        <Redirect to="/client" />
                      </Route>
                      {/* <Route path="/auth" component={AuthLayout} /> */}
                      <Route path="/client" component={ClientLayout} />
                      {/* <Route path="/admin" component={AdminLayout} /> */}
                      <Redirect from="/" to="/auth" />
                    </Switch>
                  </Router>,
                  document.getElementById("root"),

                )
                //window.location.href = '/client/dashboard';
                // await new Promise(resolve => setTimeout(resolve, 3000)); // 3 sec
                //                         cookies.remove('wordpress_logged_in')

              }
              else if (nAdmin) {
                ReactDOM.render(
                  <Router history={hist}>
                    <Switch>
                      {/* <Route path="/rtl" component={RtlLayout} /> */}
                      <Route exact path="/auth">
                        <Redirect to="/admin" />
                      </Route>
                      {/* <Route path="/auth" component={AuthLayout} /> */}
                      {/* <Route path="/client" component={ClientLayout} /> */}
                      <Route path="/admin" component={AdminLayout} />
                      <Redirect from="/" to="/auth" />
                    </Switch>
                  </Router>,
                  document.getElementById("root"),
                )
                //window.location.href = '/admin/dashboard';

                //useHistory().history.push("/admin/dashboard");

              }

            });
        }
      })

  }
} else if (IdUser != null) {
  fetch(`${apiUrl}/connexion/getUserById/` + IdUser)
    .then(response => response.json())
    .then((data) => {
      var nSubscriber = data[0]['meta_value'].includes("subscriber");
      var nAdmin = data[0]['meta_value'].includes("administrator");
      localStorage.setItem('IdUser', data[0].user_id);

      if (nSubscriber) {
        ReactDOM.render(
          <Router history={hist}>
            <Switch>
              {/* <Route path="/rtl" component={RtlLayout} /> */}
              <Route exact path="/auth">
                <Redirect to="/client" />
              </Route>
              {/* <Route path="/auth" component={AuthLayout} /> */}
              <Route path="/client" component={ClientLayout} />
              {/* <Route path="/admin" component={AdminLayout} /> */}
              <Redirect from="/" to="/auth" />
            </Switch>
          </Router>,
          document.getElementById("root"),

        )
        //window.location.href = '/client/dashboard';
        // await new Promise(resolve => setTimeout(resolve, 3000)); // 3 sec
        //                         cookies.remove('wordpress_logged_in')

      }
      else if (nAdmin) {
        ReactDOM.render(
          <Router history={hist}>
            <Switch>
              {/* <Route path="/rtl" component={RtlLayout} /> */}
              <Route exact path="/auth">
                <Redirect to="/admin" />
              </Route>
              {/* <Route path="/auth" component={AuthLayout} /> */}
              {/* <Route path="/client" component={ClientLayout} /> */}
              <Route path="/admin" component={AdminLayout} />
              <Redirect from="/" to="/auth" />
            </Switch>
          </Router>,
          document.getElementById("root"),
        )
        //window.location.href = '/admin/dashboard';

        //useHistory().history.push("/admin/dashboard");

      }
    
    });
  
}
else {
  ReactDOM.render(
    <Router history={hist}>
      <Switch>
        <Route path="/auth" component={AuthLayout} />
        <Redirect from="/" to="/auth" />
      </Switch>
    </Router>,
    document.getElementById("root"))
  //authuroties = false
}
// if(Auth.hasAnyAuthorities()){
//   ReactDOM.render(
//     <Router history={hist}>
//       <Switch>
//         <Route path="/rtl" component={RtlLayout} />
//         <Route path="/auth" component={AuthLayout} />
//         <Route path="/client" component={ClientLayout} />
//         <Route path="/admin" component={AdminLayout} />
//         <Redirect from="/" to="/auth" />
//       </Switch>
//     </Router>,
//     document.getElementById("root"),  

//   )
// }else{
//   ReactDOM.render(
//       <Router history={hist}>
//     <Switch>
//       <Route path="/auth" component={AuthLayout} />
//       <Redirect from="/" to="/auth" />
//     </Switch>
//     </Router>,
//       document.getElementById("root"))
//   //authuroties = false

// }
// Auth.hasAnyAuthorities() ? 
// ReactDOM.render(
//   <Router history={hist}>
//     <Switch>
//       <Route path="/rtl" component={RtlLayout} />
//       <Route path="/auth" component={AuthLayout} />
//       <Route path="/client" component={ClientLayout} />
//       <Route path="/admin" component={AdminLayout} />
//       <Redirect from="/" to="/auth" />
//     </Switch>
//   </Router>,
//   document.getElementById("root")


// ):  
// ReactDOM.render(
//   <Router history={hist}>
// <Switch>
//   <Route path="/auth" component={AuthLayout} />
//   <Redirect from="/" to="/auth" />
// </Switch>
// </Router>,
//   document.getElementById("root")

// )   
;

