// import admin
/*import Buttons from "views/Components/Buttons.js";
import Calendar from "views/Calendar/Calendar.js";
import Charts from "views/Charts/Charts.js";*/
import Dashboard from "views/Dashboard/Dashboard.js";
/*import ErrorPage from "views/Pages/ErrorPage.js";
import ExtendedForms from "views/Forms/ExtendedForms.js";
import ExtendedTables from "views/Tables/ExtendedTables.js";
import FullScreenMap from "views/Maps/FullScreenMap.js";
import GoogleMaps from "views/Maps/GoogleMaps.js";
import GridSystem from "views/Components/GridSystem.js";
import Icons from "views/Components/Icons.js";
import LockScreenPage from "views/Pages/LockScreenPage.js";
import LoginPage from "views/Pages/LoginPage.js";
import Notifications from "views/Components/Notifications.js";
import Panels from "views/Components/Panels.js";
import PricingPage from "views/Pages/PricingPage.js";
import RTLSupport from "views/Pages/RTLSupport.js";
import ReactTables from "views/Tables/ReactTables.js";
import RegisterPage from "views/Pages/RegisterPage.js";
import RegularForms from "views/Forms/RegularForms.js";
import RegularTables from "views/Tables/RegularTables.js";
import SweetAlert from "views/Components/SweetAlert.js";
import TimelinePage from "views/Pages/Timeline.js";
import Typography from "views/Components/Typography.js";
import UserProfile from "views/Pages/UserProfile.js";
import ValidationForms from "views/Forms/ValidationForms.js";
import VectorMap from "views/Maps/VectorMap.js";
import Widgets from "views/Widgets/Widgets.js";
import Wizard from "views/Forms/Wizard.js";*/

// @material-ui/icons
//import Apps from "@material-ui/icons/Apps";
import DashboardIcon from "@material-ui/icons/Dashboard";
/*import DateRange from "@material-ui/icons/DateRange";
import GridOn from "@material-ui/icons/GridOn";
import Image from "@material-ui/icons/Image";
import Place from "@material-ui/icons/Place";
import Timeline from "@material-ui/icons/Timeline";
import WidgetsIcon from "@material-ui/icons/Widgets";*/

/////////////////////////////////
import Unarchive from "@material-ui/icons/Unarchive";
//import Person from "@material-ui/icons/Person";
import Utilisateurs from "views/Utilisateurs/Utilisateurs.js";
import RetraitClient from "views/RetraitClient/RetraitClient.js";
import PlacementClient from "views/PlacementClient/PlacementClient.js";
import Services from "views/Services/Services.js";
import Redirection from "views/Redirection/Redirection.js";
import EditeProfil from "views/EditeProfil/EditeProfil.js";
import Placement from "views/Placement/Placement.js";
import AccountBoxIcon from '@material-ui/icons/AccountBox';
import CreditCardIcon from '@material-ui/icons/CreditCard';
import SettingsApplicationsRoundedIcon from '@material-ui/icons/SettingsApplicationsRounded';
import PublishRoundedIcon from '@material-ui/icons/PublishRounded';
/////////////////////////////////

////////////////////// Admin
var dashRoutes = [
  {
    path: "/dashboard",
    name: "Vue d'ensemble",
    rtlName: "لوحة القيادة",
    icon: DashboardIcon,
    component: Dashboard,
    layout: "/admin"
  },
  {
    path: "/utilisateurs",
    name: "Utilisateurs",
    //rtlName: "لوحة القيادة",
    icon: AccountBoxIcon,
    component: Utilisateurs,
    layout: "/admin"
  },
  {
    path: "/demande-placements-client",
    name: "Demande placements",
    //rtlName: "لوحة القيادة",
    icon: CreditCardIcon,
    component: PlacementClient,
    layout: "/admin"
  },
  {
    path: "/retrait-client",
    name: "Demandes retraits clients",
    //rtlName: "لوحة القيادة",
    icon: CreditCardIcon,
    component: RetraitClient,
    layout: "/admin"
  },
  {
    collapse: true,
    name: "Services",
    rtlName: "صفحات",
    icon: PublishRoundedIcon,
    state: "depotCollapse",
    views: [
      {
        path: "/services",
        name: "Services disponible",
        rtlName: "عالتسعير",
        mini: "SD",
        rtlMini: "ع",
        component: Services,
        layout: "/admin"
      },
      {
        path: "/placement",
        name: "Placement",
        rtlName: "صودعم رتل",
        mini: "PL",
        rtlMini: "صو",
        component: Placement,
        layout: "/admin"
      },
    ]
  },
  {
    path: "/edite-profil",
    name: "Édition profil client",
    icon: SettingsApplicationsRoundedIcon,
    component: EditeProfil,
    layout: "/admin"
  },
  {
    path: "/redirection",
    name: "Retourné sur le site",
    rtlName: "التطور للاحترافية",
    icon: Unarchive,
    component: Redirection,
    layout: "/admin"
  },
 
];

export default dashRoutes;
