import React, { useState, useEffect } from "react";
import { useHistory } from 'react-router-dom';
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
//import Assignment from "@material-ui/icons/Assignment";
import Dvr from "@material-ui/icons/Dvr";
//import Favorite from "@material-ui/icons/Favorite";
//import Close from "@material-ui/icons/Close";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardIcon from "components/Card/CardIcon.js";
import CardHeader from "components/Card/CardHeader.js";
import ReactTable from "components/ReactTable/ReactTable.js";
import AccountBoxIcon from '@material-ui/icons/AccountBox';
import moment from 'moment';

import { dataTable } from "variables/placement.js";

import { cardTitle } from "assets/jss/material-dashboard-pro-react.js";
const apiUrl = "https://api.lba-investissements.com" 

const styles = {
  cardIconTitle: {
    ...cardTitle,
    marginTop: "15px",
    marginBottom: "0px"
  }
};

const useStyles = makeStyles(styles);

export default function ReactTables() {

  const history = useHistory();

  const [rowsPlacements, setRowsPlacements] = useState();
  useEffect(() => {

    fetch(`${apiUrl}/placements/getAllPlacements`)
      .then(response => response.json())
      .then((data) => {
        setRowsPlacements(data["data"]);
      });
  }, []);

  const handleClickProfile = (obj) => {
    let path = 'edite-profil';
    history.push(path, obj);
  };
  const [data] = React.useState(
    dataTable.dataRows.map((prop, key) => {
      return {
        id: key,
        nomprenom: prop[0],
        refunique: prop[1],
        dateplacement: prop[2],
        service: prop[3],
        mi: prop[4],
        actions: (
          // we've added some custom button actions
          <div className="actions-right">
            {/* use this button to add a like kind of action */}

            {/* use this button to add a edit kind of action */}
            <Button
              justIcon
              round
              simple
              onClick={() => {
                let obj = data.find(o => o.id === key);
                alert(
                  "You've clicked EDIT button on \n{ \nNom Prénom: " +
                  obj.nomprenom +
                  ", \nrefunique: " +
                  obj.refunique +
                  ", \ndateplacement: " +
                  obj.dateplacement +
                  ", \nservice: " +
                  obj.service +
                  ", \nmi: " +
                  obj.mi +
                  "\n}."
                );
              }}
              color="warning"
              className="edit"
            >
              <Dvr />
            </Button>{" Éditer "}
            {/* use this button to remove the data row */}

          </div>
        )
      };
    })
  );
  const classes = useStyles();
  return (
    <GridContainer>
      <GridItem xs={12}>
        <Card>
          <CardHeader color="info" icon>
            <CardIcon color="info">
              <AccountBoxIcon />
            </CardIcon>
            <h4 className={classes.cardIconTitle}>Placements sur les services</h4>
            <font color="black"><small>Liste de tous les placements sur tous les services disponible</small></font>
          </CardHeader>
          <CardBody>
            {rowsPlacements !== undefined ?
              <ReactTable
                columns={[
                  {
                    Header: "Nom Prénom",
                    accessor: "nomprenom"
                  },
                  {
                    Header: "Référence client",
                    accessor: "refunique"
                  },
                  {
                    Header: "Date de placement",
                    accessor: "dateplacement"
                  },
                  {
                    Header: "Service",
                    accessor: "service"
                  },
                  {
                    Header: "Montant investi",
                    accessor: "mi"
                  },
                  {
                    Header: "Actions",
                    accessor: "actions"
                  }
                ]}
                //data={data}
                data={rowsPlacements.map(prop => {
                  return {
                    id: prop.id_placement,
                    nomprenom: prop.nom_prenom,
                    refunique: prop.reference_client,
                    dateplacement: moment(prop.date).format("DD/MM/YYYY"),
                    service: prop.nom_service,
                    mi: prop.montant,
                    actions: (
                      // we've added some custom button actions
                      <div className="actions-right">
                        {/* use this button to add a like kind of action */}

                        {/* use this button to add a edit kind of action */}

                        <Button
                          justIcon
                          round
                          simple
                          // onClick={handleClickProfile(obj = rowsInformation.find(o => o.id_Information === prop.id_Information);)}
                          onClick={() => {
                            let obj = rowsPlacements.find(o => o.id_placement === prop.id_placement);
                            handleClickProfile(obj);
                          }}
                          // {() => {
                          //   let obj = rowsInformation.find(o => o.id_Information === prop.id_Information);
                          //   alert(
                          //     "You've clicked EDIT button on \n{ \nNom Prénom: " +
                          //     obj.nom + " " + obj.prenom +
                          //       ", \nrefunique: " +
                          //       obj.reference_client +
                          //       ", \ndateinscription: " +
                          //       obj.date_inscription +
                          //       ", \nkyc: " +
                          //       obj.kyc +
                          //       ", \nmti: " +
                          //       obj.montant +
                          //       "\n}."
                          //   );
                          // }}
                          color="warning"
                          className="edit"
                        >
                          <Dvr />
                        </Button>{" Éditer "}
                        {/* use this button to remove the data row */}

                      </div>
                    )
                  };
                })}
              />
              :
              null
            }
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
}
