import React, { useState, useEffect } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
//import InputLabel from "@material-ui/core/InputLabel";
// @material-ui/icons
import PermIdentity from "@material-ui/icons/PermIdentity";
import 'sweetalert2/src/sweetalert2.scss'
// ES6 Modules or TypeScript
import Swal from 'sweetalert2'
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Clearfix from "components/Clearfix/Clearfix.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";

import styles from "assets/jss/material-dashboard-pro-react/views/userProfileStyles.js";

const apiUrl = "https://api.lba-investissements.com" 

const useStyles = makeStyles(styles);
const information = [
  {
    nom_utilisateur: '',
      nom: '',
      adresse: '',
      code_postal: '',
      societe: '',
      prenom: '',
      ville: '',
      pays: '',
      email: '',
      telephone: '',
      nom_rib: '',
      code_bic : '',
      rib: ''
  }
];
export default function UserProfile() {
  window.IdUser = localStorage.getItem("IdUser");
  const classes = useStyles();
  const [informationUser, setInformationUsers] = useState();
  const [nom, setNom] = useState('');
  const [nom_utilisateur, setNomUtilisateur] = useState('');
  const [adresse, setAdresse] = useState('');
  const [code_postal, setCode_postal] = useState('');
  const [societe, setSociete] = useState('');
  const [prenom, setPrenom] = useState('');
  const [ville, setVille] = useState('');
  const [pays, setPays] = useState('');
  const [email, setEmail] = useState('');
  const [telephone, setTel] = useState('');
  const [nom_rib, setNomRib] = useState('');
  const [rib, setRib] = useState('');
  const [codeBic, setCodeBic] = useState('');
  useEffect(() => {
    const idUser = localStorage.getItem('IdUser');
    fetch(`${apiUrl}/information/getInformationByIdUser/` + idUser)
      .then(response => response.json())
      .then((data) => {
        setInformationUsers(data[0]);
      });
      

      
  }, []);
  const handleClickUpdate = (obj) => {
    information[0].nom = nom === "" ? obj.nom : nom;
    information[0].nom_utilisateur = nom_utilisateur === "" ? obj.nom_utilisateur : nom_utilisateur;
    information[0].adresse = adresse === "" ? obj.adresse : adresse;
    information[0].code_postal = code_postal === "" ? obj.code_postal : code_postal;
    information[0].societe = societe === "" ? obj.societe : societe;
    information[0].prenom = prenom === "" ? obj.prenom : prenom;
    information[0].ville = ville === "" ? obj.ville : ville;
    information[0].pays = pays === "" ? obj.pays : pays;
    information[0].email = email === "" ? obj.email : email;
    information[0].telephone = telephone === "" ? obj.telephone : telephone;
    information[0].nom_rib = nom_rib === "" ? obj.nom_rib : nom_rib;
    information[0].rib = rib === "" ? obj.rib : rib;
    information[0].code_bic = codeBic === "" ? obj.code_bic : codeBic;

    fetch(`${apiUrl}/information/updateUserInformation/` + obj.id_utilisateur,
        {
          method: "PUT",
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify(information[0])
        }).then((result) =>{
          if(result.status === 200)
          {
            Swal.fire({
              position: 'top-end',
              icon: 'success',
              title: 'mise à jour avec succès',
              showConfirmButton: false,
              timer: 1500
            }).then(() => {
              window.location.reload();
                  })
          }
          else{
            Swal.fire({
              position: 'top-end',
              icon: 'warning',
              title: 'Error',
              showConfirmButton: false,
              timer: 1500
            })
          }
        }).catch((error) => {
          Swal.fire({
            position: 'top-end',
            icon: 'warning',
            title: 'Error',
            showConfirmButton: false,
            timer: 1500
          }) })
    
    // const options = {
    //   autoClose: 6000
    //   // and so on ...
    // };
    // //toast("Update success");
    // toast.success("Update success", {
    //   onClose: () => window.history.back()
    // });
    //window.history.back();
  };

  return (
    <div>
      {informationUser !== undefined ?
        <GridContainer justify="center">
          <GridItem xs={12} sm={12} md={6}>
            <Card>
              <CardHeader color="info" icon>
                <CardIcon color="info">
                  <PermIdentity />
                </CardIcon>
                <h4 className={classes.cardIconTitle}>
                  Édition du profil <small> | {informationUser.kyc === 1 ? "Profil vérifié" : "Profil non verifié"}</small><br />
                  <small>Votre Référence client : {informationUser.reference_client}</small>
                </h4>
              </CardHeader>
              <CardBody>
                <GridContainer>

                  <GridItem xs={12} sm={12} md={5}>
                    <CustomInput
                      //labelText={informationUser.societe}
                      labelText="Société"

                      id="company"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        defaultValue : informationUser.societe,

                        onChange: e => {
                          setSociete(e.target.value);
                        },
                        disabled: informationUser.kyc === 1 ? true : false

                      }}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={3}>
                    <CustomInput
                      labelText="Pseudo"
                      id="username"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        defaultValue : informationUser.nom_utilisateur,
                        onChange: e => {
                          setNomUtilisateur(e.target.value);
                        },
                        disabled: true
                      }}
                    />
                  </GridItem>
                </GridContainer>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={6}>
                    <CustomInput
                      labelText="Nom"
                      id="nom"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        defaultValue: informationUser.nom,
                        //placeholder : informationUser.nom,
                        onChange: e => {
                          setNom(e.target.value);
                        },
                        disabled: informationUser.kyc === 1 ? true : false

                      }}
                    />
                  </GridItem>
                </GridContainer>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={6}>
                    <CustomInput
                      labelText="Prénom"
                      id="Prénom"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        defaultValue : informationUser.prenom,
                        onChange: e => {
                          setPrenom(e.target.value);
                        },
                        disabled: informationUser.kyc === 1 ? true : false

                      }}
                    />
                  </GridItem>
                </GridContainer>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={4}>
                    <CustomInput
                      labelText="Adresse"
                      id="adresse"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        defaultValue : informationUser.adresse,
                        onChange: e => {
                          setAdresse(e.target.value);
                        },
                        disabled: informationUser.kyc === 1 ? true : false

                      }}
                    />
                  </GridItem>
                </GridContainer>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={2}>
                    <CustomInput
                      labelText="Code postal"
                      id="postal-code"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        defaultValue : informationUser.code_postal,
                        onChange: e => {
                          setCode_postal(e.target.value);
                        },
                        disabled: informationUser.kyc === 1 ? true : false

                      }}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={4}>
                    <CustomInput
                      labelText="Ville"
                      id="city"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        defaultValue : informationUser.ville,
                        onChange: e => {
                          setVille(e.target.value);
                        },
                        disabled: informationUser.kyc === 1 ? true : false

                      }}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={2}>
                    <CustomInput
                      labelText="Pays"
                      id="pays"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        defaultValue : informationUser.pays,
                        onChange: e => {
                          setPays(e.target.value);
                        },
                        disabled: informationUser.kyc === 1 ? true : false

                      }}
                    />
                  </GridItem>
                </GridContainer>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={4}>
                    <CustomInput
                      labelText="E-mail"
                      id="email"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        defaultValue : informationUser.email,
                        onChange: e => {
                          setEmail(e.target.value);
                        }

                      }}
                    />
                  </GridItem>
                </GridContainer>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={4}>
                    <CustomInput
                      labelText="N° Téléphone"
                      id="num-telephone"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        defaultValue : informationUser.telephone,
                        onChange: e => {
                          setTel(e.target.value);
                        }

                      }}
                    />
                  </GridItem>
                </GridContainer>
                <CardHeader color="rose" icon>
                  <PermIdentity />
                  <h4 className={classes.cardIconTitle}>
                    Information bancaire<br />
                    <small>Compléter vos information bancaire pour vos demandes de commissions</small>
                  </h4>
                </CardHeader>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={4}>
                    <CustomInput
                      labelText="Nom Complet"
                      id="nom-complet-rib"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        defaultValue : informationUser.nom_rib,
                        disabled: informationUser.kyc === 1 ? true : false,
                        onChange: e => {
                          setNomRib(e.target.value);
                        },

                      }}
                    />
                  </GridItem>
                </GridContainer>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={4}>
                    <CustomInput
                      labelText="RIB"
                      id="rib"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        defaultValue : informationUser.rib,
                        disabled: informationUser.kyc === 1 ? true : false,
                        onChange: e => {
                          setRib(e.target.value);
                        },

                      }}
                    />
                  </GridItem>
                </GridContainer>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={4}>
                    <CustomInput
                      labelText="Code BIC"
                      id="code-bic"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        defaultValue : informationUser.code_bic,
                        disabled: informationUser.kyc === 1 ? true : false,
                        onChange: e => {
                          setCodeBic(e.target.value);
                        },
                      }}
                    />
                  </GridItem>
                </GridContainer>
                <Button color="info" className={classes.updateProfileButton}
                  onClick={() => {
                    handleClickUpdate(informationUser)
                  }}>
                  Mettre à jour
              </Button>
                <Clearfix />
              </CardBody>

            </Card>
          </GridItem>

        </GridContainer>
        : null}
    </div>
  );
}
