import React, { useState, useEffect } from "react";
// react plugin for creating charts
//import ChartistGraph from "react-chartist";
// react plugin for creating vector maps
//import { VectorMap } from "react-jvectormap";
import moment from 'moment';

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
//import Tooltip from "@material-ui/core/Tooltip";
//import Icon from "@material-ui/core/Icon";

// @material-ui/icons
// import ContentCopy from "@material-ui/icons/ContentCopy";
//import Store from "@material-ui/icons/Store";
// import InfoOutline from "@material-ui/icons/InfoOutline";
//import Warning from "@material-ui/icons/Warning";
import DateRange from "@material-ui/icons/DateRange";
import LocalOffer from "@material-ui/icons/LocalOffer";
import Update from "@material-ui/icons/Update";
//import ArrowUpward from "@material-ui/icons/ArrowUpward";
//import AccessTime from "@material-ui/icons/AccessTime";
//import Refresh from "@material-ui/icons/Refresh";
//import Edit from "@material-ui/icons/Edit";
//import Place from "@material-ui/icons/Place";
//import ArtTrack from "@material-ui/icons/ArtTrack";
//import Language from "@material-ui/icons/Language";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Table from "components/Table/Table.js";
//import Button from "components/CustomButtons/Button.js";
//import Danger from "components/Typography/Danger.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import EuroSymbolRoundedIcon from '@material-ui/icons/EuroSymbolRounded';
import CreditCardRoundedIcon from '@material-ui/icons/CreditCardRounded';
import BusinessCenterRoundedIcon from '@material-ui/icons/BusinessCenterRounded';
import TrendingUpRoundedIcon from '@material-ui/icons/TrendingUpRounded';
import AuthService from '../../Services/Auth.js'

///////////////////////////////
/*import BugReport from "@material-ui/icons/BugReport";
import Tasks from "components/Tasks/Tasks.js";
import Cloud from "@material-ui/icons/Cloud";
import CustomTabs from "components/CustomTabs/CustomTabs.js";
import { bugs, website, server } from "variables/general.js";
import Code from "@material-ui/icons/Code";*/

//////////////////////////////


/*import {
  dailySalesChart,
  emailsSubscriptionChart,
  completedTasksChart
} from "variables/charts";*/

import styles from "assets/jss/material-dashboard-pro-react/views/dashboardStyle.js";


//import priceImage1 from "assets/img/card-2.jpeg";
//import priceImage2 from "assets/img/card-3.jpeg";
//import priceImage3 from "assets/img/card-1.jpeg";

/*const us_flag = require("assets/img/flags/US.png");
const de_flag = require("assets/img/flags/DE.png");
const au_flag = require("assets/img/flags/AU.png");
const gb_flag = require("assets/img/flags/GB.png");
const ro_flag = require("assets/img/flags/RO.png");
const br_flag = require("assets/img/flags/BR.png");*/

const apiUrl = "https://api.lba-investissements.com"
const Auth = new AuthService();

const useStyles = makeStyles(styles);
const information = [
  {
    value: '',
    revenue: '',
    revenueCommission: '',
    revenueNonValid: '',
    revenueValid: ''

  }
];
window.IdUser = 0;
export default function Dashboard(props) {
  const classes = useStyles();
  const [sumMontant, setSommeMontant] = useState('');
  const [creditUser, setCredit] = useState('');
  const [sumMontantATS, setSommeMontantATS] = useState('');
  const [sumMontantCommissions, setSommeMontantCommissions] = useState('');
  const [sumMontantCommissionsNonValid, setSommeMontantCommissionsNonValid] = useState('');
  const [roi, setSommeRoi] = useState('');
  const [rowsPlacements, setRowsPlacements] = useState();
  const [rowsCommissions, setRowsCommissions] = useState();
  function numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }
  window.IdUser = localStorage.getItem('IdUser');

  useEffect(() => {
    //if(props["history"]["location"]["state"] !== null)
    //{
    //const user_id = props["history"]["location"]["state"].user_id;
    //localStorage.setItem('IdUser',props["history"]["location"]["state"].user_id);
    //window.IdUser = props["history"]["location"]["state"].user_id;
    const fetchData = async () => {
      await fetch(`${apiUrl}/placements/sumMontantUser/` + window.IdUser)
        .then(response => response.json())
        .then((data) => {
          //localStorage.setItem('SommeMount',parseFloat(data[0].Somme+parseFloat(data[0].SommeRea)));
          //setSommeMontant(numberWithCommas(Math.round(parseFloat(data[0].Somme+parseFloat(data[0].SommeRea)))));
          setSommeMontant(parseFloat(data[0].Somme + (data[0].SommeRea !== null ? parseFloat(data[0].SommeRea) : 0)));

          information[0].value = parseFloat(data[0].Somme + (data[0].SommeRea !== null ? parseFloat(data[0].SommeRea) : 0));

        }).then(async () => {
          await fetch(`${apiUrl}/information/updateTotalInvesti/` + window.IdUser,
            {
              method: "PUT",
              headers: { 'Content-Type': 'application/json' },
              body: JSON.stringify(information[0])
            });
        });
        await fetch(`${apiUrl}/placements/sumMontantUserATS/` + window.IdUser)
        .then(response => response.json())
        .then((data) => {
          setSommeMontantATS(parseFloat(data[0].Somme + (data[0].SommeATS !== null ? parseFloat(data[0].SommeATS) : 0)));

        })
        await fetch(`${apiUrl}/commissions/sumCommissionUser/` + window.IdUser)
        .then(response => response.json())
        .then((data) => {
          var somme = data["data"][0].Somme != null ? data["data"][0].Somme : 0;
          var reajust_revenue =  data["data"][0].reajust_revenue != null ? data["data"][0].reajust_revenue : 0;
          setSommeMontantCommissions(parseFloat(somme) + parseFloat(reajust_revenue));
          information[0].revenue = parseFloat(somme) + parseFloat(reajust_revenue);

        }).then(async () => {
          await fetch(`${apiUrl}/information/updateTotalRevenue/` + window.IdUser,
            {
              method: "PUT",
              headers: { 'Content-Type': 'application/json' },
              body: JSON.stringify(information[0])
            });
        }).then(async ()=>{
          await fetch(`${apiUrl}/commissions/sumMontantCommissionUser/` + window.IdUser)
          .then(response => response.json())
          .then((data) => {
            var somme = data[0].Somme != null ? data[0].Somme : 0;
            information[0].revenueValid = parseFloat(somme);
  
          }).then(async ()=>{
            await fetch(`${apiUrl}/commissions/sumMontantCommissionUserNonValide/` + window.IdUser)
          .then(response => response.json())
          .then((data) => {

            var somme = data[0].Somme != null ? data[0].Somme : 0;
            var SommeRea = data[0].SommeRea != null ? data[0].SommeRea : 0;            
            information[0].revenueCommission = parseFloat(somme + SommeRea);
            var revenueCommission = (information[0].revenueCommission != null || information[0].revenueCommission == "") ? information[0].revenueCommission : 0;
            var revenueValid = information[0].revenueValid != null ? information[0].revenueValid : 0;
            setSommeMontantCommissionsNonValid(parseFloat(revenueCommission - revenueValid));

            
            //localStorage.setItem('SommeMount',parseFloat(data[0].Somme+parseFloat(data[0].SommeRea)));
            //setSommeMontantCommissionsNonValid(parseFloat(data[0].Somme - information[0].revenue));
            information[0].revenueNonValid = parseFloat(revenueCommission - revenueValid);
  
  
  
          })
          })
          .then(async () => {
            await fetch(`${apiUrl}/information/updateTotalCommissions/` + window.IdUser,
              {
                method: "PUT",
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(information[0])
              });
          }).then(() => {
            const pourcentage = ((information[0].revenueCommission * 100) / information[0].value);
            console.log(pourcentage)
  
            //setSommeRoi(numberWithCommas(pourcentage.toFixed(2)).replace('.', ','));
            setSommeRoi(pourcentage);
  
          });
        })
     

    }
    fetchData();
    fetch(`${apiUrl}/placements/getPlacementsUser/` + window.IdUser)
      .then(response => response.json())
      .then((data) => {
        setRowsPlacements(data["data"]);
      });
      fetch(`${apiUrl}/commissions/getCommissionsUser/` + window.IdUser)
      .then(response => response.json())
      .then((data) => {
        setRowsCommissions(data["data"]);
      });

      fetch(`${apiUrl}/information/creditUser/` + window.IdUser)
      .then(response => response.json())
      .then((data) => {
        setCredit(data[0].credit);
      });

    //}
  }, []);

  return (
    <div>
      <GridContainer>
        <GridItem xs={12} sm={6} md={6} lg={3}>
          <Card>
            <CardHeader color="success" stats icon>
              <CardIcon color="success">
                <BusinessCenterRoundedIcon />
              </CardIcon>
              <p className={classes.cardCategory}>Total investi</p>

              <h3 className={classes.cardTitle}>
                {isNaN(sumMontant) ? 0 : sumMontant} <small>€</small>
              </h3>
              <p className={classes.cardCategory}>Total ATS Trading Services</p>

              <h3 className={classes.cardTitle}>
                {isNaN(sumMontantATS) ? 0 : sumMontantATS} <small>€</small>
              </h3>
            </CardHeader>
            <CardFooter stats>
              <div className={classes.stats}>
                <Update />
                Total de mes investissements
              </div>
            </CardFooter>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={6} md={6} lg={3}>
          <Card>
            <CardHeader color="info" stats icon>
              <CardIcon color="info">
                <CreditCardRoundedIcon />
              </CardIcon>
              <p className={classes.cardCategory}>Revenue</p>
              <h3 className={classes.cardTitle}>
                {isNaN(sumMontantCommissions) ? 0 : Auth.toFixed(parseFloat(sumMontantCommissions), 2)} <small>€</small>
              </h3>
            </CardHeader>
            <CardFooter stats>
              <div className={classes.stats}>
                <DateRange />
                Total de mes commissions retirer
              </div>
            </CardFooter>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={6} md={6} lg={3}>
          <Card>
            <CardHeader color="warning" stats icon>
              <CardIcon color="warning">
                <EuroSymbolRoundedIcon />
              </CardIcon>
              <p className={classes.cardCategory}>Commission</p>
              <h3 className={classes.cardTitle}>
                {isNaN(sumMontantCommissionsNonValid) ? 0 : Auth.toFixed(parseFloat(sumMontantCommissionsNonValid), 2)} <small>€</small>
              </h3>
              <p className={classes.cardCategory}>Crédit</p>
              <h3 className={classes.cardTitle}>{creditUser} <small>€</small>
              </h3>
            </CardHeader>
            <CardFooter stats>
              <div className={classes.stats}>
                <LocalOffer />
                Total des commissions disponible
              </div>
            </CardFooter>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={6} md={6} lg={3}>
          <Card>
            <CardHeader color="info" stats icon>
              <CardIcon color="info">
                <TrendingUpRoundedIcon />
              </CardIcon>
              <p className={classes.cardCategory}>ROI</p>
              <h3 className={classes.cardTitle}>
                {isNaN(roi) ? 0 : Auth.toFixed(parseFloat(roi), 2)} <small>%</small>
              </h3>
            </CardHeader>
            <CardFooter stats>
              <div className={classes.stats}>
                <Update />
                ROI = Retour sur investissements
              </div>
            </CardFooter>
          </Card>
        </GridItem>
      </GridContainer>

      <GridContainer>
        <GridItem xs={12} sm={12} md={6}>
          <Card>
            <CardHeader color="success">
              <h4 className={classes.cardTitleWhite}>Mes derniers investissements</h4>
              <p className={classes.cardCategoryWhite}>
                Mes dernières investissements effectué
              </p>
            </CardHeader>
            <CardBody>
              {rowsPlacements !== undefined ?

                <Table
                  tableHeaderColor="warning"
                  tableHead={["Services", "Date", "Montant"]}
                  // tableData={[
                  //   ["ATS TRADING SERVICES", "15/01/2021", "500€"],
                  //   ["LBA IMMO", "13/01/2021", "1000€"],
                  //   ["ATS TRADING SERVICES", "08/01/2021", "350€"],
                  //   ["LBA IMMO", "22/12/2020", "50€"],
                  //   ["LBA IMMO", "13/01/2021", "720€"],
                  //   ["LBA IMMO", "18/11/2020", "1430€"],
                  //   ["ATS TRADING SERVICES", "28/10/2020", "500€"],
                  //   ["LBA IMMO", "25/10/2020", "1000€"],
                  //   ["ATS TRADING SERVICES", "23/09/2020", "350€"],
                  //   ["ATS TRADING SERVICES", "19/09/2020", "490€"],
                  // ]}
                  tableData={rowsPlacements.map(row => ([

                    // [row.nom_prenom,row.nom_service, row.date, row.montant]
                    row.nom_service, moment(row.date).format("DD/MM/YYYY"), Auth.toFixed(parseFloat(row.montant), 2) + "€"]))}

                  coloredColls={[2]}
                  colorsColls={["success"]}
                /> : null}
            </CardBody>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={12} md={6}>
          <Card>
            <CardHeader color="warning">
              <h4 className={classes.cardTitleWhite}>Mes commissions</h4>
              <p className={classes.cardCategoryWhite}>
                Mes dernières commissions gagnées
              </p>
            </CardHeader>
            <CardBody>
              {rowsCommissions !== undefined ?
                <Table
                  tableHeaderColor="warning"
                  tableHead={["Services", "Date", "Montant"]}
                  // tableData={[
                  //   ["ATS TRADING SERVICES", "15/01/2021", "49,5€"],
                  //   ["LBA IMMO", "15/01/2021", "13€"],
                  //   ["ATS TRADING SERVICES", "14/01/2021", "49€"],
                  //   ["LBA IMMO", "14/01/2021", "12,8€"],
                  //   ["ATS TRADING SERVICES", "13/01/2021", "48,5€"],
                  //   ["LBA IMMO", "13/01/2021", "12,1€"],
                  //   ["ATS TRADING SERVICES", "12/01/2021", "49€"],
                  //   ["LBA IMMO", "12/01/2021", "12,8€"],
                  //   ["ATS TRADING SERVICES", "11/01/2021", "48,5€"],
                  //   ["LBA IMMO", "11/01/2021", "12,1€"],
                  // ]}
                  tableData={rowsCommissions.map(row => ([

                    // [row.nom_prenom,row.nom_service, row.date, row.montant]
                    row.nom_service, moment(row.date).format("DD/MM/YYYY"), Auth.toFixed(parseFloat(row.montant), 2) + "€"]))}


                  coloredColls={[2]}
                  colorsColls={["success"]}
                /> : null}
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  );
}
