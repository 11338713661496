import React, { useState, useEffect } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { useHistory } from 'react-router-dom';

// material-ui icons
//import Assignment from "@material-ui/icons/Assignment";
import CreditCardIcon from '@material-ui/icons/CreditCard';
//import Person from "@material-ui/icons/Person";
import Edit from "@material-ui/icons/Edit";
import Close from "@material-ui/icons/Close";
import CheckCircleRoundedIcon from '@material-ui/icons/CheckCircleRounded';
import moment from 'moment';
import 'sweetalert2/src/sweetalert2.scss';
import Swal from 'sweetalert2';
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Table from "components/Table/Table.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardIcon from "components/Card/CardIcon.js";
import CardHeader from "components/Card/CardHeader.js";

import styles from "assets/jss/material-dashboard-pro-react/views/extendedTablesStyle.js";

const apiUrl = "https://api.lba-investissements.com" 

const useStyles = makeStyles(styles);
const placement = [
  {
    id_utilisateur: '',
    nom_prenom: '',
    reference_client: '',
    date: '',
    nom_service: '',
    montant: '',
  }
];
const commissions = [
  {
    id_utilisateur: '',
    nom_prenom: '',
    status: '',
    date: '',
    montant: '',
    nom_service : ''
  }
];
const information = [
  {
    value: ''
  }
];
const Credit = [
  {
    credit: ''
  }
];
export default function DemandePlacement() {
  const classes = useStyles();
  const [rowsDemande, setRowsDemande] = useState();
  const history = useHistory();

  useEffect(() => {
    fetch(`${apiUrl}/placements/getDemandePlacements`)
      .then(response => response.json())
      .then((data) => {
        setRowsDemande(data["data"]);
      });

  }, []);

  return (
    <GridContainer>
      <GridItem xs={12}>
        <Card>
          <CardHeader color="warning" icon>
            <CardIcon color="warning">
              <CreditCardIcon />
            </CardIcon>
            <h4 className={classes.cardIconTitle}>Demandes de placements clients</h4>
            <font color="black"><small>Liste de toutes les demandes de placements clients</small></font>
          </CardHeader>
          <CardBody>
            {rowsDemande !== undefined ?
              <Table
                tableHead={["Nom Prénom", "Référence client", "Date de demande", "Service","Portefeuille", "Montant demandé"]}
                // tableData={[

                //   ["Dupont Jean","LBA01006", "05/12/2020", "✔", "350€", simpleButtons],
                //   ["Hirtz Alex","LBA01005", "05/12/2020", "✔", "1000€", simpleButtons],
                //   ["Meyer Damien","LBA01004", "05/12/2020", "-", "50€", simpleButtons],
                //   ["Dubois Jean","LBA01003", "05/12/2020", "-", "90€", simpleButtons],
                //   ["Muller Paul","LBA01002", "05/12/2020", "-", "800€", simpleButtons],
                //   ["Berra Luc","LBA01001", "05/12/2020", "✔", "1500€", simpleButtons]
                // ]}
                tableData={rowsDemande.map((row) => ([

                  // [row.nom_prenom,row.nom_service, row.date, row.montant]
                  //row.nom_prenom, row.reference_client, moment(row.date).format("DD/MM/YYYY"), row.kyc === 0 ? "-" : "✔" ,numberWithCommas(Math.round(parseFloat(row.montant)).toFixed(2)) + " €",simpleButtons])
                  row.nom_prenom, row.reference_client, moment(row.date).format("DD/MM/YYYY"), row.nom_service,row.portefeuille, parseFloat(row.montant).toFixed(2) + " €",
                  [
                    { color: "success", icon: CheckCircleRoundedIcon, data: row },
                    { color: "warning", icon: Edit, data: row },
                    { color: "danger", icon: Close, data: row }
                  ].map((prop, key) => {
                    console.log(prop["data"])

                    return (
                      <Button
                        color={prop.color}
                        simple
                        className={classes.actionButton}
                        key={key}
                        //onClick={(e)=>clickButton(e)}
                        onClick={() => {

                          if (prop.color === "success") {
                            placement[0].date = prop["data"].date;
                            placement[0].id_utilisateur = prop["data"].id_utilisateur;
                            placement[0].montant = prop["data"].montant;
                            placement[0].nom_prenom = prop["data"].nom_prenom;
                            placement[0].nom_service = prop["data"].nom_service;
                            placement[0].reference_client = prop["data"].reference_client;
                            fetch(`${apiUrl}/placements/addPlacements`,
                                {
                                  method: "POST",
                                  headers: { 'Content-Type': 'application/json' },
                                  body: JSON.stringify(placement[0])
                                }).then(async() => {
                                  await fetch(`${apiUrl}/placements/sumMontantUser/` + prop["data"].id_utilisateur)
                                  .then(response => response.json())
                                  .then((data) => {    
                                    information[0].value = parseFloat(data[0].Somme + (data[0].SommeRea !== null ? parseFloat(data[0].SommeRea) : 0));
                          
                                  }).then( async() => {
                                    await fetch(`${apiUrl}/information/updateTotalInvesti/` + prop["data"].id_utilisateur,
                                      {
                                        method: "PUT",
                                        headers: { 'Content-Type': 'application/json' },
                                        body: JSON.stringify(information[0])
                                      });
                                  });
                                  if(row.portefeuille === "Commissions"){

                                    commissions[0].id_utilisateur = prop["data"].id_utilisateur;
                                    commissions[0].montant = prop["data"].montant;
                                    commissions[0].nom_prenom = prop["data"].nom_prenom;
                                    commissions[0].nom_service = prop["data"].nom_service;

                                    // var date = new Date().toLocaleDateString();
                                    // commissions[0].date = reformatDateString(date);
                                    commissions[0].date = prop["data"].date;

                                  await fetch(`${apiUrl}/commissions/InsertComm`,
                                  {
                                    method: "POST",
                                    headers: { 'Content-Type': 'application/json' },
                                    body: JSON.stringify(commissions[0])
                                  })
                                                                      
                                }else{
                                  Credit[0].credit = prop["data"].montant;
                                  fetch(`${apiUrl}/information/updateCreditPlacement/` + prop["data"].id_utilisateur,
                                  {
                                    method: "PUT",
                                    headers: { 'Content-Type': 'application/json' },
                                    body: JSON.stringify(Credit[0])
                                  })
                                }
                                }).then(() => {
                                  fetch(`${apiUrl}/placements/deleteDemande/`+ prop["data"].id_demande,
                                  {
                                    method: "DELETE",
                                  })
                                })
                                .then((result) => {
                                  Swal.fire({
                                    position: 'top-end',
                                    icon: 'success',
                                    title: 'Mise à jour avec succès',
                                    showConfirmButton: false,
                                    timer: 1500
                                  }).then(() => {
                                    window.location.reload(); 
                                  
                                  })
                                
                                // else {
                                //   Swal.fire({
                                //     position: 'top-end',
                                //     icon: 'warning',
                                //     title: 'Erreur Mise à jour',
                                //     showConfirmButton: false,
                                //     timer: 1500
                                //   })
                                // }
                              }).catch((error) => {
                                Swal.fire({
                                  position: 'top-end',
                                  icon: 'warning',
                                  title: 'Error',
                                  showConfirmButton: false,
                                  timer: 1500
                                })
                              })
                          }
                          if (prop.color === "danger") {
                            Swal.fire({
                              title: 'Voulez-vous supprimer ce demande?',
                              showDenyButton: true,
                              confirmButtonText: `Annuler`,
                              denyButtonText: `Accepter`,
                            }).then((result) => {
                              /* Read more about isConfirmed, isDenied below */
                              if (result.isConfirmed) {
                                //Swal.fire('Saved!', '', 'success')
                              } else if (result.isDenied) {
                                fetch(`${apiUrl}/placements/deleteDemande/`+ prop["data"].id_demande,
                                  {
                                    method: "DELETE",
                                  }).then((result) => {
                                  if (result.status === 200) {
                                    Swal.fire({
                                      position: 'top-end',
                                      icon: 'success',
                                      title: 'suppression avec succès ',
                                      showConfirmButton: false,
                                      timer: 1500
                                    }).then(() => {
                                      window.location.reload(); 
                                    
                                    })
                                  }
                                  else {
                                    Swal.fire({
                                      position: 'top-end',
                                      icon: 'warning',
                                      title: 'Erreur Mise à jour',
                                      showConfirmButton: false,
                                      timer: 1500
                                    })
                                  }
                                })
                                                            }
                            })
                          }else if (prop.color === "warning") {
                            history.push(`edite-profil`,row);
                          }
                        }}
                      >
                        <prop.icon className={classes.icon} />
                      </Button>
                    );
                  })
                ])

                )}

                coloredColls={[5]}
                colorsColls={["success"]}

                customCellClasses={[classes.center, classes.right, classes.right]}
                customClassesForCells={[0, 4, 5]}
                customHeadCellClasses={[
                  classes.center,
                  classes.right,
                  classes.right
                ]}
                customHeadClassesForCells={[0, 4, 5]}
              /> :
              null}
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
}
