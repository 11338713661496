import {  useEffect } from "react";

// creates a beautiful scrollbar
import "perfect-scrollbar/css/perfect-scrollbar.css";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import $ from 'jquery';
import swal from '@sweetalert/with-react'
import moment from 'moment';


import 'sweetalert2/src/sweetalert2.scss';
import Swal from 'sweetalert2';
import AuthService from '../views/Services/Auth.js'

import styles from "assets/jss/material-dashboard-pro-react/layouts/rtlStyle.js";
const apiUrl = "https://api.lba-investissements.com"
const Auth = new AuthService();

const demandePlacements = [
  {
    portefeuille: '',
    id_utilisateur: '',
    nom_service: 'LBA IMMO',
    date: moment(Date.now()).format("YYYY-MM-DD hh:mm:ss"),
    montant: '',
    nom_prenom: '',
    reference_client: ''
  }
];
const userInformation = [
  {
    commissions: '',
    id_utilisateur: '',
    nom: '',
    prenom: '',
    reference_client: '',
    credit: ''
  }
];

const useStyles = makeStyles(styles);

export default function RTL(props) {
  const idUser = localStorage.getItem('IdUser');
  //const [rowsPlacements, setRowsPlacements] = useState();
  //const [userInformation, setUserInformation] = useState();
  //const [creditUser, setCredit] = useState('');
  // const { ...rest } = props;
  // // states and functions
  // const [mobileOpen, setMobileOpen] = React.useState(false);
  // const [miniActive, setMiniActive] = React.useState(false);
  // const [image, setImage] = React.useState(require("assets/img/sidebar-2.jpg"));
  // const [color, setColor] = React.useState("blue");
  // const [bgColor, setBgColor] = React.useState("black");
  // // const [hasImage, setHasImage] = React.useState(true);
  // const [fixedClasses, setFixedClasses] = React.useState("dropdown");
  // const [logo, setLogo] = React.useState(require("assets/img/logo-white.svg"));
  // // styles
  // const classes = useStyles();
  // const mainPanelClasses =
  //   classes.mainPanel +
  //   " " +
  //   cx({
  //     [classes.mainPanelSidebarMini]: miniActive
  //   });
  // // ref for main panel div
  // const mainPanel = React.createRef();
  // effect instead of componentDidMount, componentDidUpdate and componentWillUnmount
  useEffect(() => {
    fetch(`${apiUrl}/information/getInformationByIdUser/` + idUser)
      .then(response => response.json())
      .then((data) => {
        userInformation[0].commissions = data[0].commissions;
        userInformation[0].reference_client = data[0].reference_client;
        userInformation[0].nom = data[0].nom;
        userInformation[0].prenom = data[0].prenom;
        userInformation[0].id_utilisateur = data[0].id_utilisateur;

        //setUserInformation(data[0]);
      });
    fetch(`${apiUrl}/information/creditUser/` + idUser)
      .then(response => response.json())
      .then((data) => {
        userInformation[0].credit = data[0].credit
        //setCredit(data[0].credit);
      });
    swal({
      title: 'Données Utilisateurs',
      text: 'Redirecting...',
      icon: 'success',
      timer: 1000,
      buttons: false,
    }).then(() => {
      Swal.fire({
        title: "Nouvelle demande de placement \n" +
          "Service : <b>LBA IMMO</b>",
        type: 'info',
        input: 'select',
        inputOptions: {
          creditUser: 'Crédit - ( ' + Auth.toFixed(parseFloat(userInformation[0].credit,2)) + ' )',
          commissions: 'Commissions - ( ' + (isNaN(parseFloat(userInformation[0].commissions)) ? 0 : Auth.toFixed(parseFloat(userInformation[0].commissions),2)) + ' )'


        },
        inputPlaceholder: 'Choisir Portefeuille',
        //showCancelButton: true,

        html:
          '<p id="label">Montant désiré : <p>\n' +
          '<input id="swal-input1" class="swal2-input">',

        //input: 'text',
        showDenyButton: true,
        //showCancelButton: true,
        confirmButtonText: 'Validé',
        denyButtonText: 'Annulé',
        //showCloseButton: true,
        inputValidator: async () => {
          var select = $(".swal2-select").val();
          return new Promise((resolve) => {
            if (select !== null) {
              resolve()
            } else {
              resolve('Veuillez renseigner tous les champs')
            }
          })
        },
        preConfirm: () => {
          var input = $("#swal-input1").val();
          if (input === "") {
            Swal.showValidationMessage(
              '<i class="fa fa-info-circle"></i>Veuillez renseigner tous les champs'
            )
          }
          if (parseFloat($("#swal-input1").val()) > parseFloat(userInformation[0].credit) && ($(".swal2-select").val() === "creditUser") || ($("#swal-input1").val() < 50)) {
            Swal.showValidationMessage(
              '<i class="fa fa-info-circle"></i>Montant invalid'
            )
          }
          if (parseFloat($("#swal-input1").val()) > parseFloat(userInformation[0].commissions) && ($(".swal2-select").val() === "commissions") || ($("#swal-input1").val() < 50)) {
            Swal.showValidationMessage(
              '<i class="fa fa-info-circle"></i>Montant invalid'
            )
          }
        },
        onOpen: function (ele) {
          $(ele).find('.swal2-select').insertBefore($(ele).find('#label'));
        }

      }).then((result) => {
        demandePlacements[0].id_utilisateur = userInformation[0].id_utilisateur;
        demandePlacements[0].nom_prenom = userInformation[0].nom + " " + userInformation[0].prenom;
        demandePlacements[0].reference_client = userInformation[0].reference_client;
        demandePlacements[0].montant = $("#swal-input1").val();

        if (result.isDenied) {
        }
        else if (result.isConfirmed) {
          if ($(".swal2-select").val() === "creditUser") {
            Swal.fire({
              title: "Vous êtes sur le point de placé " + $("#swal-input1").val() + "€ sur le service \n" +
                "LBA IMMO \n" +
                "voulez-vous continuer ?</b>",
              type: 'info',
              showDenyButton: true,
              confirmButtonText: 'Validé',
              denyButtonText: 'Annulé',

            }).then((result) => {
              if (result.isConfirmed) {

                demandePlacements[0].portefeuille = "Crédit";

                swal({
                  title: 'Votre placement à bien été enregistrer \n',
                  text: 'votre placement sera prochainement actif',
                  icon: 'success',
                  timer: 3000,
                  buttons: false,
                }).then(async () => {
                  await fetch(`${apiUrl}/placements/InsertDemande`,
                    {
                      method: "POST",
                      headers: { 'Content-Type': 'application/json' },
                      body: JSON.stringify(demandePlacements[0])
                    })
                })
              } else {

              }
            })

          } else {
            Swal.fire({
              title: "Vous êtes sur le point de placé " + $("#swal-input1").val() + "€ sur le service \n" +
                "ATS Trading Services \n" +
                "voulez-vous continuer ?</b>",
              type: 'info',
              showDenyButton: true,
              confirmButtonText: 'Validé',
              denyButtonText: 'Annulé',

            }).then((result) => {
              if (result.isConfirmed) {
                demandePlacements[0].portefeuille = "Commissions";

                swal({
                  title: 'Votre placement à bien été enregistrer \n',
                  text: 'votre placement sera prochainement actif',
                  icon: 'success',
                  timer: 3000,
                  buttons: false,
                }).then(async () => {
                  await fetch(`${apiUrl}/placements/InsertDemande`,
                    {
                      method: "POST",
                      headers: { 'Content-Type': 'application/json' },
                      body: JSON.stringify(demandePlacements[0])
                    })
                })
              } else {
                swal.close();
              }
            })
          }
        }
      })


    })
  }, []);
  // if (navigator.platform.indexOf("Win") > -1) {
  //   ps = new PerfectScrollbar(mainPanel.current, {
  //     suppressScrollX: true,
  //     suppressScrollY: false
  //   });
  //   document.body.style.overflow = "hidden";
  // }
  // window.addEventListener("resize", resizeFunction);

  // // Specify how to clean up after this effect:
  // return function cleanup() {
  //   if (navigator.platform.indexOf("Win") > -1) {
  //     ps.destroy();
  //   }
  //   window.removeEventListener("resize", resizeFunction);
  // };
  // });
  // functions for changeing the states from components
  // const handleImageClick = image => {
  //   setImage(image);
  // };
  // const handleColorClick = color => {
  //   setColor(color);
  // };
  // const handleBgColorClick = bgColor => {
  //   switch (bgColor) {
  //     case "white":
  //       setLogo(require("assets/img/logo.svg"));
  //       break;
  //     default:
  //       setLogo(require("assets/img/logo-white.svg"));
  //       break;
  //   }
  //   setBgColor(bgColor);
  // };
  // const handleFixedClick = () => {
  //   if (fixedClasses === "dropdown") {
  //     setFixedClasses("dropdown show");
  //   } else {
  //     setFixedClasses("dropdown");
  //   }
  // };
  // const handleDrawerToggle = () => {
  //   setMobileOpen(!mobileOpen);
  // };
  // const getActiveRoute = routes => {
  //   let activeRoute = "Default Brand Text";
  //   for (let i = 0; i < routes.length; i++) {
  //     if (routes[i].collapse) {
  //       let collapseActiveRoute = getActiveRoute(routes[i].views);
  //       if (collapseActiveRoute !== activeRoute) {
  //         return collapseActiveRoute;
  //       }
  //     } else {
  //       if (
  //         window.location.href.indexOf(routes[i].layout + routes[i].path) !== -1
  //       ) {
  //         return routes[i].name;
  //       }
  //     }
  //   }
  //   return activeRoute;
  // };
  // const getRoutes = routes => {
  //   return routes.map((prop, key) => {
  //     if (prop.collapse) {
  //       return getRoutes(prop.views);
  //     }
  //     if (prop.layout === "/rtl") {
  //       return (
  //         <Route
  //           path={prop.layout + prop.path}
  //           component={prop.component}
  //           key={key}
  //         />
  //       );
  //     } else {
  //       return null;
  //     }
  //   });
  // };
  // const sidebarMinimize = () => {
  //   setMiniActive(!miniActive);
  // };
  // const resizeFunction = () => {
  //   if (window.innerWidth >= 960) {
  //     setMobileOpen(false);
  //   }
  // };
  const classes = useStyles();

  return (null
    //   swal({
    //     title: 'Données Utilisateurs',
    //     text: 'Redirecting...',
    //     icon: 'success',
    //     timer: 1000,
    //     buttons: false,
    //   }).then(() => {
    //     Swal.fire({
    //       title: "Nouvelle demande de placement \n" +
    //         "Service : <b>LBA IMMO</b>",
    //       type: 'info',
    //       input: 'select',
    // inputOptions: {
    //   creditUser: 'Crédit - ( '+creditUser+' )',
    //   commissions: 'Commissions - ('+ Math.round(parseFloat(userInformation.commissions)) +' )'

    // },
    // inputPlaceholder: 'Choisir Portefeuille',
    // //showCancelButton: true,

    //  html:
    //    '<p id="label">Montant désiré : <p>\n'+
    //   '<input id="swal-input1" class="swal2-input">' ,

    // //input: 'text',
    // showDenyButton: true,
    // //showCancelButton: true,
    // confirmButtonText: 'Validé',
    // denyButtonText: 'Annulé',
    // //showCloseButton: true,
    // inputValidator: async () => {
    //   var select = $(".swal2-select").val();
    //   console.log(select);

    //   return new Promise((resolve) => {
    //     if (select !== null) {
    //       resolve()
    //     } else {
    //       resolve('Veuillez renseigner tous les champs')
    //     }
    //   })
    // },
    // preConfirm: () => {
    //   var input = $("#swal-input1").val();
    //   if (input === "") {
    //     Swal.showValidationMessage(
    //       '<i class="fa fa-info-circle"></i>Veuillez renseigner tous les champs'
    //     )
    //   }
    // },
    // onOpen: function(ele) {
    //   $(ele).find('.swal2-select').insertBefore($(ele).find('#label'));
    // }

    //     }).then((result) => {
    //       demandePlacements[0].id_utilisateur = userInformation.id_utilisateur;
    //       demandePlacements[0].nom_prenom = userInformation.nom + " " + userInformation.prenom;
    //       demandePlacements[0].reference_client = userInformation.reference_client;
    //       demandePlacements[0].montant = $("#swal-input1").val();

    //       if (result.isDenied) {
    //       }
    //       else if (result.isConfirmed) {
    //         if($(".swal2-select").val() === "creditUser"){
    //           Swal.fire({
    //             title: "Vous êtes sur le point de placé "+ $("#swal-input1").val()+"€ sur le service \n" +
    //               "LBA IMMO \n" +
    //               "voulez-vous continuer ?</b>",
    //             type: 'info',
    //             showDenyButton: true,
    //             confirmButtonText: 'Validé',
    //             denyButtonText: 'Annulé',

    //           }).then((result) => {
    //             console.log(result);
    //             if (result.isConfirmed) {

    //             demandePlacements[0].portefeuille = "Crédit";

    //             swal({
    //               title: 'Votre placement à bien été enregistrer credit \n',
    //               text: 'votre placement sera prochainement actif',
    //               icon: 'success',
    //               timer: 3000,
    //               buttons: false,
    //             }).then(async () => {
    //               {
    //                 method: "POST",
    //                 headers: { 'Content-Type': 'application/json' },
    //                 body: JSON.stringify(demandePlacements[0])
    //               })
    //             })
    //             console.log(parseFloat(creditUser))
    //           }else{

    //           }
    //           })                 

    //         }else{
    //           Swal.fire({
    //             title: "Vous êtes sur le point de placé "+ $("#swal-input1").val()+"€ sur le service \n" +
    //               "ATS Trading Services \n" +
    //               "voulez-vous continuer ?</b>",
    //             type: 'info',
    //             showDenyButton: true,
    //             confirmButtonText: 'Validé',
    //             denyButtonText: 'Annulé',

    //           }).then((result) => {
    //             console.log(result);
    //             if (result.isConfirmed) {
    //             demandePlacements[0].portefeuille = "Commissions";

    //             swal({
    //               title: 'Votre placement à bien été enregistrer commissions \n',
    //               text: 'votre placement sera prochainement actif',
    //               icon: 'success',
    //               timer: 3000,
    //               buttons: false,
    //             }).then(async () => {
    //               {
    //                 method: "POST",
    //                 headers: { 'Content-Type': 'application/json' },
    //                 body: JSON.stringify(demandePlacements[0])
    //               })
    //             })
    //             console.log(parseFloat(userInformation.commissions))
    //           }else{

    //           }
    //           })
    //         }
    //       }
    //     })


    //   })
    // <div className={classes.wrapper}>
    //   <Sidebar
    //     routes={routes}
    //     logoText={"توقيت الإبداعية"}
    //     logo={logo}
    //     image={image}
    //     handleDrawerToggle={handleDrawerToggle}
    //     open={mobileOpen}
    //     color={color}
    //     bgColor={bgColor}
    //     miniActive={miniActive}
    //     rtlActive
    //     {...rest}
    //   />
    //   <div className={mainPanelClasses} ref={mainPanel}>
    //     <AdminNavbar
    //       rtlActive
    //       sidebarMinimize={sidebarMinimize.bind(this)}
    //       miniActive={miniActive}
    //       handleDrawerToggle={handleDrawerToggle}
    //       brandText={getActiveRoute(routes)}
    //       {...rest}
    //     />
    //     <div className={classes.content}>
    //       <div className={classes.container}>
    //         <Switch>
    //           {getRoutes(routes)}
    //           <Redirect from="/rtl" to="/rtl/rtl-support-page" />
    //         </Switch>
    //       </div>
    //     </div>
    //     <Footer fluid rtlActive />
    //     <FixedPlugin
    //       handleImageClick={handleImageClick}
    //       handleColorClick={handleColorClick}
    //       handleBgColorClick={handleBgColorClick}
    //       color={color}
    //       bgColor={bgColor}
    //       bgImage={image}
    //       handleFixedClick={handleFixedClick}
    //       fixedClasses={fixedClasses}
    //       sidebarMinimize={sidebarMinimize.bind(this)}
    //       miniActive={miniActive}
    //       rtlActive={true}
    //     />
    //   </div>
    // </div>
  );
}
