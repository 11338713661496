import React, { useState, useEffect } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
//import InputLabel from "@material-ui/core/InputLabel";

// @material-ui/icons
import PermIdentity from "@material-ui/icons/PermIdentity";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Clearfix from "components/Clearfix/Clearfix.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";

import MenuItem from "@material-ui/core/MenuItem";
import 'sweetalert2/src/sweetalert2.scss'
// ES6 Modules or TypeScript
import Swal from 'sweetalert2'
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import SettingsApplicationsRoundedIcon from '@material-ui/icons/SettingsApplicationsRounded';

import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
//import LibraryBooks from "@material-ui/icons/LibraryBooks";
import Datetime from "react-datetime";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import styles from "assets/jss/material-dashboard-pro-react/views/userProfileStyles.js";
import AuthService from '../Services/Auth.js'

const apiUrl = "https://api.lba-investissements.com"
const Auth = new AuthService();

const useStyles = makeStyles(styles);
const information = [
  {
    nom_utilisateur: '',
    kyc: '',
    nom: '',
    adresse: '',
    code_postal: '',
    societe: '',
    prenom: '',
    ville: '',
    pays: '',
    email: '',
    telephone: '',
    nom_rib: '',
    code_bic: '',
    rib: '',
    reajust_total_investi: '',
    reajustRevenue: '',
    reajust_commissions: '',
    nom_service: '',
    checkeKyc: '',
    credit : '',
    value: '',
    revenue: '',
    revenueCommission: '',
    revenueNonValid: ''
  }
];
const placement = [
  {
    id_utilisateur: '',
    nom_prenom: '',
    reference_client: '',
    date: '',
    nom_service: '',
    montant: '',
  }
];
export default function UserProfile(props) {

  //setInformationUsers(JSON.stringify(props["history"]["location"]["state"][0]));
  const handleSimple = event => {
    setSimpleSelect(event.target.value);
  };
  function reformatDateString(s) {
    var b = s.split(/\D/);
    return b.reverse().join('-');
  }
  const handleDate = event => {
    setDate(reformatDateString(event._d.toLocaleDateString()));
  };
  const [simpleSelect, setSimpleSelect] = React.useState("");
  const [checkedA, setCheckedA] = React.useState(false);
  const classes = useStyles();
  const [informationUser, setInformationUsers] = useState();
  const [servicesData, setServicesData] = useState();
  const [nom_utilisateur, setNom_utilisateur] = useState('');
  const [nom, setNom] = useState('');
  const [adresse, setAdresse] = useState('');
  const [code_postal, setCode_postal] = useState('');
  const [societe, setSociete] = useState('');
  const [prenom, setPrenom] = useState('');
  const [ville, setVille] = useState('');
  const [pays, setPays] = useState('');
  const [email, setEmail] = useState('');
  const [telephone, setTel] = useState('');
  const [nom_rib, setNomRib] = useState('');
  const [rib, setRib] = useState('');
  const [codeBic, setCodeBic] = useState('');
  const [reajust_total_investi, setReajustTotalInvesti] = useState('');
  const [reajust_revenue, setReajustRevenue] = useState('');
  const [reajustCommissions, setReajustCommissions] = useState('');
  const [service, setService] = useState('');
  const [date, setDate] = useState('');
  const [credit, setMontant] = useState('');
  const [checkeKyc, setCheckeKyc] = useState('');
  useEffect(() => {
    if (props["history"]["location"]["state"] !== null) {
      var id = props["history"]["location"]["state"].id_utilisateur;
      fetch(`${apiUrl}/information/getInformationByIdUser/` + id)
        .then(response => response.json())
        .then((data) => {
          if (data.length > 0) {
            console.log(data)
            setInformationUsers(data[0]);
            setCheckedA(data[0].kyc)
          } else {
            Swal.fire({
              position: 'top-end',
              icon: 'warning',
              title: 'Error',
              showConfirmButton: false,
              timer: 1500
            })
          }

        }).catch(e => {

        });
      fetch(`${apiUrl}/services/getServices`)
        .then(response => response.json())
        .then((data) => {
          setServicesData(data["data"]);
        });
    }
  }, []);
  const handleClickUpdate = (obj) => {
    console.log(obj.reajust_revenue)
    console.log(information[0])
    console.log(reajust_revenue)

    information[0].kyc = checkedA;
    information[0].nom_utilisateur = obj.nom_utilisateur;
    information[0].nom = nom === "" ? obj.nom : nom;
    information[0].adresse = ((adresse === "") || (adresse == null)) ? obj.adresse : adresse;
    information[0].code_postal = code_postal === "" ? obj.code_postal : code_postal;
    information[0].societe = societe === "" ? obj.societe : societe;
    information[0].prenom = prenom === "" ? obj.prenom : prenom;
    information[0].ville = ville === "" ? obj.ville : ville;
    information[0].pays = pays === "" ? obj.pays : pays;
    information[0].email = email === "" ? obj.email : email;
    information[0].telephone = telephone === "" ? obj.telephone : telephone;
    information[0].nom_rib = nom_rib === "" ? obj.nom_rib : nom_rib;
    information[0].rib = rib === "" ? obj.rib : rib;
    information[0].code_bic = ((codeBic === "") || (codeBic === null)) ? obj.code_bic : codeBic;
    information[0].reajust_total_investi = reajust_total_investi === "" ? obj.reajust_total_investi : parseFloat(reajust_total_investi) + parseFloat(obj.reajust_total_investi);
    information[0].reajustRevenue = reajust_revenue === "" ? obj.reajust_revenue : parseFloat(reajust_revenue) + parseFloat(obj.reajust_revenue);
    information[0].reajust_commissions = reajustCommissions === "" ? obj.reajust_commissions : parseFloat(reajustCommissions) + parseFloat(obj.reajust_commissions);
    information[0].credit = credit === "" ? obj.credit : parseFloat(credit) + parseFloat(obj.credit);
    information[0].checkeKyc = ((checkeKyc === "") || (checkeKyc == null)) ? obj.checkeKyc : checkeKyc;


    // if ((simpleSelect !== "") && (date !== "") && (montant !== "")) {
    //   placement[0].date = date;
    //   placement[0].id_utilisateur = obj.id_utilisateur;
    //   placement[0].montant = montant;
    //   placement[0].nom_prenom = obj.nom + " " + obj.prenom;
    //   placement[0].nom_service = simpleSelect;
    //   placement[0].reference_client = obj.reference_client;

    //   fetch(`${apiUrl}/placements/addPlacements`,
    //     {
    //       method: "POST",
    //       headers: { 'Content-Type': 'application/json' },
    //       body: JSON.stringify(placement[0])
    //     })
    // }
    fetch(`${apiUrl}/information/updateUser/` + obj.id_utilisateur,
      {
        method: "PUT",
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(information[0])
      }).then(async ()=>{
          await fetch(`${apiUrl}/placements/sumMontantUser/` + obj.id_utilisateur)
            .then(response => response.json())
            .then((data) => {    
              information[0].value = parseFloat(data[0].Somme + (data[0].SommeRea !== null ? parseFloat(data[0].SommeRea) : 0));
    
            }).then( async() => {
              fetch(`${apiUrl}/information/updateTotalInvesti/` + obj.id_utilisateur,
                {
                  method: "PUT",
                  headers: { 'Content-Type': 'application/json' },
                  body: JSON.stringify(information[0])
                });
            });
            await fetch(`${apiUrl}/commissions/sumMontantCommissionUser/` + obj.id_utilisateur)
            .then(response => response.json())
            .then((data) => {
              console.log(data[0])
              //information[0].revenue = parseFloat(data[0].Somme + (data[0].SommeRea !== null ? parseFloat(data[0].SommeRea) : 0));
              information[0].revenue = parseFloat(informationUser.revenue) + parseFloat(reajust_revenue);

            }).then(() => {
              fetch(`${apiUrl}/information/updateTotalRevenue/` + obj.id_utilisateur,
                {
                  method: "PUT",
                  headers: { 'Content-Type': 'application/json' },
                  body: JSON.stringify(information[0])
                });
            });
    
            await fetch(`${apiUrl}/commissions/sumMontantCommissionUserNonValide/` + obj.id_utilisateur)
            .then(response => response.json())
            .then((data) => {
              information[0].revenueNonValid = parseFloat(data[0].Somme + (data[0].SommeRea !== null ? parseFloat(data[0].SommeRea) : 0));
    
            }).then(async () => {
              await fetch(`${apiUrl}/information/updateTotalCommissions/` + obj.id_utilisateur,
                {
                  method: "PUT",
                  headers: { 'Content-Type': 'application/json' },
                  body: JSON.stringify(information[0])
                }).then((e) => {
                  if (e.status === 200) {
                    Swal.fire({
                      position: 'top-end',
                      icon: 'success',
                      title: 'mise à jour avec succès',
                      showConfirmButton: false,
                      timer: 1500
                    }).then(() => {
                      window.history.back();
                    })
                  } else {
                    Swal.fire({
                      position: 'top-end',
                      icon: 'warning',
                      title: 'Error',
                      showConfirmButton: false,
                      timer: 1500
                    })
                  }
          
                }).catch((error) => {
                  Swal.fire({
                    position: 'top-end',
                    icon: 'warning',
                    title: 'Error',
                    showConfirmButton: false,
                    timer: 1500
                  })
                })
          ;
            })
    
        
      })

  };


  return (
    <div>
      {informationUser !== undefined ?
        <GridContainer justify="center">
          <GridItem xs={12} sm={12} md={6}>
            <Card>
              <CardHeader color="info" icon>
                <CardIcon color="info">
                  <PermIdentity />
                </CardIcon>
                <h4 className={classes.cardIconTitle}>
                  Édition du profil <small> |  {informationUser.kyc === 1 ? "Profil vérifié" : "Profil non verifié"}</small><br />

                  <small>Référence client : {informationUser.reference_client}</small>
                </h4>
              </CardHeader>
              <CardBody>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={5}>
                    <CustomInput
                      labelText="Société"
                      inputProps={{
                        defaultValue: informationUser.societe,

                        onChange: e => {
                          setSociete(e.target.value);
                        },

                      }}
                      id="company"
                      formControlProps={{
                        fullWidth: true
                      }}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={3}>
                    <CustomInput
                      labelText="Pseudo"
                      id="username"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        defaultValue: informationUser.nom_utilisateur,
                        disabled: true
                      }}
                    />
                  </GridItem>
                </GridContainer>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={6}>
                    <CustomInput
                      labelText="Nom"
                      inputProps={{
                        defaultValue: informationUser.nom,
                        onChange: e => {
                          setNom(e.target.value);
                        },

                      }}
                      id="nom"
                      formControlProps={{
                        fullWidth: true
                      }}
                    />
                  </GridItem>
                </GridContainer>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={6}>
                    <CustomInput
                      labelText="Prénom"
                      inputProps={{
                        defaultValue: informationUser.prenom,
                        onChange: e => {
                          setPrenom(e.target.value);
                        },

                      }}
                      id="Prénom"
                      formControlProps={{
                        fullWidth: true
                      }}
                    />
                  </GridItem>
                </GridContainer>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={4}>
                    <CustomInput
                      labelText="Adresse"
                      inputProps={{
                        defaultValue: informationUser.adresse,
                        onChange: e => {
                          setAdresse(e.target.value);
                        },

                      }}
                      onChange={(e) => setAdresse(e.target.value)}
                      id="adresse"
                      formControlProps={{
                        fullWidth: true
                      }}
                    />
                  </GridItem>
                </GridContainer>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={2}>
                    <CustomInput
                      labelText="Code Postal"
                      inputProps={{
                        defaultValue: informationUser.code_postal,
                        onChange: e => {
                          setCode_postal(e.target.value);
                        },

                      }}
                      id="postal-code"
                      formControlProps={{
                        fullWidth: true
                      }}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={4}>
                    <CustomInput
                      labelText="Ville"
                      inputProps={{
                        defaultValue: informationUser.ville,
                        onChange: e => {
                          setVille(e.target.value);
                        },

                      }}
                      id="city"
                      formControlProps={{
                        fullWidth: true
                      }}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={2}>
                    <CustomInput
                      labelText="Pays"
                      inputProps={{
                        defaultValue: informationUser.pays,
                        onChange: e => {
                          setPays(e.target.value);
                        },

                      }}
                      id="pays"
                      formControlProps={{
                        fullWidth: true
                      }}
                    />
                  </GridItem>
                </GridContainer>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={4}>
                    <CustomInput
                      labelText="E-mail"
                      inputProps={{
                        defaultValue: informationUser.email,
                        onChange: e => {
                          setEmail(e.target.value);
                        },

                      }}
                      id="email"
                      formControlProps={{
                        fullWidth: true
                      }}
                    />
                  </GridItem>
                </GridContainer>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={4}>
                    <CustomInput
                      labelText="N° Téléphone"
                      inputProps={{
                        defaultValue: informationUser.telephone,
                        onChange: e => {
                          setTel(e.target.value);
                        },

                      }}
                      id="num-telephone"
                      formControlProps={{
                        fullWidth: true
                      }}
                    />
                  </GridItem>
                </GridContainer>
                <CardHeader color="rose" icon>
                  <PermIdentity />
                  <h4 className={classes.cardIconTitle}>
                    Information bancaire<br />
                    <small>Compléter vos information bancaire pour vos demandes de commissions</small>
                  </h4>
                </CardHeader>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={4}>
                    <CustomInput
                      labelText="Nom Complet"
                      inputProps={{
                        defaultValue: informationUser.nom_rib,
                        onChange: e => {
                          setNomRib(e.target.value);
                        },

                      }}
                      id="nom-complet-rib"
                      formControlProps={{
                        fullWidth: true
                      }}
                    />
                  </GridItem>
                </GridContainer>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={4}>
                    <CustomInput
                      labelText="RIB"
                      inputProps={{
                        defaultValue: informationUser.rib,
                        onChange: e => {
                          setRib(e.target.value);
                        },

                      }}
                      id="rib"
                      formControlProps={{
                        fullWidth: true
                      }}
                    />
                  </GridItem>
                </GridContainer>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={4}>
                    <CustomInput
                      labelText="Code BIC"
                      inputProps={{
                        defaultValue: informationUser.code_bic,
                        onChange: e => {
                          setCodeBic(e.target.value);
                        },

                      }}
                      id="code-bic"
                      formControlProps={{
                        fullWidth: true
                      }}
                    />
                  </GridItem>
                </GridContainer>
                <CardHeader color="rose" icon>
                  <PermIdentity />
                  <h4 className={classes.cardIconTitle}>
                    <SettingsApplicationsRoundedIcon />
                  Paramètre administrateur
                <br />
                    <br />
                Réajustement
                <br />
                    <small>Réajusté le solde total investi, revenu ou commission du client</small> <br></br>
                    <small>Montant Total investi : {informationUser.total_investi} | Revenu : {Auth.toFixed(parseFloat(informationUser.revenue), 2)}| Commissions : {Auth.toFixed(parseFloat(informationUser.commissions), 2)}| Crédit : {Auth.toFixed(parseFloat(informationUser.credit), 2)}</small>

                  </h4>
                </CardHeader>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={4}>
                    <CustomInput
                      labelText="Total investi"
                      inputProps={{
                        defaultValue: informationUser.reajust_total_investi,
                        onChange: e => {
                          setReajustTotalInvesti(e.target.value);
                        },

                      }}
                      id="total-investi"
                      formControlProps={{
                        fullWidth: true
                      }}
                    />
                  </GridItem>
                </GridContainer>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={4}>
                    <CustomInput
                      labelText="Revenu"
                      inputProps={{
                        defaultValue: informationUser.reajust_revenue,
                        onChange: e => {
                          setReajustRevenue(e.target.value);
                        },

                      }}
                      id="revenu"
                      formControlProps={{
                        fullWidth: true
                      }}
                    />
                  </GridItem>
                </GridContainer>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={4}>
                    <CustomInput
                      labelText="Commissions"
                      inputProps={{
                        defaultValue: informationUser.reajust_commissions,
                        onChange: e => {
                          setReajustCommissions(e.target.value);
                        },

                      }}
                      id="commissions"
                      formControlProps={{
                        fullWidth: true
                      }}
                    />
                  </GridItem>
                </GridContainer>
                <CardHeader color="rose" icon>
                  <PermIdentity />
                  <h4 className={classes.cardIconTitle}>
                    Ajout<br />
                    <small>Ajout manuel de crédit LBA</small>
                  </h4>
                </CardHeader>
                {/* <GridContainer>
                  <GridItem xs={12} sm={6} md={5} lg={5}>
                    <br />
                    <FormControl
                      fullWidth
                      className={classes.selectFormControl}
                    >
                      <InputLabel
                        htmlFor="simple-select"
                        className={classes.selectLabel}
                      >
                        Choix du service
                        </InputLabel>
                      <Select
                        MenuProps={{
                          className: classes.selectMenu
                        }}
                        classes={{
                          select: classes.select
                        }}
                        value={simpleSelect}
                        onChange={handleSimple}
                        inputProps={{
                          name: "simpleSelect",
                          id: "simple-select"
                        }}
                      >
                        <MenuItem
                          disabled
                          classes={{
                            root: classes.selectMenuItem
                          }}
                        >
                          Choix du service
                          </MenuItem>
                        {servicesData !== undefined && servicesData.map((prop, key) => {
                          return (
                            <MenuItem
                              key={key}
                              classes={{
                                root: classes.selectMenuItem,
                                selected: classes.selectMenuItemSelected
                              }}
                              value={prop.nom_service}
                              onChange={
                                //setService(e.target.selected);
                                handleSimple
                              }

                            >
                              {prop.nom_service}
                            </MenuItem>)
                        })}
                        {/* <MenuItem
                            classes={{
                              root: classes.selectMenuItem,
                              selected: classes.selectMenuItemSelected
                            }}
                            value="3"
                          >
                            LBA IMMO
                          </MenuItem> *
                      </Select>
                    </FormControl>
                  </GridItem>
                </GridContainer> */}
                {/* <GridContainer>
                  <GridItem xs={12} sm={12} md={4}>
                    <br />
                    <FormControl fullWidth>
                      <Datetime
                        timeFormat={false}
                        onChange={handleDate}
                        inputProps={{ defaultValue: "Date du placement" }}
                      //inputProps={informationUser !== undefined && { placeholder: informationUser.date }}



                      />
                    </FormControl>
                  </GridItem>
                </GridContainer> */}
                <GridContainer>
                  <GridItem xs={12} sm={12} md={4}>
                    <CustomInput
                      labelText="Montant désiré"
                      inputProps={{
                        defaultValue: informationUser.credit,
                        onChange: e => {
                          setMontant(e.target.value);
                        },

                      }}
                      id="montant-investi"
                      formControlProps={{
                        fullWidth: true
                      }}
                    />
                  </GridItem>
                </GridContainer>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={6}>
                    <br />
                    <legend>Validation KYC</legend>
                    <div className={classes.block}>
                      <FormControlLabel
                        control={
                          <Switch
                            checked={checkedA}
                            onChange={event => setCheckedA(event.target.checked)}
                            // inputProps={{
                            //   onChange: e => {
                            //     setCheckeKyc(e.target.checked);
                            //   },

                            // }}
                            value="checkedA"
                            classes={{
                              switchBase: classes.switchBase,
                              checked: classes.switchChecked,
                              thumb: classes.switchIcon,
                              track: classes.switchBar
                            }}
                          />
                        }
                        classes={{
                          label: classes.label
                        }}
                        label="Accepter la validation"
                      />
                    </div>
                  </GridItem>
                </GridContainer>
                <Button color="info" className={classes.updateProfileButton}
                  onClick={() => {
                    handleClickUpdate(informationUser)
                  }}>
                  Mettre à jour
              </Button>
                <Clearfix />
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
        : null}
      <ToastContainer />

    </div>
  );
}
