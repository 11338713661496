import React, { useState, useEffect } from "react";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import InputAdornment from "@material-ui/core/InputAdornment";
//import Checkbox from "@material-ui/core/Checkbox";
//import FormControlLabel from "@material-ui/core/FormControlLabel";
import Icon from "@material-ui/core/Icon";
import Swal from 'sweetalert2'
import swal from '@sweetalert/with-react'
import InputLabel from "@material-ui/core/InputLabel";
// @material-ui/icons
//import Timeline from "@material-ui/icons/Timeline";
//import Code from "@material-ui/icons/Code";
//import Group from "@material-ui/icons/Group";
import Face from "@material-ui/icons/Face";
import Email from "@material-ui/icons/Email";
// import LockOutline from "@material-ui/icons/LockOutline";
//import Check from "@material-ui/icons/Check";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import CustomInput from "components/CustomInput/CustomInput.js";
//import InfoArea from "components/InfoArea/InfoArea.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import moment from 'moment';

import styles from "assets/jss/material-dashboard-pro-react/views/registerPageStyle";
const apiUrl = "https://api.lba-investissements.com" 
//const stripeKey = process.env.STRIPE_KEY

const useStyles = makeStyles(styles);
const Payment = [
  {
    email: '',
    stripeToken: '',
    amount : ''
  }
];
const Inscription = [
  {
    nomUtilisateur: '',
    nom: '',
    prenom: '',
    email: '',
    password: ''

  }
];

const UserMeta = [
  {
    user_id: '',
  }
];

const Information = [
  {
    id_utilisateur: '',
    nom_utilisateur: '',
    nom: '',
    premon: '',
    email: '',
    date_inscription: '',
    reference_client: ''


  }
];
export default function RegisterPage() {
  const [message, setMessage] = useState('');
  const [nomUtilisateur, setNomUtilisateur] = useState();
  const [nom, setNom] = useState();
  const [prenom, setPrenom] = useState();
  const [email, setEmail] = useState();
  const [password, setPassword] = useState();
  const [cardAnimaton, setCardAnimation] = React.useState("cardHidden");
  const [lastId, setLastId] = useState('');
  const [stripeToken, setStripeToken] = useState('');


  const onGetStripeToken = (token) =>{
    // Got Stripe token. This means user's card is valid!
    // We need to continue the payment process by sending this token to our own server.
    // More info: https://stripe.com/docs/charges
    setStripeToken(token);

  }
  const stripeHandler = window.StripeCheckout.configure({
    key: "pk_test_51H5qqMBd3cNPyGZeHinbTw88LQXg6hXlyE4CxgxxIwmN9q5PHZnhEWoB5l8e5jsVxzVDAkbskIpSqEzZuPpVH3ZS001Eqdp0m0",
    image: 'https://stripe.com/img/documentation/checkout/marketplace.png',
    locale: 'auto',
    token: function(token) {
      // You can access the token ID with `token.id`.
      // Get the token ID to your server-side code for use.
      Payment[0].email = token.email;
      Payment[0].stripeToken = token.id;

      fetch(`${apiUrl}/connexion/charge`,
        {
          method: "POST",
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify(Payment[0])
        }).then(()=>{
          swal({
            title: 'paiement avec succès',
            text: 'Redirecting...',
            icon: 'success',
            timer: 2000,
            buttons: false,
          })    
        }).then(()=>{
          Inscription[0].nomUtilisateur = nomUtilisateur;
          Inscription[0].nom = nom;
          Inscription[0].prenom = prenom;
          Inscription[0].email = email;
          Inscription[0].password = password;
          UserMeta[0].user_id = lastId + 1;
          Information[0].id_utilisateur = lastId + 1;
          Information[0].date_inscription = moment(Date.now()).format("YYYY-MM-DD hh:mm:ss");
          Information[0].email = email;
          Information[0].nom = nom;
          Information[0].nom_utilisateur = nomUtilisateur ;
          Information[0].prenom = prenom;
          Information[0].reference_client = 'LBA0'+ (100 + (lastId + 1 ))
    
    
          fetch(`${apiUrl}/connexion/Inscription`,
                    {
                      method: "POST",
                      headers: { 'Content-Type': 'application/json' },
                      body: JSON.stringify(Inscription[0])
                    })
                  .then((result)=>{
                    if(result.status === 200){
                      Swal.fire({
                        position: 'top-end',
                        icon: 'success',
                        title: 'Inscription avec succès',
                        showConfirmButton: false,
                        timer: 1500
                      })
                               
                    }
                  
                  }).then(async ()=>{
                   await fetch(`${apiUrl}/connexion/saveUsermeta`,
                    {
                      method: "POST",
                      headers: { 'Content-Type': 'application/json' },
                      body: JSON.stringify(UserMeta[0])
                    })
                  }).then(()=>{
                    fetch(`${apiUrl}/information/InscritUser`,
                    {
                      method: "POST",
                      headers: { 'Content-Type': 'application/json' },
                      body: JSON.stringify(Information[0])
                    })
                  });
        })
      
    },
    opened: function() {
    },
    closed: function() {
    }
    // token: onGetStripeToken.bind(this),
    // opened: function() {
    //   console.log("Form opened");
    // },
    // closed: function(e) {
    //   console.log("Form closed");
    // },
    // onToken :function (e) {
    //   Payment[0].email = e.token.email;
    //   Payment[0].stripeToken = e.token.id;

    //   {
    //     method: "POST",
    //     headers: { 'Content-Type': 'application/json' },
    //     body: JSON.stringify(Payment[0])
    //   }).then(()=>{
    //     swal({
    //       title: 'paiement avec succès',
    //       text: 'Redirecting...',
    //       icon: 'success',
    //       timer: 1000,
    //       buttons: false,
    //     })    
    //   }).then(()=>{
    //     Inscription[0].nomUtilisateur = nomUtilisateur;
    //     Inscription[0].nom = nom;
    //     Inscription[0].prenom = prenom;
    //     Inscription[0].email = email;
    //     Inscription[0].password = password;
    //     UserMeta[0].user_id = lastId + 1;
    //     Information[0].id_utilisateur = lastId + 1;
    //     Information[0].date_inscription = moment(Date.now()).format("YYYY-MM-DD hh:mm:ss");
    //     Information[0].email = email;
    //     Information[0].nom = nom;
    //     Information[0].nom_utilisateur = nomUtilisateur ;
    //     Information[0].prenom = prenom;
    //     Information[0].reference_client = 'LBA0'+ (100 + (lastId + 1 ))
  
  
    //               {
    //                 method: "POST",
    //                 headers: { 'Content-Type': 'application/json' },
    //                 body: JSON.stringify(Inscription[0])
    //               })
    //             .then((result)=>{
    //               console.log(result)
    //               if(result.status === 200){
    //                 Swal.fire({
    //                   position: 'top-end',
    //                   icon: 'success',
    //                   title: 'Inscription avec succès',
    //                   showConfirmButton: false,
    //                   timer: 1500
    //                 })
                             
    //               }
                
    //             }).then(async ()=>{
    //               {
    //                 method: "POST",
    //                 headers: { 'Content-Type': 'application/json' },
    //                 body: JSON.stringify(UserMeta[0])
    //               })
    //             }).then(()=>{
    //               {
    //                 method: "POST",
    //                 headers: { 'Content-Type': 'application/json' },
    //                 body: JSON.stringify(Information[0])
    //               })
    //             });
    //   })
    //   console.log(e);
    // }
    
  });
  React.useEffect(() => {
    fetch(`${apiUrl}/connexion/getLastId`)
    .then(response => response.json())
    .then((data) => {
      setLastId(data[0].LastID);
    });
    let id = setTimeout(function () {
      setCardAnimation("");
    }, 700);
    // Specify how to clean up after this effect:
    return function cleanup() {
      window.clearTimeout(id);
    };
  });
  const handleFacebook = () => {
    window.location.replace("https://lba-investissements.com/");

  };
  const handleInstagram = () => {
    window.location.replace("https://instagram.com/lba.investissements");

  };
  
  const classes = useStyles();
  const handleClick = async () => {
    if((nomUtilisateur == null) || (nom == null) || (prenom == null) || (email == null) ||(password == null) || (nomUtilisateur === "") ||(nom === "") || (prenom === "") || 
    (email=== "") ||(password === "")){
      setMessage("Information manquante");
    }else{
      swal({
        title: 'Paiement en cours',
        text: 'Redirecting...',
        icon: 'success',
        timer: 1000,
        buttons: false,
      }).then(() => {
        Swal.fire({
          title: "Nouvelle Inscription \n" ,
          type: 'info',
          text: "Montant désiré :",
          input: 'text',
          showDenyButton: true,
          showCancelButton: true,
          confirmButtonText: '<i class="fa fa-credit-card"></i> Carte bancaire',
          denyButtonText: '<i class="fa fa-money-bill-wave-al"></i>Virement bancaire',
          showCloseButton: true,
          preConfirm: (value) => {
            if (!value) {
              Swal.showValidationMessage(
                '<i class="fa fa-info-circle"></i>Le Montant est obligatoire'
              )
            }
          }
        
        
          //showCancelButton: true,
          //focusConfirm: false,
          //confirmButtonText:
          //  '<i class="fa fa-credit-card"></i> Carte bancaire',
          //confirmButtonAriaLabel: 'Thumbs up, great!',
          //virementButtonText : "Virement bancaire",
          //cancelButtonText:
          //  '<i class="fa fa-thumbs-down"></i>',
          //cancelButtonAriaLabel: 'Thumbs down',

          //})
          // .then((result) => {
          //   /* Read more about isConfirmed, isDenied below */
          //   if (result.isConfirmed) {
          //     Swal.fire('Saved!', '', 'success')
          //   } else if (result.isDenied) {
          //     Swal.fire('Changes are not saved', '', 'info')
          //   }
        }).then((result) => {
          if (result.isDismissed) {
          }
          else if (result.isDenied) {
            Swal.fire({
              title: 'Effectuer un virement bancaire',
              html: `<p>Vous pouvez effectuer votre placement par virement bancaire, pour ce faire veuillez nous indiqué dans le libeller du virement l'information suivante: \n<b></p>`
               + '<p>LBA0'+1 + (lastId + 1 ) + `_` + `ATS </b> </p>\n` +
                `<p>LBA INVESTISSEMENTS</p> \n
                      <p>FR76 1010 1010 1010 1010</p> \n
                      Code BIC : CCML110`
            });
          }
          else if (result.isConfirmed) {
            Payment[0].amount = result.value;
            stripeHandler.open({
              amount: result.value * 100,
              currency: 'EUR'
          })
          // .then(()=>{
          //   fetch('/purchase', {
          //     method: 'POST',
          //     headers: {
          //         'Content-Type': 'application/json',
          //         'Accept': 'application/json'
          //     },
          //     body: JSON.stringify({
          //         stripeTokenId: stripeToken.id
          //             })
          // }).then(function(res) {
          //     return res.json()
          // }).then(function(data) {
          //     alert(data.message)
              
          // }).catch(function(error) {
          //     console.error(error)
          // })})
            // Swal.mixin({
            //   input: 'text',
            //   confirmButtonText: 'Suivant &rarr;',
            //   showCancelButton: true,
            //   preConfirm: (value) => {
            //     if (!value) {
            //       Swal.showValidationMessage(
            //         '<i class="fa fa-info-circle"></i>Ce champ est obligatoire'
            //       )
            //     }
            //   },
            //   progressSteps: ['1', '2', '3']
            // }).queue([
            //   {
            //     title: 'Nom du titulaire',
            //     //text: 'Chaining swal2 modals is easy'
            //   },
            //   'Numéro de carte',
            //   {
            //     title: 'CVV',
            //     text: 'Date expiration'
            //   }
            // ]).then((result) => {
            //   if (result.value) {
            //     const answers = JSON.stringify(result.value)
            //     Swal.fire({
            //       title: 'All done!',
            //       html: `
            //                   Your answers:
            //                   <pre><code>${answers}</code></pre>
            //                 `,
            //       confirmButtonText: 'Validé',
            //       showCancelButton: true,
                  
            //     })
            //   }
            // })
            // if(value !== null){
            //   swal({
            //     title: "Opération réussis ! Votre demande sera traiter dans les plus brève délai",
            //     icon: 'success'

            //   })
            //.then(async () => {
            // commissions[0].id_utilisateur = idUser;
            // commissions[0].montant = value;
            // commissions[0].nom_prenom = data[0].nom + " " + data[0].prenom;
            // var date = new Date().toLocaleDateString();
            // commissions[0].date = reformatDateString(date);

            //   {
            //     method: "POST",
            //     headers: { 'Content-Type': 'application/json' },
            //     body: JSON.stringify(commissions[0])
            //   })
            // }).then(()=>{
            //   window.location.reload();
            // })

            //}
          }

        });
      });
      setMessage("");
      
    }
  }
  return (
<>
    <div className={classes.container}>
      <GridContainer justify="center">
        <GridItem xs={12} sm={12} md={8}>
          <Card className={classes.cardSignup}>
            <h2 className={classes.cardTitle}>Inscription au service <br /> ATS TRADING SERVICES</h2>
            <CardBody>
              <GridContainer justify="center">
                <GridItem xs={12} sm={8} md={8}>
                  <div className={classes.center}>
                    <Button justIcon round color="facebook" onClick={handleFacebook}>
                      <i className="fab fa-facebook-f" />
                    </Button>
                    {` `}
                    <Button justIcon round color="instagram" onClick={handleInstagram}>
                      <i className="fab fa-instagram" />
                    </Button>
                    {` `}
                    <h4 className={classes.socialTitle}>Suivez-nous sur les réseaux</h4>
                  </div>
                  <form className={classes.form}>
                    <CustomInput
                      formControlProps={{
                        fullWidth: true,
                        className: classes.customFormControlClasses
                      }}
                      inputProps={{
                        onChange: e => {
                          setNomUtilisateur(e.target.value);
                        },
                        startAdornment: (
                          <InputAdornment
                            position="start"
                            className={classes.inputAdornment}
                          >
                            <Face className={classes.inputAdornmentIcon} />
                          </InputAdornment>
                        ),
                        placeholder: "Nom d'utilisateur"
                      }}
                    />
                    <CustomInput
                      formControlProps={{
                        fullWidth: true,
                        className: classes.customFormControlClasses
                      }}
                      inputProps={{
                        onChange: e => {
                          setNom(e.target.value);
                        },
                        startAdornment: (
                          <InputAdornment
                            position="start"
                            className={classes.inputAdornment}
                          >
                            <Face className={classes.inputAdornmentIcon} />
                          </InputAdornment>
                        ),
                        placeholder: "Votre Nom"
                      }}
                    />
                    <CustomInput
                      formControlProps={{
                        fullWidth: true,
                        className: classes.customFormControlClasses
                      }}
                      inputProps={{
                        onChange: e => {
                          setPrenom(e.target.value);
                        },
                        startAdornment: (
                          <InputAdornment
                            position="start"
                            className={classes.inputAdornment}
                          >
                            <Face className={classes.inputAdornmentIcon} />
                          </InputAdornment>
                        ),
                        placeholder: "Votre Prénom"
                      }}
                    />
                    <CustomInput
                      formControlProps={{
                        fullWidth: true,
                        className: classes.customFormControlClasses
                      }}
                      inputProps={{
                        onChange: e => {
                          setEmail(e.target.value);
                        },
                        startAdornment: (
                          <InputAdornment
                            position="start"
                            className={classes.inputAdornment}
                          >
                            <Email className={classes.inputAdornmentIcon} />
                          </InputAdornment>
                        ),
                        placeholder: "E-mail"
                      }}
                    />
                    <CustomInput
                      formControlProps={{
                        fullWidth: true,
                        className: classes.customFormControlClasses
                      }}
                      inputProps={{
                        onChange: e => {
                          setPassword(e.target.value);
                        },
                        startAdornment: (
                          <InputAdornment
                            position="start"
                            className={classes.inputAdornment}
                          >
                            <Icon className={classes.inputAdornmentIcon}>
                              lock_outline
                            </Icon>
                          </InputAdornment>
                        ),
                        type: "password",
                        placeholder: "Mot de passe"
                      }}
                    /><br></br>     
               
                    <br></br>
                    <InputLabel
                className={classes.labelRoot + " "}
                error={true}
              >
                {message}
              </InputLabel>
                    <br></br>
                    <div className={classes.center}>
                    <Button round color="info" onClick={() => handleClick()}>
                        Inscription
                      </Button>
                    </div>
                  </form>
                </GridItem>
              </GridContainer>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
    </>
  );
}
