
import Cookies from 'universal-cookie';

const cookies = new Cookies();
const apiUrl = "https://api.lba-investissements.com"

export default class AuthService {

  toFixed(num, fixed) {
    var re = new RegExp('^-?\\d+(?:\.\\d{0,' + (fixed || -1) + '})?');
    return isNaN(num) ? 0 : num.toString().match(re)[0];
  }

  hasAnyAuthorities() {
    var i = 0;
    if (document.cookie.match('wordpress_logged_in') !== null) {
      //A supprimer apres
      //cookies.set('PHPSESSID','b49acf560df832c42dcba38e14c31076');
      fetch(`${apiUrl}/connexion/getUserBySession/` + cookies.get('PHPSESSID'))
        .then(response => response.json())
        .then((data) => {
          if (data[0] != undefined) {
            var stringEle = data[0].session_value.substr(data[0].session_value.indexOf('id|') + 5, (data[0].session_value.length));
            var element = stringEle.replace(";", "");
            fetch(`${apiUrl}/connexion/getUserById/` + element)
              .then(response => response.json())
              .then(async (data) => {
                var nSubscriber = data[0]['meta_value'].includes("subscriber");
                var nAdmin = data[0]['meta_value'].includes("administrator");

                localStorage.setItem('IdUser', data[0].user_id);

                if ((nSubscriber) && (i === 0)) {
                  window.location.href = '/client/dashboard';
                  // await new Promise(resolve => setTimeout(resolve, 3000)); // 3 sec
                  //                         cookies.remove('wordpress_logged_in')

                }
                else if ((nAdmin) && (i === 0)) {
                  //window.location.href = '/admin/dashboard';

                  //useHistory().history.push("/admin/dashboard");

                }

              });

          }
        })

      return true;
    }
    else {
      return false;

    }
  }


}
