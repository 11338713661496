import React, { useState, useEffect } from "react";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import InputAdornment from "@material-ui/core/InputAdornment";
//import Icon from "@material-ui/core/Icon";
import Icon from "@material-ui/core/Icon";

// @material-ui/icons
//import Face from "@material-ui/icons/Face";
import Email from "@material-ui/icons/Email";
// import LockOutline from "@material-ui/icons/LockOutline";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import CardFooter from "components/Card/CardFooter.js";
import Swal from 'sweetalert2';

import styles from "assets/jss/material-dashboard-pro-react/views/MotDePasseOubliePageStyle.js";
const apiUrl = "https://api.lba-investissements.com"

const useStyles = makeStyles(styles);
const Reinit = [
  {
    password: ''

  }
];
export default function RenitialiserMotDePasseOubliePage() {
  const [cardAnimaton, setCardAnimation] = React.useState("cardHidden");
  const [motDePasse, setMotDePasse] = useState();
  const [confirmMotDePasse, setConfirmMotDePasse] = useState();
  const [userId, setUserId] = useState();
  var parseQueryString = function() {

    var str = window.location.search;
    var objURL = {};

    str.replace(
        new RegExp( "([^?=&]+)(=([^&]*))?", "g" ),
        function( $0, $1, $2, $3 ){
            objURL[ $1 ] = $3;
        }
    );
    return objURL;
};
  React.useEffect(() => {
    //setEmail(window.location.search.split('email=')[1]);
    //fetch(`${apiUrl}/connexion/getUserByEmail/` + window.location.search.split('email=')[1]+` ` )
    var params = parseQueryString();
    console.log(params['token'])
    console.log(params['email'])
    
    fetch(`${apiUrl}/connexion/getUserByEmail/` + params['email'] + `/`+params['token'])
      .then(response => response.json())
      .then((data) => {
        console.log(data)
        setUserId(data[0].ID)
        //setRowsDemande(data["data"]);
      });
    let id = setTimeout(function () {
      setCardAnimation("");
    }, 700);
    // Specify how to clean up after this effect:
    return function cleanup() {
      window.clearTimeout(id);
    };
  }, []);
  // const handleClickForgetPassword = async () => {
  //   if (motDePasse !== "" && confirmMotDePasse !== "") {
  //     if (motDePasse === confirmMotDePasse) {
  //       Reinit[0].password = motDePasse;
  //       fetch(`${apiUrl}/connexion/RenitialiserPassword/` + userId,
  //         {
  //           method: "PUT",
  //           headers: { 'Content-Type': 'application/json' },
  //           body: JSON.stringify(Reinit[0])
  //         }).then(() => {
  //           Swal.fire({
  //             position: 'top-end',
  //             icon: 'success',
  //             title: 'mise à jour avec succès',
  //             showConfirmButton: false,
  //             timer: 1500
  //           }).then(() => {
  //             window.location.replace("/auth/connexion");
  //           })
  //         })

  //     } else {
  //       Swal.fire({
  //         position: 'top-end',
  //         icon: 'warning',
  //         title: `Votre mot de passe n'est pas correct`,
  //         showConfirmButton: false,
  //         timer: 1500
  //       })
  //     }
  //   }

  // }
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <GridContainer justify="center">
        <GridItem xs={12} sm={6} md={4}>
          <form>
            <Card login className={classes[cardAnimaton]}>
              <CardHeader
                className={`${classes.cardHeader} ${classes.textCenter}`}
                color="info"
              >
                <h4 className={classes.cardTitle}>Réinitialiser votre mot de passe</h4>
                <div className={classes.socialLine}>
                  {[
                    "fab fa-facebook-square",
                    "fab fa-instagram",
                  ].map((prop, key) => {
                    return (
                      <Button
                        color="transparent"
                        justIcon
                        key={key}
                        className={classes.customButtonClass}
                      >
                        <i className={prop} />
                      </Button>
                    );
                  })}
                </div>
              </CardHeader>
              <CardBody>
                <CustomInput
                  labelText="Votre nouveau mot de passe"
                  id="password"
                  formControlProps={{
                    fullWidth: true
                  }}
                  inputProps={{
                    onChange: e => {
                      setMotDePasse(e.target.value);
                    },
                    endAdornment: (
                      <InputAdornment position="end">
                        <Icon className={classes.inputAdornmentIcon}>
                          lock_outline
                        </Icon>
                      </InputAdornment>
                    ),
                    type: "password",
                    autoComplete: "off"
                  }}
                />
                <CustomInput
                  labelText="Confirmer votre nouveau mot de passe"
                  id="confirmPassword"
                  formControlProps={{
                    fullWidth: true
                  }}
                  inputProps={{
                    onChange: e => {
                      setConfirmMotDePasse(e.target.value);
                    },
                    endAdornment: (
                      <InputAdornment position="end">
                        <Icon className={classes.inputAdornmentIcon}>
                          lock_outline
                        </Icon>
                      </InputAdornment>
                    ),
                    type: "password",
                    autoComplete: "off"
                  }}
                />
              </CardBody>
              {/* <CardFooter className={classes.justifyContentCenter} onClick={() => handleClickForgetPassword()}> */}
              <CardFooter className={classes.justifyContentCenter}>

                <Button color="info" simple size="lg" block>
                  Réinitialiser
                </Button>
              </CardFooter>
            </Card>
          </form>
        </GridItem>
      </GridContainer>
    </div>
  );
}
