import React, { useState, useEffect } from "react";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Icon from "@material-ui/core/Icon";

// @material-ui/icons
// import Weekend from "@material-ui/icons/Weekend";
import Home from "@material-ui/icons/Home";
import Business from "@material-ui/icons/Business";
import AccountBalance from "@material-ui/icons/AccountBalance";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";

import styles from "assets/jss/material-dashboard-pro-react/views/pricingPageStyle.js";
import 'sweetalert2/src/sweetalert2.scss';
import Swal from 'sweetalert2'
import $ from 'jquery';
import swal from '@sweetalert/with-react'
import moment from 'moment';
import AuthService from '../../Services/Auth.js'

const useStyles = makeStyles(styles);
const apiUrl = "https://api.lba-investissements.com"
const Auth = new AuthService();

const demandePlacements = [
  {
    portefeuille: '',
    id_utilisateur: '',
    nom_service: 'ATS TRADING SERVICES',
    date: moment(Date.now()).format("YYYY-MM-DD hh:mm:ss"),
    montant: '',
    nom_prenom: '',
    reference_client: ''
  }
];
const userInformation = [
  {
    commissions: '',
    id_utilisateur: '',
    nom: '',
    prenom: '',
    reference_client: '',
    credit: ''
  }
];
export default function PricingPage() {
  const classes = useStyles();

  const idUser = localStorage.getItem('IdUser');

  useEffect(() => {
    fetch(`${apiUrl}/information/getInformationByIdUser/` + idUser)
      .then(response => response.json())
      .then((data) => {
        userInformation[0].commissions = data[0].commissions;
        userInformation[0].reference_client = data[0].reference_client;
        userInformation[0].nom = data[0].nom;
        userInformation[0].prenom = data[0].prenom;
        userInformation[0].id_utilisateur = data[0].id_utilisateur;
      });
    fetch(`${apiUrl}/information/creditUser/` + idUser)
      .then(response => response.json())
      .then((data) => {
        userInformation[0].credit = data[0].credit
      });
    swal({
      title: 'Données Utilisateurs',
      text: 'Redirecting...',
      icon: 'success',
      timer: 1000,
      buttons: false,
    }).then(() => {
      Swal.fire({
        title: "Nouvelle demande de placement \n" +
          "Service : <b>ATS TRADING SERVICES</b>",
        type: 'info',
        input: 'select',
        inputOptions: {
          creditUser: 'Crédit - ( ' +  Auth.toFixed(parseFloat(userInformation[0].credit,2)) + ' )',
          commissions: 'Commissions - ( ' + (isNaN(parseFloat(userInformation[0].commissions)) ? 0 : Auth.toFixed(parseFloat(userInformation[0].commissions),2)) + ' )'

        },
        inputPlaceholder: 'Choisir Portefeuille',

        html:
          '<p id="label">Montant désiré : <p>\n' +
          '<input id="swal-input1" class="swal2-input">',

        showDenyButton: true,
        confirmButtonText: 'Validé',
        denyButtonText: 'Annulé',
        inputValidator: async () => {
          var select = $(".swal2-select").val();

          return new Promise((resolve) => {
            if (select !== null) {
              resolve()
            } else {
              resolve('Veuillez renseigner tous les champs')
            }
          })
        },
        preConfirm: () => {
          var input = $("#swal-input1").val();
          if (input === "") {
            Swal.showValidationMessage(
              '<i class="fa fa-info-circle"></i>Veuillez renseigner tous les champs'
            )
          }
          if (parseFloat($("#swal-input1").val()) > parseFloat(userInformation[0].credit) && ($(".swal2-select").val() === "creditUser") || ($("#swal-input1").val() < 50)) {
            Swal.showValidationMessage(
              '<i class="fa fa-info-circle"></i>Montant invalid'
            )
          }
          if (parseFloat($("#swal-input1").val()) > parseFloat(userInformation[0].commissions) && ($(".swal2-select").val() === "commissions") || ($("#swal-input1").val() < 50)) {
            Swal.showValidationMessage(
              '<i class="fa fa-info-circle"></i>Montant invalid'
            )
          }
        },
        onOpen: function (ele) {
          $(ele).find('.swal2-select').insertBefore($(ele).find('#label'));
        }

      }).then((result) => {
        demandePlacements[0].id_utilisateur = userInformation[0].id_utilisateur;
        demandePlacements[0].nom_prenom = userInformation[0].nom + " " + userInformation[0].prenom;
        demandePlacements[0].reference_client = userInformation[0].reference_client;
        demandePlacements[0].montant = $("#swal-input1").val();

        if (result.isDenied) {
        }
        else if (result.isConfirmed) {
          if ($(".swal2-select").val() === "creditUser") {
            Swal.fire({
              title: "Vous êtes sur le point de placé " + $("#swal-input1").val() + "€ sur le service \n" +
                "ATS TRADING SERVICES \n" +
                "voulez-vous continuer ?</b>",
              type: 'info',
              showDenyButton: true,
              confirmButtonText: 'Validé',
              denyButtonText: 'Annulé',

            }).then((result) => {
              if (result.isConfirmed) {

                demandePlacements[0].portefeuille = "Crédit";

                swal({
                  title: 'Votre placement à bien été enregistrer \n',
                  text: 'votre placement sera prochainement actif',
                  icon: 'success',
                  timer: 3000,
                  buttons: false,
                }).then(async () => {
                  await fetch(`${apiUrl}/placements/InsertDemande`,
                    {
                      method: "POST",
                      headers: { 'Content-Type': 'application/json' },
                      body: JSON.stringify(demandePlacements[0])
                    })
                })
              } else {

              }
            })

          } else {
            Swal.fire({
              title: "Vous êtes sur le point de placé " + $("#swal-input1").val() + "€ sur le service \n" +
                "ATS Trading Services \n" +
                "voulez-vous continuer ?</b>",
              type: 'info',
              showDenyButton: true,
              confirmButtonText: 'Validé',
              denyButtonText: 'Annulé',

            }).then((result) => {
              if (result.isConfirmed) {
                demandePlacements[0].portefeuille = "Commissions";

                swal({
                  title: 'Votre placement à bien été enregistrer \n',
                  text: 'votre placement sera prochainement actif',
                  icon: 'success',
                  timer: 3000,
                  buttons: false,
                }).then(async () => {
                  await fetch(`${apiUrl}/placements/InsertDemande`,
                    {
                      method: "POST",
                      headers: { 'Content-Type': 'application/json' },
                      body: JSON.stringify(demandePlacements[0])
                    })
                })
              } else {

              }
            })
          }
        }
      })


    })
  }, []);
  return (null
    // <div className={classes.container}>
    //   <GridContainer justify="center">
    //     <GridItem xs={12} sm={12} md={6}>
    //       <h2 className={classes.title}>Pick the best plan for you</h2>
    //       <h5 className={classes.description}>
    //         You have Free Unlimited Updates and Premium Support on each package.
    //       </h5>
    //     </GridItem>
    //   </GridContainer>
    //   <GridContainer justify="center">
    //     <GridItem xs={12} sm={12} md={3}>
    //       <Card pricing plain>
    //         <CardBody pricing plain>
    //           <h6 className={classes.cardCategory}>Freelancer</h6>
    //           <div className={classes.icon}>
    //             <Icon className={classes.iconWhite}>weekend</Icon>
    //           </div>
    //           <h3
    //             className={`${classes.cardTitleWhite} ${classes.marginTop30}`}
    //           >
    //             FREE
    //           </h3>
    //           <p className={classes.cardCategory}>
    //             This is good if your company size is between 2 and 10 Persons.
    //           </p>
    //           <Button round color="white">
    //             Choose plan
    //           </Button>
    //         </CardBody>
    //       </Card>
    //     </GridItem>
    //     <GridItem xs={12} sm={12} md={3}>
    //       <Card pricing raised>
    //         <CardBody pricing>
    //           <h6 className={classes.cardCategory}>SMALL COMPANY</h6>
    //           <div className={classes.icon}>
    //             <Home className={classes.iconRose} />
    //           </div>
    //           <h3 className={`${classes.cardTitle} ${classes.marginTop30}`}>
    //             $29
    //           </h3>
    //           <p className={classes.cardDescription}>
    //             This is good if your company size is between 2 and 10 Persons.
    //           </p>
    //           <Button round color="rose">
    //             Choose plan
    //           </Button>
    //         </CardBody>
    //       </Card>
    //     </GridItem>
    //     <GridItem xs={12} sm={12} md={3}>
    //       <Card pricing plain>
    //         <CardBody pricing plain>
    //           <h6 className={classes.cardCategory}>MEDIUM COMPANY</h6>
    //           <div className={classes.icon}>
    //             <Business className={classes.iconWhite} />
    //           </div>
    //           <h3
    //             className={`${classes.cardTitleWhite} ${classes.marginTop30}`}
    //           >
    //             $69
    //           </h3>
    //           <p className={classes.cardCategory}>
    //             This is good if your company size is between 11 and 99 Persons.
    //           </p>
    //           <Button round color="white">
    //             Choose plan
    //           </Button>
    //         </CardBody>
    //       </Card>
    //     </GridItem>
    //     <GridItem xs={12} sm={12} md={3}>
    //       <Card pricing plain>
    //         <CardBody pricing plain>
    //           <h6 className={classes.cardCategory}>ENTERPRISE</h6>
    //           <div className={classes.icon}>
    //             <AccountBalance className={classes.iconWhite} />
    //           </div>
    //           <h3
    //             className={`${classes.cardTitleWhite} ${classes.marginTop30}`}
    //           >
    //             $159
    //           </h3>
    //           <p className={classes.cardCategory}>
    //             This is good if your company size is 99+ persons.
    //           </p>
    //           <Button round color="white">
    //             Choose plan
    //           </Button>
    //         </CardBody>
    //       </Card>
    //     </GridItem>
    //   </GridContainer>
    // </div>
  );
}
