import nodemailer from "nodemailer"

export const transporter = nodemailer.createTransport({
  service: "gmail",
  auth: {
    user: 'noreply@lba-investissements.com',
    pass: 'Noreply_lba?/'
  }
})

export const getPasswordResetURL = (user, token) =>
  `http://localhost:3000/Reinitialiser-mot-de-passe`

export const resetPasswordTemplate = (user, url) => {

  const from = 'noreply@lba-investissements.com'
  const to = user[0].email
  const subject = "🌻 Password Reset 🌻"
  const html = `
  <p>Hey ${user[0].nom_utilisateur || user[0].email},</p>
  <p>We heard that you lost your  password. Sorry about that!</p>
  <p>But don’t worry! You can use the following link to reset your password:</p>
  <a href=${url}>${url}</a>
  <p>Do something outside today! </p>
  `

  return { from, to, subject, html }
}