import React, { useState, useEffect } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import moment from 'moment';

// material-ui icons
//import Assignment from "@material-ui/icons/Assignment";
import EuroSymbolRoundedIcon from '@material-ui/icons/EuroSymbolRounded';
import Button from "components/CustomButtons/Button.js";
import swal from '@sweetalert/with-react'
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Table from "components/Table/Table.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";
import AuthService from '../../Services/Auth.js'

import { cardTitle } from "assets/jss/material-dashboard-pro-react.js";
const Auth = new AuthService();

const styles = {
  customCardContentClass: {
    paddingLeft: "0",
    paddingRight: "0"
  },
  cardIconTitle: {
    ...cardTitle,
    marginTop: "15px",
    marginBottom: "0px"
  }
};
const commissions = [
  {
    id_utilisateur: '',
    nom_prenom: '',
    status: 'En Attente',
    date: '',
    montant: ''
  }
];
const useStyles = makeStyles(styles);
const apiUrl = "https://api.lba-investissements.com"

export default function RegularTables() {
  function numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  const classes = useStyles();
  const [rowsCommissionsRetire, setRowsCommissionsRetire] = useState();
  const [rowsCommissions, setRowsCommissions] = useState();
  const idUser = localStorage.getItem('IdUser');
  function reformatDateString(s) {
    var b = s.split(/\D/);
    return b.reverse().join('-');
  }

  useEffect(() => {

    fetch(`${apiUrl}/commissions/getCommissionsUserRetire/` + idUser)
      .then(response => response.json())
      .then((data) => {
        setRowsCommissionsRetire(Array.from(data["data"]));
      });
    fetch(`${apiUrl}/commissions/getCommissionsUser/` + idUser)
      .then(response => response.json())
      .then((data) => {
        setRowsCommissions(Array.from(data["data"]));
      });

  }, []);

  return (
    <>
      <Button color="success" className={classes.updateProfileButton} style={{ float: 'right' }}
        onClick={() => {

          swal({
            title: 'Données Utilisateurs',
            text: 'Redirecting...',
            icon: 'success',
            timer: 1000,
            buttons: false,
          })
            .then(() => {
              fetch(`${apiUrl}/information/getInformationByIdUser/` + idUser)
                .then(response => response.json())
                .then(async (data) => {
                  if (data[0].kyc === 0) {
                    swal({
                      title: "Validation KYC requise",
                      text: "Suite à la Directive (UE) 2015/849 du Parlement Européen et du Conseil, nous somme dans l'obligation de procéder à la vérification de votre identité \n" +
                        "Pour ce faire nous aurions besoins des documents suivants vous concernant afin de validé votre identité : \n" +
                        "- Une pièce d'indentité en cours de validité \n" +
                        "- Un justificatif de domicile de moins de 3 mois \n" +
                        "- Un RIB \n" +
                        "Et de nous retourner tous les documents par e-mail en accompagnent dans l'objet la Référence suivante :" + data[0].reference_client + "\n",
                      type: "success",
                      button: "Envoyer les documents"
                    }).then(okay => {
                      if (okay) {
                        window.location.href = "mailto:contact@lba-investissements.com?subject="+data[0].reference_client;
                      }
                    });

                  }
                  if (data[0].kyc === 1) {
                    swal({
                      title: "Nouvelle demande de retrait",
                      text: "Votre solde : " + Auth.toFixed(parseFloat(data[0].commissions),2) + "\n",
                      type: "success",
                      content: "input",
                      // button: "Validé"
                      buttons: {
                        cancel: "Annulé",
                        confirm: {
                          text: "Validé",
                        }

                        //   catch: {
                        //   text: "Validé",
                        //   value: "valid",
                        // }
                      }
                    }).then((value) => {
                      switch (value) {
                        case "Annulé":
                          //window.location.href = "";
                          break;

                        default:
                          //window.location.href = "";
                          if ((value > parseFloat(data[0].commissions) && (value !== null))) {
                            swal({
                              title: "Le montant demander dépasse la solde disponible",
                              icon: 'warning'
                            })
                          }
                          else if ((value < 10) && (value !== null)) {
                            swal({
                              title: "Le montant minimum est de 10€",
                              icon: 'warning'

                            })
                          }
                          else if (value !== null) {
                            swal({
                              title: "Opération réussis ! Votre demande sera traiter dans les plus brève délai",
                              icon: 'success'

                            }).then(async () => {
                              console.log("22222"+data[0])

                              commissions[0].id_utilisateur = idUser;
                              commissions[0].montant = value;
                              commissions[0].nom_prenom = data[0].nom + " " + data[0].prenom;
                              var date = new Date().toLocaleDateString();
                              commissions[0].date = reformatDateString(date);

                              await fetch(`${apiUrl}/commissions/InsertCommission`,
                                {
                                  method: "POST",
                                  headers: { 'Content-Type': 'application/json' },
                                  body: JSON.stringify(commissions[0])
                                })
                            }).then(() => {
                              window.location.reload();
                            })

                          }

                      }
                    });
                  }

                })
            });
          //         swal(
          //           <div>
          //             <h1>Hello world!</h1>
          //             <p>
          //               This is now rendered with JSX!{informationUser !== undefined && informationUser.kyc}
          //             </p>
          //           </div>, {
          //   button: "Envoyer les documents"
          // });
          //let obj = data.find(o => o.id === key);
          //alert("test"
          // "You've clicked EDIT button on \n{ \nNom Prénom: " +
          //   obj.nomprenom +
          //   ", \nrefunique: " +
          //   obj.refunique +
          //   ", \ndateinscription: " +
          //   obj.dateinscription +
          //   ", \nkyc: " +
          //   obj.kyc +
          //   ", \nmti: " +
          //   obj.mti +
          //   "\n}."
          //);
        }} >
        Nouveau retrait
              </Button>
      <GridContainer>
        <GridItem xs={12}>
          <Card>
            <CardHeader color="success" icon>
              <CardIcon color="success">
                <EuroSymbolRoundedIcon />
              </CardIcon>
              <h4 className={classes.cardIconTitle}>Total de mes commissions retiré</h4>
              <font color="black"><small>Retrouver la liste de toutes vos commissions retirées de votre compte</small></font>
            </CardHeader>
            <CardBody>
              {rowsCommissionsRetire !== undefined ?
                <Table
                  tableHeaderColor="primary"
                  tableHead={["ID", "Date de demande", "Status", "Montant"]}
                  // tableData={[
                  //   ["ATS TRADING SERVICES", "15/01/2021", "49,5€"],
                  //   ["LBA IMMO", "15/01/2021", "13€"],
                  //   ["ATS TRADING SERVICES", "14/01/2021", "49€"],
                  //   ["LBA IMMO", "14/01/2021", "12,8€"],
                  //   ["ATS TRADING SERVICES", "13/01/2021", "48,5€"],
                  //   ["LBA IMMO", "13/01/2021", "12,1€"],
                  //   ["ATS TRADING SERVICES", "12/01/2021", "49€"],
                  //   ["LBA IMMO", "12/01/2021", "12,8€"],
                  //   ["ATS TRADING SERVICES", "11/01/2021", "48,5€"],
                  //   ["LBA IMMO", "11/01/2021", "12,1€"],
                  // ]}
                  tableData={rowsCommissionsRetire.map(row => ([

                    // [row.nom_prenom,row.nom_service, row.date, row.montant]
                    row.id_commission, moment(row.date).format("DD/MM/YYYY"), row.status, Auth.toFixed(parseFloat(row.montant), 2) + "€"]))}


                  coloredColls={[2]}
                  colorsColls={["success"]}
                /> : null}
              {/* <Table
              tableHeaderColor="primary"
              tableHead={["ID", "Date de demande", "Status", "Montant"]}
              tableData={[
                ["6", "05/12/2020", "Accepté", "50€"],
                ["5", "15/11/2020", "Accepté", "150€"],
                ["4", "13/09/2020", "Refusé", "80€"],
                ["3", "18/07/2020", "Accepté", "30€"],
                ["2", "13/05/2020", "En attente", "70€"],
                ["1", "09/03/2020", "Accepté", "300€"]
              ]}
              coloredColls={[3]}
              colorsColls={["success"]}
            /> */}
            </CardBody>
          </Card>
        </GridItem>
        <GridItem xs={12}>
          <Card>
            <CardHeader color="success" icon>
              <CardIcon color="success">
                <EuroSymbolRoundedIcon />
              </CardIcon>
              <h4 className={classes.cardIconTitle}>Total de mes commissions gagné</h4>
              <font color="black"><small>Retrouver la liste de toutes vos commissions que vous avez gagnée</small></font>
            </CardHeader>
            <CardBody>
              {rowsCommissions !== undefined ?
                <Table
                  tableHeaderColor="primary"
                  tableHead={["ID", "Service", "Date", "Montant"]}
                  // tableData={[
                  //   ["ATS TRADING SERVICES", "15/01/2021", "49,5€"],
                  //   ["LBA IMMO", "15/01/2021", "13€"],
                  //   ["ATS TRADING SERVICES", "14/01/2021", "49€"],
                  //   ["LBA IMMO", "14/01/2021", "12,8€"],
                  //   ["ATS TRADING SERVICES", "13/01/2021", "48,5€"],
                  //   ["LBA IMMO", "13/01/2021", "12,1€"],
                  //   ["ATS TRADING SERVICES", "12/01/2021", "49€"],
                  //   ["LBA IMMO", "12/01/2021", "12,8€"],
                  //   ["ATS TRADING SERVICES", "11/01/2021", "48,5€"],
                  //   ["LBA IMMO", "11/01/2021", "12,1€"],
                  // ]}
                  tableData={rowsCommissions.map(row => ([

                    // [row.nom_prenom,row.nom_service, row.date, row.montant]
                    row.id_commission, row.nom_service, moment(row.date).format("DD/MM/YYYY"), Auth.toFixed(parseFloat(row.montant), 2) + "€"]))}


                  coloredColls={[2]}
                  colorsColls={["success"]}
                /> : null}
              {/* <Table
              tableHeaderColor="primary"
              tableHead={["ID", "Service", "Date", "Montant"]}
              tableData={[
                ["6","ATS TRADING SERVICES", "15/02/2021", "50€"],
                ["5", "LBA IMMO", "15/02/2021", "150€"],
                ["4", "ATS TRADING SERVICES", "14/01/2021", "80€"],
                ["3", "LBA IMMO", "14/01/2021", "30€"],
                ["2", "ATS TRADING SERVICES", "13/01/2021", "70€"],
                ["1", "LBA IMMO", "13/01/2021", "300€"]
              ]}
              coloredColls={[3]}
              colorsColls={["success"]}
            /> */}
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </>
  );
}
