import React, { useState, useEffect } from "react";
// react plugin for creating charts
//import ChartistGraph from "react-chartist";
// react plugin for creating vector maps
//import { VectorMap } from "react-jvectormap";
import moment from 'moment';

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
//import Tooltip from "@material-ui/core/Tooltip";
//import Icon from "@material-ui/core/Icon";

// @material-ui/icons
// import ContentCopy from "@material-ui/icons/ContentCopy";
//import Store from "@material-ui/icons/Store";
//import InfoOutline from "@material-ui/icons/InfoOutline";
//import Warning from "@material-ui/icons/Warning";
import DateRange from "@material-ui/icons/DateRange";
import LocalOffer from "@material-ui/icons/LocalOffer";
import Update from "@material-ui/icons/Update";
//import ArrowUpward from "@material-ui/icons/ArrowUpward";
//import AccessTime from "@material-ui/icons/AccessTime";
//import Refresh from "@material-ui/icons/Refresh";
//import Edit from "@material-ui/icons/Edit";
//import Place from "@material-ui/icons/Place";
//import ArtTrack from "@material-ui/icons/ArtTrack";
//import Language from "@material-ui/icons/Language";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Table from "components/Table/Table.js";
//import Button from "components/CustomButtons/Button.js";
//import Danger from "components/Typography/Danger.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import CachedRoundedIcon from '@material-ui/icons/CachedRounded';
import AccountBalanceWalletRoundedIcon from '@material-ui/icons/AccountBalanceWalletRounded';
import BusinessCenterRoundedIcon from '@material-ui/icons/BusinessCenterRounded';
import AuthService from '../Services/Auth.js'

///////////////////////////////
/*import BugReport from "@material-ui/icons/BugReport";
import Tasks from "components/Tasks/Tasks.js";
import Cloud from "@material-ui/icons/Cloud";
import CustomTabs from "components/CustomTabs/CustomTabs.js";
import { bugs, website, server } from "variables/general.js";
import Code from "@material-ui/icons/Code";*/

//////////////////////////////


/*import {
  dailySalesChart,
  emailsSubscriptionChart,
  completedTasksChart
} from "variables/charts";*/

import styles from "assets/jss/material-dashboard-pro-react/views/dashboardStyle.js";


//import priceImage1 from "assets/img/card-2.jpeg";
//import priceImage2 from "assets/img/card-3.jpeg";
//import priceImage3 from "assets/img/card-1.jpeg";

/*const us_flag = require("assets/img/flags/US.png");
const de_flag = require("assets/img/flags/DE.png");
const au_flag = require("assets/img/flags/AU.png");
const gb_flag = require("assets/img/flags/GB.png");
const ro_flag = require("assets/img/flags/RO.png");
const br_flag = require("assets/img/flags/BR.png");*/

const apiUrl = "https://api.lba-investissements.com"
const Auth = new AuthService();

const useStyles = makeStyles(styles);

export default function Dashboard() {
  const classes = useStyles();
  const [sumMontant, setSommeMontant] = React.useState('');
  const [countInvertisseurs, setCountInvertisseurs] = React.useState('');
  const [sumCommissions, setSommeCommission] = React.useState('');
  const [rowsPlacements, setRowsPlacements] = useState();
  const [rowsCommissions, setRowsCommissions] = useState();

  useEffect(() => {
    fetch(`${apiUrl}/placements/sumMontant`)
      .then(response => response.json())
      .then((data) => {
        setSommeMontant(parseFloat(Object.values(data[0])));
      });
    fetch(`${apiUrl}/placements/countInvertisseurs`)
      .then(response => response.json())
      .then((data) => {
        setCountInvertisseurs(data);
      });
    fetch(`${apiUrl}/placements/sumCommission`)
      .then(response => response.json())
      .then((data) => {
        setSommeCommission(parseFloat(Object.values(data[0])));
      });
    fetch(`${apiUrl}/placements/getPlacements`)
      .then(response => response.json())
      .then((data) => {
        setRowsPlacements(data["data"]);
      });
    fetch(`${apiUrl}/commissions/getCommissionsValide`)
      .then(response => response.json())
      .then((data) => {
        setRowsCommissions(data["data"]);
      });
  }, []);


  return (
    <div>
      <GridContainer>
        <GridItem xs={12} sm={6} md={6} lg={4}>
          <Card>
            <CardHeader color="info" stats icon>
              <CardIcon color="info">
                <BusinessCenterRoundedIcon />
              </CardIcon>
              <p className={classes.cardCategory}>Total des investissements</p>
              <h3 className={classes.cardTitle}>
                {isNaN(sumMontant) ? 0 : sumMontant} <small>€</small>
              </h3>
            </CardHeader>
            <CardFooter stats>
              <div className={classes.stats}>
                <Update />
                Total de tous les investissements sur LBA Investissements
              </div>
            </CardFooter>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={6} md={6} lg={4}>
          <Card>
            <CardHeader color="success" stats icon>
              <CardIcon color="success">
                <AccountBalanceWalletRoundedIcon />
              </CardIcon>
              <p className={classes.cardCategory}>Investisseurs actifs</p>
              <h3 className={classes.cardTitle}>{countInvertisseurs}
              </h3>
            </CardHeader>
            <CardFooter stats>
              <div className={classes.stats}>
                <DateRange />
                Total des investisseurs actif dans le portefeuille
              </div>
            </CardFooter>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={6} md={6} lg={4}>
          <Card>
            <CardHeader color="warning" stats icon>
              <CardIcon color="warning">
                <CachedRoundedIcon />
              </CardIcon>
              <p className={classes.cardCategory}>Total des commission demander</p>
              <h3 className={classes.cardTitle}>
                {isNaN(sumCommissions) ? 0 : Auth.toFixed(parseFloat(sumCommissions), 2)} <small>€</small>
              </h3>
            </CardHeader>
            <CardFooter stats>
              <div className={classes.stats}>
                <LocalOffer />
                Total des commissions distribuer au clients
              </div>
            </CardFooter>
          </Card>
        </GridItem>
      </GridContainer>

      <GridContainer>
        <GridItem xs={12} sm={12} md={6}>
          <Card>
            <CardHeader color="success">
              <h4 className={classes.cardTitleWhite}>Derniers investissements cliens</h4>
              <p className={classes.cardCategoryWhite}>
                Les derniers placements actifs clients
              </p>
            </CardHeader>
            <CardBody>
              {rowsPlacements !== undefined ?
                <Table
                  tableHeaderColor="warning"
                  tableHead={["Client", "Services", "Date", "Montant"]}
                  tableData={rowsPlacements.map(row => ([

                    // [row.nom_prenom,row.nom_service, row.date, row.montant]
                    row.nom_prenom, row.nom_service, moment(row.date).format("DD/MM/YYYY"), Auth.toFixed(parseFloat(row.montant), 2) + "€"]))}



                  coloredColls={[3]}
                  colorsColls={["success"]}
                />
                :
                null}
            </CardBody>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={12} md={6}>
          <Card>
            <CardHeader color="warning">
              <h4 className={classes.cardTitleWhite}>Commission distribuer</h4>
              <p className={classes.cardCategoryWhite}>
                Les dernières commission distribuer au clients
              </p>
            </CardHeader>
            <CardBody>
              {rowsCommissions !== undefined &&
                <Table
                  tableHeaderColor="warning"
                  tableHead={["Client", "Date", "Montant"]}
                  tableData={rowsCommissions.map(row => ([

                    // [row.nom_prenom,row.nom_service, row.date, row.montant]
                    row.nom_prenom, moment(row.date).format("DD/MM/YYYY"), Auth.toFixed(parseFloat(row.montant), 2) + " €"]))}



                  coloredColls={[3]}
                  colorsColls={["success"]}
                />
              }

            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  );
}
