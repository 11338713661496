import React, { useState, useEffect } from "react";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// @material-ui/icons
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import PriorityHigh from "@material-ui/icons/PriorityHigh";
import Check from "@material-ui/icons/Check";
import Warning from "@material-ui/icons/Warning";
import Close from "@material-ui/icons/Close";
import Favorite from "@material-ui/icons/Favorite";
import swal from '@sweetalert/with-react'
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Pagination from "components/Pagination/Pagination.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import 'sweetalert2/src/sweetalert2.scss';
import Swal from 'sweetalert2';
import styles from "assets/jss/material-dashboard-pro-react/views/buttonsStyle.js";
import $ from 'jquery';
import { useHistory } from 'react-router-dom';
const apiUrl = "https://api.lba-investissements.com"
//const stripeKey = process.env.STRIPE_KEY

const useStyles = makeStyles(styles);
const Payment = [
  {
    email: '',
    stripeToken: '',
    amount: ''
  }
];
export default function AchatCredit() {
  const classes = useStyles();
  const [lastId, setLastId] = useState('');
  const [valueSelected, setValue] = useState('');
  const idUser = localStorage.getItem('IdUser');
  const history = useHistory();

  const stripeHandler = window.StripeCheckout.configure({
    key: "pk_test_51H5qqMBd3cNPyGZeHinbTw88LQXg6hXlyE4CxgxxIwmN9q5PHZnhEWoB5l8e5jsVxzVDAkbskIpSqEzZuPpVH3ZS001Eqdp0m0",
    image: 'https://stripe.com/img/documentation/checkout/marketplace.png',
    locale: 'auto',
    token: function (token) {
      // You can access the token ID with `token.id`.
      // Get the token ID to your server-side code for use.
      Payment[0].email = token.email;
      Payment[0].stripeToken = token.id;
      fetch(`${apiUrl}/connexion/charge`,
        {
          method: "POST",
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify(Payment[0])
        }).then(() => {
          swal({
            title: 'Opération réussis !',
            text: 'Votre paiement à bien été accpeter, votre compte viens d"être crédité de ' + (Payment[0].amount) + ' crédit. Vous pouvez dès à présent les placés sur nos services',
            icon: 'success',
            timer: 2000,
            buttons: false,
          }).then(async () => {
            await fetch(`${apiUrl}/information/updateCredit/` + window.IdUser,
              {
                method: "PUT",
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(Payment[0])
              })
          })
            .then(() => {
              history.push("/client/dashboard");

            })
        }).catch(function (error) {
          swal({
            title: 'Opération échoué !',
            text: `Votre paiement n'a pas été accepter, veuillez contacter votre banque ou contacter notre support`,
            icon: 'warning',
            timer: 2000,
            buttons: false,
          })
        })

    },
    opened: function () {
    },
    closed: function () {
    }

  });
  useEffect(() => {
    fetch(`${apiUrl}/connexion/getLastId`)
      .then(response => response.json())
      .then((data) => {
        setLastId(parseFloat(data[0].LastID));
        handleClick(data[0].LastID);

      })
  }, []);
  const handleClick = async (LastIdInfo) => {
    swal({
      title: 'Paiement en cours',
      text: 'Redirecting...',
      icon: 'success',
      timer: 1000,
      buttons: false,
    }).then(() => {
      Swal.fire({
        title: "Recharger votre compte en crédit LBA \n",
        type: 'info',
        input: 'select',
        inputOptions: {
          '50': '50 crédit LBA',
          '100': '100 crédit LBA',
          '150': '150 crédit LBA',
          '200': '200 crédit LBA',
          '400': '400 crédit LBA',
          '600': '600 crédit LBA',
          '800': '800 crédit LBA',
          '1000': '1000 crédit LBA',
          '1500': '1500 crédit LBA',
          '2000': '2000 crédit LBA',
          '3000': '3000 crédit LBA',
          '4000': '4000 crédit LBA',
          '5000': '5000 crédit LBA'

        },
        inputPlaceholder: 'Choisir crédit',
        showCancelButton: true,
        inputValidator: async (value) => {

          return new Promise((resolve) => {
            if (value !== "") {
              resolve()
            } else {
              resolve('Veuillez choisir un crédit LBA pour continuer')
            }
          })
        },
        // html:
        //   '<p id="label">Montant désiré : <p>\n'+
        //   '<input id="swal-input1" class="swal2-input">' ,

        text: "1 Crédit = 1 Euros",
        //input: 'text',
        showDenyButton: true,
        showCancelButton: true,
        confirmButtonText: '<i class="fa fa-credit-card"></i> Carte bancaire',
        denyButtonText: '<i class="fa fa-money-bill-wave-al"></i>Virement bancaire',
        showCloseButton: true,
        // preConfirm: (value) => {
        //   if (!value) {
        //     Swal.showValidationMessage(
        //       '<i class="fa fa-info-circle"></i>Le Montant est obligatoire'
        //     )
        //   }
        // },
        // onOpen: function(ele) {
        //   $(ele).find('.swal2-select').insertBefore($(ele).find('#label'));
        //}


      }).then((result) => {
        if (result.isDismissed) {
        }
        else if ((result.isDenied) && (result.value !== "")) {

          Swal.fire({
            title: 'Effectuer un virement bancaire',
            html: `<p>Vous pouvez effectuer votre placement par virement bancaire, pour ce faire veuillez nous indiqué dans le libeller du virement l'information suivante: \n<b></p>`
              + '<p>LBA0' + 1 + (idUser) + `_crédit ` + ` </b> </p>\n` +
              `<p>LBA INVESTISSEMENTS</p> \n
                    <p>FR76 1010 1010 1010 1010</p> \n
                    Code BIC : CCML110 \n
                    Lorsque nous aurons réceptionner votre virement, nous vous créditerons votre compte.`,
            showDenyButton: true,
            showConfirmButton: false,
            denyButtonText: '<i class="fa fa-money-bill-wave-al"></i>Fermer',

          });
        }
        else if (result.isConfirmed) {
          Payment[0].amount = result.value;
          stripeHandler.open({
            amount: result.value * 100,
            currency: 'EUR'
          })
        }

      });


    })
  };
  return (null
    // <Card>
    //   <CardBody>
    //     <GridContainer>
    //       <GridItem xs={12} sm={12} md={6}>
    //         <div className={classes.cardHeader}>
    //           <h4 className={classes.cardTitle}>Pick your Color</h4>
    //         </div>
    //         <div className={classes.cardContentLeft}>
    //           <Button className={classes.marginRight}>Default</Button>
    //           <Button color="primary" className={classes.marginRight}>
    //             Primary
    //           </Button>
    //           <Button color="info" className={classes.marginRight}>
    //             Info
    //           </Button>
    //           <Button color="success" className={classes.marginRight}>
    //             Success
    //           </Button>
    //           <Button color="warning" className={classes.marginRight}>
    //             Warning
    //           </Button>
    //           <Button color="danger" className={classes.marginRight}>
    //             Danger
    //           </Button>
    //           <Button color="rose" className={classes.marginRight}>
    //             Rose
    //           </Button>
    //         </div>
    //       </GridItem>
    //       <GridItem xs={12} sm={12} md={6}>
    //         <div className={classes.cardHeader}>
    //           <h4 className={classes.cardTitle}>Buttons with Label</h4>
    //         </div>
    //         <div className={classes.cardContentRight}>
    //           <Button className={classes.marginRight}>
    //             <KeyboardArrowLeft className={classes.icons} /> Left
    //           </Button>
    //           <Button className={classes.marginRight}>
    //             Right <KeyboardArrowRight className={classes.icons} />
    //           </Button>
    //           <Button color="info" className={classes.marginRight}>
    //             <PriorityHigh className={classes.icons} /> Info
    //           </Button>
    //           <Button color="success" className={classes.marginRight}>
    //             <Check className={classes.icons} /> Success
    //           </Button>
    //           <Button color="warning" className={classes.marginRight}>
    //             <Warning className={classes.icons} /> Warning
    //           </Button>
    //           <Button color="danger" className={classes.marginRight}>
    //             <Close className={classes.icons} /> Danger
    //           </Button>
    //         </div>
    //       </GridItem>
    //     </GridContainer>
    //     <GridContainer>
    //       <GridItem xs={12} sm={12} md={6}>
    //         <div className={classes.cardHeader}>
    //           <h4 className={classes.cardTitle}>Pick your Size</h4>
    //         </div>
    //         <div className={classes.cardContentLeft}>
    //           <Button color="primary" size="sm" className={classes.marginRight}>
    //             Small
    //           </Button>
    //           <Button color="primary" className={classes.marginRight}>
    //             Regular
    //           </Button>
    //           <Button color="primary" size="lg" className={classes.marginRight}>
    //             Large
    //           </Button>
    //         </div>
    //       </GridItem>
    //       <GridItem xs={12} sm={12} md={6}>
    //         <div className={classes.cardHeader}>
    //           <h4 className={classes.cardTitle}>Pick your Style</h4>
    //         </div>
    //         <div className={classes.cardContentRight}>
    //           <Button color="primary" className={classes.marginRight}>
    //             Default
    //           </Button>
    //           <Button color="primary" round className={classes.marginRight}>
    //             round
    //           </Button>
    //           <Button color="primary" round className={classes.marginRight}>
    //             <Favorite className={classes.icons} /> with icon
    //           </Button>
    //           <Button
    //             justIcon
    //             round
    //             color="primary"
    //             className={classes.marginRight}
    //           >
    //             <Favorite className={classes.icons} />
    //           </Button>
    //           <Button color="primary" simple className={classes.marginRight}>
    //             simple
    //           </Button>
    //         </div>
    //       </GridItem>
    //     </GridContainer>
    //     <GridContainer>
    //       <GridItem xs={12} sm={12} md={6}>
    //         <div className={classes.cardHeader}>
    //           <h4 className={classes.cardTitle}>Pagination</h4>
    //         </div>
    //         <div className={classes.cardContentLeft}>
    //           <Pagination
    //             pages={[
    //               { text: 1 },
    //               { text: "..." },
    //               { text: 5 },
    //               { text: 6 },
    //               { active: true, text: 7 },
    //               { text: 8 },
    //               { text: 9 },
    //               { text: "..." },
    //               { text: 12 }
    //             ]}
    //           />
    //           <Pagination
    //             pages={[
    //               { text: "PREV" },
    //               { text: 1 },
    //               { text: 2 },
    //               { active: true, text: 3 },
    //               { text: 4 },
    //               { text: 5 },
    //               { text: "NEXT" }
    //             ]}
    //             color="info"
    //           />
    //         </div>
    //       </GridItem>
    //       <GridItem xs={12} sm={12} md={6}>
    //         <div className={classes.cardHeader}>
    //           <h4 className={classes.cardTitle}>Button Group</h4>
    //         </div>
    //         <div className={classes.cardContentRight}>
    //           <div className={classes.buttonGroup}>
    //             <Button color="info" className={classes.firstButton}>
    //               Left
    //             </Button>
    //             <Button color="info" className={classes.middleButton}>
    //               Middle
    //             </Button>
    //             <Button color="info" className={classes.lastButton}>
    //               Right
    //             </Button>
    //           </div>
    //           <br />
    //           <br />
    //           <div className={classes.buttonGroup}>
    //             <Button color="info" round className={classes.firstButton}>
    //               1
    //             </Button>
    //             <Button color="info" round className={classes.middleButton}>
    //               2
    //             </Button>
    //             <Button color="info" round className={classes.middleButton}>
    //               3
    //             </Button>
    //             <Button color="info" round className={classes.lastButton}>
    //               4
    //             </Button>
    //           </div>
    //           {` `}
    //           <div className={classes.buttonGroup}>
    //             <Button color="info" round className={classes.firstButton}>
    //               5
    //             </Button>
    //             <Button color="info" round className={classes.middleButton}>
    //               6
    //             </Button>
    //             <Button color="info" round className={classes.lastButton}>
    //               7
    //             </Button>
    //           </div>
    //         </div>
    //       </GridItem>
    //     </GridContainer>
    //     <GridContainer>
    //       <GridItem xs={12}>
    //         <div className={classes.cardHeader}>
    //           <h4 className={classes.cardTitle}>Social buttons</h4>
    //         </div>
    //         <div className={classes.cardContentBottom}>
    //           <GridContainer>
    //             <GridItem xs={12} sm={4} md={4}>
    //               <Button color="twitter">
    //                 <i
    //                   className={
    //                     classes.socialButtonsIcons +
    //                     " " +
    //                     classes.marginRight +
    //                     " fab fa-twitter"
    //                   }
    //                 />{" "}
    //                 Connect with Twitter
    //               </Button>
    //             </GridItem>
    //             <GridItem xs={12} sm={1} md={1}>
    //               <Button justIcon color="twitter">
    //                 <i className={"fab fa-twitter"} />
    //               </Button>
    //             </GridItem>
    //             <GridItem xs={12} sm={1} md={1}>
    //               <Button justIcon round color="twitter">
    //                 <i
    //                   className={classes.socialButtonsIcons + " fab fa-twitter"}
    //                 />
    //               </Button>
    //             </GridItem>
    //             <GridItem xs={12} sm={1} md={1}>
    //               <Button justIcon color="twitter" simple>
    //                 <i
    //                   className={
    //                     classes.socialButtonsIcons +
    //                     " " +
    //                     classes.marginRight +
    //                     " fab fa-twitter"
    //                   }
    //                 />
    //               </Button>
    //             </GridItem>
    //             <GridItem xs={12} sm={4} md={3}>
    //               <Button color="twitter" simple>
    //                 <i
    //                   className={
    //                     classes.socialButtonsIcons +
    //                     " " +
    //                     classes.marginRight +
    //                     " fab fa-twitter"
    //                   }
    //                 />{" "}
    //                 Connect with Twitter
    //               </Button>
    //             </GridItem>
    //           </GridContainer>
    //           <GridContainer>
    //             <GridItem xs={12} sm={4} md={4}>
    //               <Button color="facebook">
    //                 <i
    //                   className={
    //                     classes.socialButtonsIcons +
    //                     " " +
    //                     classes.marginRight +
    //                     " fab fa-facebook-square"
    //                   }
    //                 />{" "}
    //                 Share · 2.2k
    //               </Button>
    //             </GridItem>
    //             <GridItem xs={12} sm={1} md={1}>
    //               <Button justIcon color="facebook">
    //                 <i className={"fab fa-facebook"} />
    //               </Button>
    //             </GridItem>
    //             <GridItem xs={12} sm={1} md={1}>
    //               <Button justIcon round color="facebook">
    //                 <i
    //                   className={
    //                     classes.socialButtonsIcons + " fab fa-facebook"
    //                   }
    //                 />
    //               </Button>
    //             </GridItem>
    //             <GridItem xs={12} sm={1} md={1}>
    //               <Button justIcon color="facebook" simple>
    //                 <i
    //                   className={
    //                     classes.socialButtonsIcons +
    //                     " " +
    //                     classes.marginRight +
    //                     " fab fa-facebook-square"
    //                   }
    //                 />
    //               </Button>
    //             </GridItem>
    //             <GridItem xs={12} sm={4} md={3}>
    //               <Button color="facebook" simple>
    //                 <i
    //                   className={
    //                     classes.socialButtonsIcons +
    //                     " " +
    //                     classes.marginRight +
    //                     " fab fa-facebook-square"
    //                   }
    //                 />{" "}
    //                 Share · 2.2k
    //               </Button>
    //             </GridItem>
    //           </GridContainer>
    //           <GridContainer>
    //             <GridItem xs={12} sm={4} md={4}>
    //               <Button color="google">
    //                 <i
    //                   className={
    //                     classes.socialButtonsIcons +
    //                     " " +
    //                     classes.marginRight +
    //                     " fab fa-google-plus-g"
    //                   }
    //                 />{" "}
    //                 Share on Google+
    //               </Button>
    //             </GridItem>
    //             <GridItem xs={12} sm={1} md={1}>
    //               <Button justIcon color="google">
    //                 <i className={"fab fa-google"} />
    //               </Button>
    //             </GridItem>
    //             <GridItem xs={12} sm={1} md={1}>
    //               <Button justIcon round color="google">
    //                 <i
    //                   className={classes.socialButtonsIcons + " fab fa-google"}
    //                 />
    //               </Button>
    //             </GridItem>
    //             <GridItem xs={12} sm={1} md={1}>
    //               <Button justIcon color="google" simple>
    //                 <i
    //                   className={
    //                     classes.socialButtonsIcons +
    //                     " " +
    //                     classes.marginRight +
    //                     " fab fa-google"
    //                   }
    //                 />
    //               </Button>
    //             </GridItem>
    //             <GridItem xs={12} sm={4} md={3}>
    //               <Button color="google" simple>
    //                 <i
    //                   className={
    //                     classes.socialButtonsIcons +
    //                     " " +
    //                     classes.marginRight +
    //                     " fab fa-google-plus-g"
    //                   }
    //                 />{" "}
    //                 Share on Google+
    //               </Button>
    //             </GridItem>
    //           </GridContainer>
    //           <GridContainer>
    //             <GridItem xs={12} sm={4} md={4}>
    //               <Button color="linkedin">
    //                 <i
    //                   className={
    //                     classes.socialButtonsIcons +
    //                     " " +
    //                     classes.marginRight +
    //                     " fab fa-linkedin"
    //                   }
    //                 />{" "}
    //                 Connect with Linkedin
    //               </Button>
    //             </GridItem>
    //             <GridItem xs={12} sm={1} md={1}>
    //               <Button justIcon color="linkedin">
    //                 <i className={"fab fa-linkedin"} />
    //               </Button>
    //             </GridItem>
    //             <GridItem xs={12} sm={1} md={1}>
    //               <Button justIcon round color="linkedin">
    //                 <i
    //                   className={
    //                     classes.socialButtonsIcons + " fab fa-linkedin"
    //                   }
    //                 />
    //               </Button>
    //             </GridItem>
    //             <GridItem xs={12} sm={1} md={1}>
    //               <Button justIcon color="linkedin" simple>
    //                 <i
    //                   className={
    //                     classes.socialButtonsIcons +
    //                     " " +
    //                     classes.marginRight +
    //                     " fab fa-linkedin"
    //                   }
    //                 />
    //               </Button>
    //             </GridItem>
    //             <GridItem xs={12} sm={4} md={3}>
    //               <Button color="linkedin" simple>
    //                 <i
    //                   className={
    //                     classes.socialButtonsIcons +
    //                     " " +
    //                     classes.marginRight +
    //                     " fab fa-linkedin"
    //                   }
    //                 />{" "}
    //                 Connect with Linkedin
    //               </Button>
    //             </GridItem>
    //           </GridContainer>
    //           <GridContainer>
    //             <GridItem xs={12} sm={4} md={4}>
    //               <Button color="pinterest">
    //                 <i
    //                   className={
    //                     classes.socialButtonsIcons +
    //                     " " +
    //                     classes.marginRight +
    //                     " fab fa-pinterest"
    //                   }
    //                 />{" "}
    //                 Pint it · 212
    //               </Button>
    //             </GridItem>
    //             <GridItem xs={12} sm={1} md={1}>
    //               <Button justIcon color="pinterest">
    //                 <i className={"fab fa-pinterest"} />
    //               </Button>
    //             </GridItem>
    //             <GridItem xs={12} sm={1} md={1}>
    //               <Button justIcon round color="pinterest">
    //                 <i
    //                   className={
    //                     classes.socialButtonsIcons + " fab fa-pinterest"
    //                   }
    //                 />
    //               </Button>
    //             </GridItem>
    //             <GridItem xs={12} sm={1} md={1}>
    //               <Button justIcon color="pinterest" simple>
    //                 <i
    //                   className={
    //                     classes.socialButtonsIcons +
    //                     " " +
    //                     classes.marginRight +
    //                     " fab fa-pinterest"
    //                   }
    //                 />
    //               </Button>
    //             </GridItem>
    //             <GridItem xs={12} sm={4} md={3}>
    //               <Button color="pinterest" simple>
    //                 <i
    //                   className={
    //                     classes.socialButtonsIcons +
    //                     " " +
    //                     classes.marginRight +
    //                     " fab fa-pinterest"
    //                   }
    //                 />{" "}
    //                 Pint it · 212
    //               </Button>
    //             </GridItem>
    //           </GridContainer>
    //           <GridContainer>
    //             <GridItem xs={12} sm={4} md={4}>
    //               <Button color="youtube">
    //                 <i
    //                   className={
    //                     classes.socialButtonsIcons +
    //                     " " +
    //                     classes.marginRight +
    //                     " fab fa-youtube"
    //                   }
    //                 />{" "}
    //                 View on Youtube
    //               </Button>
    //             </GridItem>
    //             <GridItem xs={12} sm={1} md={1}>
    //               <Button justIcon color="youtube">
    //                 <i className={"fab fa-youtube-square"} />
    //               </Button>
    //             </GridItem>
    //             <GridItem xs={12} sm={1} md={1}>
    //               <Button justIcon round color="youtube">
    //                 <i
    //                   className={
    //                     classes.socialButtonsIcons + " fab fa-youtube-square"
    //                   }
    //                 />
    //               </Button>
    //             </GridItem>
    //             <GridItem xs={12} sm={1} md={1}>
    //               <Button justIcon color="youtube" simple>
    //                 <i
    //                   className={
    //                     classes.socialButtonsIcons +
    //                     " " +
    //                     classes.marginRight +
    //                     " fab fa-youtube-square"
    //                   }
    //                 />
    //               </Button>
    //             </GridItem>
    //             <GridItem xs={12} sm={4} md={3}>
    //               <Button color="youtube" simple>
    //                 <i
    //                   className={
    //                     classes.socialButtonsIcons +
    //                     " " +
    //                     classes.marginRight +
    //                     " fab fa-youtube"
    //                   }
    //                 />{" "}
    //                 View on Youtube
    //               </Button>
    //             </GridItem>
    //           </GridContainer>
    //           <GridContainer>
    //             <GridItem xs={12} sm={4} md={4}>
    //               <Button color="tumblr">
    //                 <i
    //                   className={
    //                     classes.socialButtonsIcons +
    //                     " " +
    //                     classes.marginRight +
    //                     " fab fa-tumblr-square"
    //                   }
    //                 />{" "}
    //                 Repost
    //               </Button>
    //             </GridItem>
    //             <GridItem xs={12} sm={1} md={1}>
    //               <Button justIcon color="tumblr">
    //                 <i className={"fab fa-tumblr-square"} />
    //               </Button>
    //             </GridItem>
    //             <GridItem xs={12} sm={1} md={1}>
    //               <Button justIcon round color="tumblr">
    //                 <i
    //                   className={
    //                     classes.socialButtonsIcons + " fab fa-tumblr-square"
    //                   }
    //                 />
    //               </Button>
    //             </GridItem>
    //             <GridItem xs={12} sm={1} md={1}>
    //               <Button justIcon color="tumblr" simple>
    //                 <i
    //                   className={
    //                     classes.socialButtonsIcons +
    //                     " " +
    //                     classes.marginRight +
    //                     " fab fa-tumblr-square"
    //                   }
    //                 />
    //               </Button>
    //             </GridItem>
    //             <GridItem xs={12} sm={4} md={3}>
    //               <Button color="tumblr" simple>
    //                 <i
    //                   className={
    //                     classes.socialButtonsIcons +
    //                     " " +
    //                     classes.marginRight +
    //                     " fab fa-tumblr-square"
    //                   }
    //                 />{" "}
    //                 Repost
    //               </Button>
    //             </GridItem>
    //           </GridContainer>
    //           <GridContainer>
    //             <GridItem xs={12} sm={4} md={4}>
    //               <Button color="github">
    //                 <i
    //                   className={
    //                     classes.socialButtonsIcons +
    //                     " " +
    //                     classes.marginRight +
    //                     " fab fa-github"
    //                   }
    //                 />{" "}
    //                 Connect with Github
    //               </Button>
    //             </GridItem>
    //             <GridItem xs={12} sm={1} md={1}>
    //               <Button justIcon color="github">
    //                 <i className={"fab fa-github"} />
    //               </Button>
    //             </GridItem>
    //             <GridItem xs={12} sm={1} md={1}>
    //               <Button justIcon round color="github">
    //                 <i
    //                   className={classes.socialButtonsIcons + " fab fa-github"}
    //                 />
    //               </Button>
    //             </GridItem>
    //             <GridItem xs={12} sm={1} md={1}>
    //               <Button justIcon color="github" simple>
    //                 <i
    //                   className={
    //                     classes.socialButtonsIcons +
    //                     " " +
    //                     classes.marginRight +
    //                     " fab fa-github"
    //                   }
    //                 />
    //               </Button>
    //             </GridItem>
    //             <GridItem xs={12} sm={4} md={3}>
    //               <Button color="github" simple>
    //                 <i
    //                   className={
    //                     classes.socialButtonsIcons +
    //                     " " +
    //                     classes.marginRight +
    //                     " fab fa-github"
    //                   }
    //                 />{" "}
    //                 Connect with Github
    //               </Button>
    //             </GridItem>
    //           </GridContainer>
    //           <GridContainer>
    //             <GridItem xs={12} sm={4} md={4}>
    //               <Button color="behance">
    //                 <i
    //                   className={
    //                     classes.socialButtonsIcons +
    //                     " " +
    //                     classes.marginRight +
    //                     " fab fa-behance-square"
    //                   }
    //                 />{" "}
    //                 Follow us
    //               </Button>
    //             </GridItem>
    //             <GridItem xs={12} sm={1} md={1}>
    //               <Button justIcon color="behance">
    //                 <i className={"fab fa-behance"} />
    //               </Button>
    //             </GridItem>
    //             <GridItem xs={12} sm={1} md={1}>
    //               <Button justIcon round color="behance">
    //                 <i
    //                   className={
    //                     classes.socialButtonsIcons + " fab fa-behance-square"
    //                   }
    //                 />
    //               </Button>
    //             </GridItem>
    //             <GridItem xs={12} sm={1} md={1}>
    //               <Button justIcon color="behance" simple>
    //                 <i
    //                   className={
    //                     classes.socialButtonsIcons +
    //                     " " +
    //                     classes.marginRight +
    //                     " fab fa-behance"
    //                   }
    //                 />
    //               </Button>
    //             </GridItem>
    //             <GridItem xs={12} sm={4} md={3}>
    //               <Button color="behance" simple>
    //                 <i
    //                   className={
    //                     classes.socialButtonsIcons +
    //                     " " +
    //                     classes.marginRight +
    //                     " fab fa-behance-square"
    //                   }
    //                 />{" "}
    //                 Follow us
    //               </Button>
    //             </GridItem>
    //           </GridContainer>
    //           <GridContainer>
    //             <GridItem xs={12} sm={4} md={4}>
    //               <Button color="dribbble">
    //                 <i
    //                   className={
    //                     classes.socialButtonsIcons +
    //                     " " +
    //                     classes.marginRight +
    //                     " fab fa-dribbble"
    //                   }
    //                 />{" "}
    //                 Find us on Dribble
    //               </Button>
    //             </GridItem>
    //             <GridItem xs={12} sm={1} md={1}>
    //               <Button justIcon color="dribbble">
    //                 <i className={"fab fa-dribbble"} />
    //               </Button>
    //             </GridItem>
    //             <GridItem xs={12} sm={1} md={1}>
    //               <Button justIcon round color="dribbble">
    //                 <i
    //                   className={
    //                     classes.socialButtonsIcons + " fab fa-dribbble"
    //                   }
    //                 />
    //               </Button>
    //             </GridItem>
    //             <GridItem xs={12} sm={1} md={1}>
    //               <Button justIcon color="dribbble" simple>
    //                 <i
    //                   className={
    //                     classes.socialButtonsIcons +
    //                     " " +
    //                     classes.marginRight +
    //                     " fab fa-dribbble"
    //                   }
    //                 />
    //               </Button>
    //             </GridItem>
    //             <GridItem xs={12} sm={4} md={3}>
    //               <Button color="dribbble" simple>
    //                 <i
    //                   className={
    //                     classes.socialButtonsIcons +
    //                     " " +
    //                     classes.marginRight +
    //                     " fab fa-dribbble"
    //                   }
    //                 />{" "}
    //                 Find us on Dribble
    //               </Button>
    //             </GridItem>
    //           </GridContainer>
    //           <GridContainer>
    //             <GridItem xs={12} sm={4} md={4}>
    //               <Button color="reddit">
    //                 <i
    //                   className={
    //                     classes.socialButtonsIcons +
    //                     " " +
    //                     classes.marginRight +
    //                     " fab fa-reddit"
    //                   }
    //                 />{" "}
    //                 Repost · 232
    //               </Button>
    //             </GridItem>
    //             <GridItem xs={12} sm={1} md={1}>
    //               <Button justIcon color="reddit">
    //                 <i className={"fab fa-reddit"} />
    //               </Button>
    //             </GridItem>
    //             <GridItem xs={12} sm={1} md={1}>
    //               <Button justIcon round color="reddit">
    //                 <i
    //                   className={classes.socialButtonsIcons + " fab fa-reddit"}
    //                 />
    //               </Button>
    //             </GridItem>
    //             <GridItem xs={12} sm={1} md={1}>
    //               <Button justIcon color="reddit" simple>
    //                 <i
    //                   className={
    //                     classes.socialButtonsIcons +
    //                     " " +
    //                     classes.marginRight +
    //                     " fab fa-reddit"
    //                   }
    //                 />
    //               </Button>
    //             </GridItem>
    //             <GridItem xs={12} sm={4} md={3}>
    //               <Button color="reddit" simple>
    //                 <i
    //                   className={
    //                     classes.socialButtonsIcons +
    //                     " " +
    //                     classes.marginRight +
    //                     " fab fa-reddit"
    //                   }
    //                 />{" "}
    //                 Repost · 232
    //               </Button>
    //             </GridItem>
    //           </GridContainer>
    //         </div>
    //       </GridItem>
    //     </GridContainer>
    //   </CardBody>
    // </Card>
  );
}
