const dataTable = {
  headerRow: ["Nom Prénom", "Référence unique", "Date d'inscription", "KYC", "Montant total investi"],
  footerRow: ["Nom Prénom", "Référence unique", "Date d'inscription", "KYC", "Montant total investi"],
  dataRows: [
    ["Dupont Jean","LBA01006", "05/12/2020", "ATS TRADING SERVICES", "350€"],
    ["Hirtz Alex","LBA01005", "15/11/2020", "ATS TRADING SERVICES", "1000€"],
    ["Meyer Damien","LBA01004", "13/09/2020", "LBA IMMO", "50€"],
    ["Dubois Jean","LBA01003", "18/07/2020", "LBA IMMO", "110€"],
    ["Muller Paul","LBA01002", "13/05/2020", "LBA IMMO", "800€"],
    ["Berra Luc","LBA01001", "09/03/2020", "ATS TRADING SERVICES", "1500€"]
  ]
};

export {

  dataTable
};
